<template>
    <v-dialog v-model="show"  max-width="450" transition="dialog-transition" :retain-focus="false">
  
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="show = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <div class="text-subtitle-1 font-weight-bold black--text">{{ pet.name }} <v-chip
                            class="font-weight-regular ml-1 mt-n1" v-show="pet.is_premium" color="primary" small
                            style="letter-spacing:1.5px"><v-icon small left>mdi-crown</v-icon>PREMIUM</v-chip>
                    </div>

                    <div class="text-caption">{{ pet.breed }}</div>
                    <div class="text-truncate text-caption">{{ pet.age ? pet.age.long : '' }} old,
                        {{ pet.gender == 'M' ? 'Male' : 'Female' }}</div>
                    <div class="text-truncate text-caption">{{ pet.location }}
                    </div>
                    <div class="text-caption py-2"><b>Owner:</b> {{ pet.user.name }}</div>

                    <div class="text-truncate text-caption py-2 secondary--text">Looking for a {{ pet.purpose }}</div>

                    <div class="text-caption py-2">{{ pet.description }}</div>

                    <v-row justify="start" align="center" class="pt-4">

                        <v-col cols="4" :key="idx" v-for="(pic, idx) in pet.photos" align="center">
                            <v-img :src="pic.photo" cover style="border-radius:5px" aspect-ratio="1">
                            </v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
      
  
  </template>
  
  <script>
  
  export default {
    name: 'PetProfileInfo',
    props: ['value','pet'],
    mounted() {
      
    },
    data() {
      return {
        
      }
    },
  
    computed: {
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
    },
  
    methods: {
    
  
    }
  }
  </script>
  