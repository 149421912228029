<template>
  <div>
    <v-img :src="photo.photo" cover :style="photo.avatar ? 'border: 4px solid #7e0de7' : ''" aspect-ratio="1.333" style="border-radius:10px; min-height:100px">
      <!--   <v-icon title="Delete Photo" color="red darken-2" style="background: white;border-radius: 12px" class="float-right" v-if="photo.avatar == 0" @click="rejectDialog= true">mdi-minus-circle</v-icon>
        <v-icon title="Profile Photo" color="green darken-2" style="background: white;border-radius: 12px" class="float-right" v-if="photo.avatar == 0" @click="setInformation('avatar', 1)">mdi-check-circle</v-icon>
   
         -->
                                            <v-menu bottom left>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" dark class="float-right">
                                                  <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </template>

                                              <v-list dense nav>

                                                  <v-list-item @click="downloadPhoto">
                                                      <v-list-item-icon class="mr-2">
                                                          <v-icon small>mdi-download-outline</v-icon>
                                                      </v-list-item-icon>
                                                      <v-list-item-content>
                                                          <v-list-item-title>Download</v-list-item-title>
                                                      </v-list-item-content>
                                                  </v-list-item>

                                                <v-list-item @click="setInformation('avatar', 1)" v-if="photo.avatar == 0">
                                                  <v-list-item-icon class="mr-2">
                                                    <v-icon small>$vuetify.icons.edit-icon</v-icon>
                                                  </v-list-item-icon>
                                                  <v-list-item-content>
                                                    <v-list-item-title>Make Avatar</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item  @click="rejectDialog= true" v-if="photo.avatar == 0">
                                                  <v-list-item-icon class="mr-2">
                                                    <v-icon small>mdi-delete-outline</v-icon>
                                                  </v-list-item-icon>
                                                  <v-list-item-content>
                                                    <v-list-item-title>Delete</v-list-item-title>
                                                  </v-list-item-content>
                                                </v-list-item>
                                              </v-list>
                                            </v-menu>
   
      </v-img>
    <v-dialog v-model="rejectDialog" max-width="400">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title class="text-subtitle-1">Why rejecting?</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="rejectDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="rejectForm" v-model="valid" lazy-validation @submit.prevent="reject">
          <v-card-text class="pt-4">
            <v-radio-group v-model="rejectReason" class="mt-2" mandatory>
              <v-radio label="Text on image" value="Text on image"></v-radio>
              <v-radio label="Poor quality" value="Poor quality"></v-radio>
              <v-radio label="Irrelevant image" value="Irrelevant image"></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="rejecting" :disabled="!valid || rejecting">Reject</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" max-width="400">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title >Confirm</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="confirmDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p class="text-subtitle-2">
            Are you sure?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="grey" @click="confirmDialog = false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="updating" :disabled="updating" @click="updatePhoto">Yes</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
    name: "UserDetailsPetPhoto",
    props: ["photo"],
    data() {
        return {
            updateField: '',
            updateValue: '',
            rejecting: false,
            rejectDialog: false,
            rejectReason: '',
            valid: true,

            updating: false,
            confirmDialog: false,
            send_notification: false,
        }
    },
    methods: {
        reject() {
            let photo_id = this.photo.xid;
            let reject_reason = this.rejectReason

            this.rejecting = true;
            this.$store.dispatch('rejectPhoto', {
                photo_id,
                reject_reason
            }).then(() => {
                this.rejecting = false;
                this.rejectDialog = false;
                this.$emit('fetch-user-detail');
            })
            .catch(err => {
                this.rejecting = false;
            });
        },
        setInformation(updateField,updateValue)
        {
            this.updateField = updateField
            this.updateValue = updateValue
            this.send_notification = false

            this.confirmDialog = true;
        },
        updatePhoto() {
            this.updating = true;
            let payload = {
                xid: this.photo.xid
            };

            if (this.updateField == 'avatar') {
                payload.avatar = this.updateValue;
            }

            this.$store.dispatch('updatePhoto', payload).then((res) => {
                this.confirmDialog = false;
                this.$emit('update-user-detail', res)
            }).catch(err => {
              this.confirmDialog = false;
            });
        },
        downloadPhoto() {
            window.open(this.photo.photo, '_blank');
        },
    }
}
</script>