<template>
<v-container fluid class="home" :class="$vuetify.breakpoint.xs?'pa-0':'pa-8'">

    <div v-if="this.$store.getters.userDetails.name == ''" class="pt-12">

        <v-row no-gutters>
                <v-col cols="12" justify="center" align="center" class="pt-12">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>

            </v-row>

    </div>
    <div v-else class="pt-6">
        <v-row no-gutters>
            <v-col cols="12" justify="center" align="center">
                <h1>Reports</h1>
            </v-col>
        </v-row>
    </div>

</v-container>
</template>

<script>


export default {
    name: 'Reports',
    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => {});
        }

    },
    components: {

    },

}
</script>


