<template>
  <div>
    <v-card height="147" style="position:relative">
      <v-img :src="video.thumb_url" height="147" cover style="border-radius:5px" class="d-flex align-center justify-center" aspect-ratio="1" v-if="video.thumb_url">
        <v-btn icon dark @click="openVideoPlayer(video.video_url)" style="background-color:rgba(0,0,0,0.30); position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
          <v-icon>mdi-play</v-icon>
        </v-btn>
      </v-img>
      <video-player v-else :options="videoOptions" :source="getVideoUrl(video)" :id="video.xid"/>
        <!-- <v-icon title="Delete Video" color="red" style="background: white;border-radius: 12px;position:absolute;top: 0;right:0" @click="rejectDialog= true">mdi-minus-circle</v-icon>
     -->
        <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" dark style="position:absolute;top: 0;right:0">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list dense nav>
                <v-list-item :disabled="downloading" @click="download()">
                    <v-list-item-icon class="mr-2">
                        <v-icon small>mdi-download-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                       <v-list-item-title>Download</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item  @click="rejectDialog= true">
                    <v-list-item-icon class="mr-2">
                        <v-icon small>mdi-delete-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                       <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    
      </v-card>


      <v-dialog v-model="openVideoDialog" @click:outside="closeVideoPlayer" max-width="90vw" max-height="90vh">
  <v-card color="black" flat>
    <v-card-title class="d-flex justify-end">
      <v-btn icon @click="closeVideoPlayer">
        <v-icon color="grey">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="d-flex justify-center align-center pa-0">
      <v-responsive 
        class="video-responsive"
        :aspect-ratio="16 / 9" 
      >
        <video
          class="v-player"
          controls
          :src="this.video_url"
          style="width: 100%; height: auto; max-height: 80vh; object-fit: contain;"
        ></video>
      </v-responsive>
    </v-card-text>
  </v-card>
</v-dialog>

    <v-dialog v-model="rejectDialog" max-width="400">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title class="text-subtitle-1">Why rejecting?</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="rejectDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="rejectForm" v-model="valid" lazy-validation @submit.prevent="deleteV">
          <v-card-text class="pt-4">
            <v-radio-group v-model="rejectReason" class="mt-2" mandatory>
              <v-radio label="Text on video" value="Text on video"></v-radio>
              <v-radio label="Poor quality" value="Poor quality"></v-radio>
              <v-radio label="Irrelevant video" value="Irrelevant video"></v-radio>
              <v-radio label="Invalid Format (Re-uploading)" value="Invalid Format"></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="rejecting" :disabled="!valid || rejecting">Reject</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" max-width="400">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title >Confirm</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="confirmDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p class="text-subtitle-2">
            Are you sure?
          </p>
          <v-checkbox v-model="send_notification" value=true >
            <template v-slot:label>
              <span class="text-caption">Send notification email</span>
            </template>
          </v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="grey" @click="confirmDialog = false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="updating" :disabled="updating" @click="updateVideo">Yes</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import VideoPlayer from "./VideoPlayer.vue";

export default {
    name: "UserDetailsPetVideo",
  components: {VideoPlayer},
    props: ["video"],
    data() {
        return {
            updateField: '',
            updateValue: '',
            rejecting: false,
            rejectDialog: false,
            rejectReason: '',
            valid: true,

            downloading: false,
            updating: false,
            confirmDialog: false,
            send_notification: false,

            openVideoDialog: false,
            video_url: '',

            videoOptions: {
              autoplay: false,
              controls: true,
              playbackRates: [0.25, 0.5, 1, 1.5, 2, 4]
            }
        }
    },
    methods: {
        openVideoPlayer(video) {
            this.openVideoDialog = true;
            this.video_url = video;
        },

        closeVideoPlayer()
        {
            this.openVideoDialog = false;
            const videos_s = document.querySelectorAll('.v-player');
            videos_s.forEach(video => {
            video.pause();
            });
        },

        getVideoUrl(item) {
            let url='https://api.dyntube.com/v1/live/videos/'+item.video_key+'.m3u8';
            return url;
        },

        deleteV()
        {
          let video_id = this.video.xid;
          this.rejecting = true;
          this.$store.dispatch('deleteVideo', video_id).then((resp)=> {
                this.rejecting = false;
                this.$emit('fetch-user-detail');
            })
            .catch(err => {
                this.rejecting = false;
            });
        },

        reject() {
            let video_id = this.video.xid;
            let reject_reason = this.rejectReason

            this.rejecting = true;
            this.$store.dispatch('rejectVideo', {
                video_id,
                reject_reason
            }).then(() => {
                this.rejecting = false;
                this.rejectDialog = false;
                this.$emit('fetch-user-detail');
            })
            .catch(err => {
                this.rejecting = false;
            });
        },
        updateVideo() {
            this.updating = true;
            let payload = {
                xid: this.photo.xid
            };

            if (this.updateField == 'avatar') {
                payload.avatar = this.updateValue;
            }

            this.$store.dispatch('updateVideo', payload).then((res) => {
                this.confirmDialog = false;
                this.$emit('update-user-detail', res)
            }).catch(err => {
              this.confirmDialog = false;
            });
        },
        download() {
            if (this.video.video_url) {
              const link = document.createElement('a');
              link.href = this.video.video_url;
              link.download = 'video.mp4'; // Adjust the file name as needed
              link.target = 'new';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              let video_id = this.video.xid;
              this.downloading = true;
              this.$store.dispatch('downloadVideo', video_id).then((res) => {
                  window.open(res.data.url, "_blank");
                  this.downloading = false;
              }).catch(err => {
                  this.downloading = false;
              });
            }
        }
    }
}
</script>

<style>
.video-responsive {
  max-height: 80vh; /* Ensures the video fits within the screen */
}

@media (orientation: portrait) {
  /* For very tall portrait videos on mobile */
  .video-responsive video {
    max-width: 100vw;
    max-height: 80vh;
  }
}

@media (min-width: 600px) {
  /* For larger screens, limit the height */
  .video-responsive video {
    max-width: 90vw;
    max-height: 80vh;
  }
}
</style>