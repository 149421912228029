<template>
    <v-row no-gutters>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4" v-if="user.user_adopter" class="pa-2">
        <v-card class="my-4 mild-shadow">
          <v-card-title>Adopter Questionnaire</v-card-title>
          <v-card-text>
            <div class="text-caption">Where do you live?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.residence_type }}</div>

            <div class="text-caption pt-2">Do you own or rent your home?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.owns_home == 1 ? "Own" : "Rented" }}</div>

            <div class="text-caption pt-2">Do you have permission from your landlord to have a pet?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.has_landlord_permission == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption pt-2">Have you ever owned a pet before?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.has_previous_pet_experience == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption pt-2">What type of pet(s) did you owned and for how long?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.previous_pet_details}}</div>

            <div class="text-caption pt-2">How active is your lifestyle? Do you enjoy outdoor activities?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.activity_level}}</div>

            <div class="text-caption pt-2">How much time are you willing to dedicate to caring for a pet each day?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.time_commitment}}</div>

            <div class="text-caption pt-2">What are you looking for in a pet?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.expectations}}</div>

            <div class="text-caption pt-2">Are you willing to invest time and resources in training your pet?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.willing_to_train  == 1 ? "Yes" : "No"}}</div>

            <div class="text-caption pt-2">How much are you willing to spend on pet care expenses like food, vet visits, grooming, etc.?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.pet_care_budget}}</div>

            <div class="text-caption pt-2">Do you have a fenced yard?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.has_fenced_yard == 1 ? "Yes" : "No"}}</div>

            <div class="text-caption pt-2">How do you plan to exercise your pet?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.exercise_plan}}</div>

            <div class="text-caption pt-2">Do you have other pets in the household?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.has_other_pets == 1 ? "Yes" : "No"}}</div>

            <div class="text-caption pt-2">Please describe them (Species/Breed/Age/Temperament)</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.other_pet_details}}</div>

            <div class="text-caption pt-2">Do you have any allergies?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.has_allergies  == 1 ? "Yes" : "No"}}</div>

            <div class="text-caption pt-2">Please specify</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.allergies}}</div>

            <div class="text-caption pt-2">Who will be the primary caregiver for the pet?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.primary_caregiver}}</div>

            <div class="text-caption pt-2">What are your plans if you go on vacation or are unable to care for the pet?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.vacation_care_plan}}</div>

            <div class="text-caption pt-2">Is there anything else you would like to share about yourself or your interest in getting a pet?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.additional_information}}</div>

            <div class="text-caption pt-2">How did you hear about Petmeetly?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_adopter.referral_source}}</div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4" v-if="user.user_breeder" class="pa-2">
        <v-card class="my-4 mild-shadow">
          <v-card-title>Breeder Questionnaire</v-card-title>
          <v-card-text>
            <div class="text-caption">Full name</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.full_name }}</div>

            <div class="text-caption pt-2">Kennel Name (if applicable)</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.kennel_name }}</div>

            <div class="text-caption pt-2">Website</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.website }}</div>

            <div class="text-caption pt-2">Social Media Links</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.social_media_links }}</div>

            <div class="text-caption pt-2">Years of Breeding Experience</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.years_of_experience }}</div>

            <div class="text-caption pt-2">Breeds You Specialize In</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.specialized_breeds }}</div>

            <div class="text-caption pt-2">Certifications or Memberships</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.certifications }}</div>

            <div class="text-caption pt-2">Describe your approach to breeding and your goals for your breeding program</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.breeding_philosophy }}</div>

            <div class="text-caption pt-2">What health tests do you perform on your breeding animals?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.health_testing_protocols }}</div>

            <div class="text-caption pt-2">Average Litter Size</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.average_litter_size }}</div>

            <div class="text-caption pt-2">How often do you breed your females?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.average_litter_size }}</div>

            <div class="text-caption pt-2">Describe how you socialize your puppies/kittens during their first weeks.</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.puppy_socialization }}</div>

            <div class="text-caption pt-2">What veterinary care do you provide for your breeding animals and puppies/kittens?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.veterinary_care }}</div>

            <div class="text-caption pt-2">Describe the living conditions and housing for your breeding animals.</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.living_conditions }}</div>

            <div class="text-caption pt-2">How do you ensure the physical and mental well-being of your breeding animals?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.animal_welfare_commitment }}</div>

            <div class="text-caption pt-2">What ethical considerations do you take into account in your breeding practices?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.ethical_considerations }}</div>

            <div class="text-caption pt-2">Do you require buyers to spay/neuter their pets?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.requires_spay_neuter == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption pt-2">What is your policy if a buyer can no longer care for the pet?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.rehoming_policy }}</div>

            <div class="text-caption pt-2">Do you offer any health guarantees or contracts to buyers?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.guarantee_contract }}</div>

            <div class="text-caption pt-2">How do you determine the price of your puppies/kittens?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.pricing_details }}</div>

            <div class="text-caption pt-2">Do you have a waiting list for your puppies/kittens?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.has_waiting_list == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption pt-2">How do you screen potential buyers to ensure they are suitable homes for your animals?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.screening_process }}</div>

            <div class="text-caption pt-2">What kind of support do you offer to buyers after they take home a puppy/kitten?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.post_sale_support }}</div>

            <div class="text-caption pt-2">Do you participate in any dog shows or competitions? (If applicable)</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.participates_in_shows == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption pt-2">Are you willing to provide photos or videos of your kennel and breeding animals?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.willing_to_provide_media == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption pt-2">Is there any additional information you would like to share about your breeding program?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_breeder.additional_information}}</div>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4" v-if="user.user_shelter" class="pa-2">
        <v-card class="my-4 mild-shadow">
          <v-card-title>Shelter Questionnaire</v-card-title>
          <v-card-text>
            <div class="text-caption">Organization Name</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.organization_name }}</div>

            <div class="text-caption pt-2">Official Phone Number</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.phone_number }}</div>

            <div class="text-caption pt-2">Official Email Address</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.email }}</div>

            <div class="text-caption pt-2">Website</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.website }}</div>

            <div class="text-caption pt-2">Social Media Links</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.social_media_links }}</div>

            <div class="text-caption pt-2">Type of Organization</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.organization_type }}</div>

            <div class="text-caption pt-2">Legal Status</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.legal_status }}</div>

            <div class="text-caption pt-2">Mission Statement</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.mission_statement }}</div>

            <div class="text-caption pt-2">Years of Operation</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.years_of_operation }}</div>

            <div class="text-caption pt-2">Sources of Animals</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.animal_sources }}</div>

            <div class="text-caption pt-2">Describe how you evaluate and intake animals</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.intake_process }}</div>

            <div class="text-caption pt-2">Type of housing</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.housing_type }}</div>

            <div class="text-caption pt-2">Cleaning and sanitation protocols</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.cleaning_protocols }}</div>

            <div class="text-caption pt-2">Enrichment activities for animals</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.enrichment_activities }}</div>

            <div class="text-caption pt-2">Veterinary care provided</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.veterinary_care }}</div>

            <div class="text-caption pt-2">How do you assess the temperament and behavior of animals?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.behavioral_assessment }}</div>

            <div class="text-caption pt-2">Do you accept and care for animals with special needs?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.accepts_special_needs == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption pt-2">Describe your adoption application process.</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.adoption_application_process }}</div>

            <div class="text-caption pt-2">What criteria do you use to evaluate potential adopters?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.screening_criteria }}</div>

            <div class="text-caption pt-2">Do you conduct home visits prior to adoption?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.conducts_home_visits == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption pt-2">What kind of support do you offer to adopters after adoption?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.post_adoption_support}}</div>

            <div class="text-caption pt-2">Euthanasia Policy</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.euthanasia_policy }}</div>

            <div class="text-caption pt-2">Spay/Neuter Policy: (Do you require adopters to spay/neuter their pets?)</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.requires_spay_neuter == 1 ? "Yes" : "No" }}</div>

            <div class="text-caption pt-2">Return Policy</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.return_policy }}</div>

            <div class="text-caption pt-2">Do you have a volunteer program?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.has_volunteer_program == 1 ? "Yes" : "No" }}</div>
            <div class="text-caption pt-2">If so, how can people get involved in volunteer program?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.volunteer_info }}</div>

            <div class="text-caption pt-2">Do you have a foster program?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.has_foster_program == 1 ? "Yes" : "No" }}</div>
            <div class="text-caption pt-2">What are the requirements for becoming a foster parent?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.foster_requirements }}</div>

            <div class="text-caption pt-2">Do you collaborate with other animal welfare organizations?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.partner_organizations}}</div>

            <div class="text-caption pt-2">How do you engage with the community to promote adoption and responsible pet ownership?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.community_outreach}}</div>

            <div class="text-caption pt-2">How do you fund your operations?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.fundraising_efforts}}</div>

            <div class="text-caption pt-2">Number of animals currently in your care</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.animals_in_care}}</div>

            <div class="text-caption pt-2">Types of animals you typically have available for adoption:</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.available_animal_types }}</div>

            <div class="text-caption pt-2">Is there any additional information you would like to share about your organization?</div>
            <div class="text-caption black--text font-weight-bold">{{ user.user_shelter.additional_information }}</div>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

</template>

<script>
export default {
  name: 'UserQuestionnaire',
  props: ['user'],
}
</script>
