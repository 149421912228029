<template>
<div>
    <v-card class="mild-shadow rounded-lg">

        <v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle class="text--primary text-caption"><b>User:</b> {{dna_report.email}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-list-item-action-text class="secondary--text">{{ $moment(dna_report.created_at).fromNow(true) }}</v-list-item-action-text>
            </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-img :src="dna_report.cloud_url" cover max-height="300" @click="show=true"></v-img>

        <v-card-text><div class="text-caption"><a class="info--text" :href="dna_report.cloud_url" target="_blank"><v-icon small color="blue">mdi-open-in-new</v-icon> Open File</a></div></v-card-text>


        <v-card-actions>
            <v-btn icon text color="primary" @click="showPetDetails()">
                    <v-icon>mdi-information-outline</v-icon>
                </v-btn>
            
            <v-spacer></v-spacer>
            <v-btn small text color="error" outlined @click="rejectDialog=true">
                REJECT
            </v-btn>
            
            <v-btn small color="success" depressed v-if="dna_report.verified" @click="approve(0)" :loading="approving" :disabled="approving" class="dna_report_approve_btn">
                APPROVED
            </v-btn>
            <v-btn small text color="success" outlined v-else  @click="approve(1)" :loading="approving" :disabled="approving" class="photo_approve_btn">
                APPROVE
            </v-btn>

        </v-card-actions>

    </v-card>

    <v-dialog v-model="show" >
                <v-img :src="dna_report.cloud_url"  contain @click="show = false"></v-img>
            </v-dialog>

    <pet-profile-info v-if="isMounted" v-model="petProfileDialog" :pet="dna_report.pet" />

    <v-dialog v-model="rejectDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Why rejecting?</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="rejectDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-form ref="rejectForm" v-model="valid" lazy-validation @submit.prevent="reject">
                <v-card-text class="pt-4">
                        <div class="pb-4">Provide a reason for rejection which will be communicated with the user.</div>
                        <v-textarea dense outlined auto-grow row-height="20" rows="3" placeholder="Reject Reason" v-model="rejectReason" :rules="rejectReasonRules"></v-textarea>
               
                        <v-chip small class="ma-1" @click="rejectReason='Pet details not matching'">Pet details not matching</v-chip>
                        <v-chip small class="ma-1" @click="rejectReason='Invalid Format'">Invalid Format</v-chip>
                        <v-chip small class="ma-1" @click="rejectReason='Visuals are not clear'">Visuals are not clear</v-chip>
                        <v-chip small class="ma-1" @click="rejectReason='Irrelevant photo'">Irrelevant photo</v-chip>
                        
                    </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit" :loading="rejecting" :disabled="!valid || rejecting">Reject</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import PetProfileInfo from "./PetProfileInfo.vue";
export default {
    name: 'DnaReportCard',
    props: ['dna_report'],
    mounted() {
        this.isMounted = true;
    },
    data() {
        return {
            approving: false,
            rejecting: false,
            rejectDialog: false,
            rejectReason: '',
            show: false,
            valid: true,
            isMounted: false,
            petProfileDialog: false,
            rejectReasonRules: [
                v => !!v || 'Reject reason is required',
            ],
        }
    },

    components: {
        PetProfileInfo
    },


    methods: {

        approve(val) {
            let verification_id = this.dna_report.xid;
            let approve = val

            this.approving = true;
            this.$store.dispatch('saveDnaReport', {
                    approve,
                    verification_id,
                }).then(() => {
                    this.approving = false;
                    this.dna_report.verified = val
                })
                .catch(err => {
                    this.approving = false;
                });
        },

        reject() {
            if (this.$refs.rejectForm.validate()) {
            this.rejecting = true;
            let verification_id = this.dna_report.xid;
            let reject_reason = this.rejectReason

            this.rejecting = true;
            this.$store.dispatch('rejectDnaReport', {
                    verification_id,
                    reject_reason
                }).then(() => {
                    this.rejecting = false;
                    this.rejectDialog = false;
                })
                .catch(err => {
                    this.rejecting = false;
                });
            }
        },
        showPetDetails() {
            this.petProfileDialog = true;
        },
    }
}
</script>
