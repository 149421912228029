<template>
    <v-container fluid class="home">
        <div v-if="loading" class="pt-12">

            <v-row no-gutters>
                <v-col cols="12" justify="center" align="center" class="pt-12">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
            </v-row>
        </div>

        <div v-else-if="!this.$store.getters.users.length" class="pt-12">
            <v-row no-gutters>
                <v-col cols="12" justify="center" align="center" class="pt-12">
                    No record found.
                </v-col>
            </v-row>
        </div>

        <div v-else class="mb-16">

            <div>

                <v-row no-gutters>
                    <v-col class="col-12" md="12">
                        <v-list two-line>
                            <v-list-item v-for="(item, index) in this.$store.getters.users" :key="index"
                                @click="userDetails(item)" style="border-bottom:1px solid #eee">
                                <v-list-item-avatar>
                                    <v-img v-if="item.photo" :src="item.photo"></v-img>
                                    <v-icon color="#7E0DE7" v-else size="36">$vuetify.icons.account-icon</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.name"></v-list-item-title>

                                    <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>

                                </v-list-item-content>
                                <v-list-item-action>


                                    <v-icon>
                                        mdi-chevron-right
                                    </v-icon>


                                </v-list-item-action>
                            </v-list-item>

                        </v-list>
                    </v-col>
                </v-row>
            </div>
        </div>

        <v-snackbar v-model="snackbar" bottom>
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <!--  <v-btn color="primary" dark fixed bottom right fab @click="searchUser" :loading="loading">
        <v-icon>mdi-chevron-double-down</v-icon>
    </v-btn> -->
    </v-container>
</template>

<script>

export default {
    name: 'SearchUser',
    computed: {
    },
    props: ['email'],
    mounted() {
        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => { });
        } else {
            this.searchUser();
        }
    },
    watch: {
        email: function (val) {
            this.searchUser();
        }
    },
    data() {
        return {
            user: null,
            loading: false,
            snackbar: false,
            snackbar_text: '',
        }
    },
    components: {
    },
    methods: {
        searchUser() {
            this.loading = true;
            let email = this.email;
            this.$store.dispatch('searchUser', email).then((res) => {
                this.loading = false;
                this.user = res;

                if (res.length == 1) {
                    this.$router.push({ path: '/user-details', query: { xid: res[0].xid } }).catch(err => { });
                }
            })
                .catch(err => {
                    this.loading = false;
                    this.snackbar_text = "Oops, Couldn't get data. Please try again.";
                    this.snackbar = true;
                });
        },
        userDetails(user) {
            this.$router.push({ path: '/user-details', query: { xid: user.xid } }).catch(err => { });
        }
    }
}
</script>
