<template>
  <v-dialog v-model="show" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable max-width="600" @input="getPetDetails()">

  <v-card class="editpet" z-index="999">
    <v-card-title>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>Edit Pet</v-toolbar-title>
    </v-card-title>

    <v-card-text>
      <v-col cols="12" class="justify-center">
        <v-row>
          <v-col cols="6" class="pb-0">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Type of pet</div>
            <v-radio-group v-model="pet_type" class="mt-2">
              <v-radio :label="item.type" :value="item.xid" v-for="(item,index) in this.$store.getters.petTypes" :key="index" @change="breed1=''; breed2=''"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="pb-0">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Your pet need</div>
            <v-radio-group v-model="purpose" class="mt-2">
              <v-radio label="Lover (Mating Partner)" value="lover"></v-radio>
              <v-radio label="Friend (Playmate)" value="friend"></v-radio>
              <v-radio label="Adopter" value="adopter"></v-radio>
              <v-radio label="Buyer" value="buyer"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="py-0">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Name</div>
            <v-text-field class="pt-0" outlined dense v-model="name"></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Sex</div>
            <v-radio-group v-model="gender" class="mt-0" row>
              <v-radio label="Male" value="M"></v-radio>
              <v-radio label="Female" value="F"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="py-0">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Breed</div>

            <v-checkbox v-model="purebred" hide-details label="Purebred"></v-checkbox>

            <v-checkbox v-model="crossbred" label="Crossbred(Mixed Breed)" class="mt-0"></v-checkbox>

            <v-autocomplete class="mt-0" v-model="breed1" hide-no-data :filter="filterObject" :items="typeBreeds" item-text="breed" item-value="xid" label="Primary Breed" return-object>

              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.breed"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                </v-list-item-content>
              </template>

            </v-autocomplete>

            <v-autocomplete v-show="crossbred && breed1" v-model="breed2" :items="typeBreeds" item-text="breed" item-value="xid" label="Secondary Breed" return-object>

              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.breed"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                </v-list-item-content>
              </template>

            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="py-0">

            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Pedigree Certified</div>
            <v-radio-group v-model="pedigree_certified" class="mt-0" row>
              <v-radio label="Yes" :value="1"  class="py-2"></v-radio>
              <v-radio label="No" :value="0"  class="py-2"></v-radio>
            </v-radio-group>

          </v-col>
          <v-col cols="12" class="py-0">

            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">DNA Tested</div>
            <v-radio-group v-model="dna_tested" class="mt-0" row>
              <v-radio label="Yes" :value="1"  class="py-2"></v-radio>
              <v-radio label="No" :value="0"  class="py-2"></v-radio>
            </v-radio-group>

            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Vaccinated</div>
            <v-radio-group v-model="vaccinated" class="mt-0" row>
              <v-radio label="Yes" :value="1"  class="py-2"></v-radio>
              <v-radio label="No" :value="0"  class="py-2"></v-radio>
            </v-radio-group>

            <div v-if="purpose != 'lover'">
              <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Neutered</div>
              <v-radio-group v-model="neutered" class="mt-0 mb-4" row>
                <v-radio label="Yes" :value="1"  class="py-2"></v-radio>
                <v-radio label="No" :value="0"  class="py-2"></v-radio>
              </v-radio-group>
            </div>

            <div v-if="purpose == 'lover'">
              <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Breeding for the first time</div>
              <v-radio-group v-model="mating_first_time" class="mt-0 mb-4" row>
                <v-radio label="Yes" :value="1"  class="py-2"></v-radio>
                <v-radio label="No" :value="0"  class="py-2"></v-radio>
              </v-radio-group>
            </div>


          </v-col>
          <v-col cols="12" class="py-0">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Age</div>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="age_years" dense outlined type="number" placeholder="0" :suffix="age_years > 1 ? 'years' : 'year'" :rules="yearRules"></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field v-model="age_months" dense outlined type="number" placeholder="0" :suffix="age_months > 1 ? 'months' : 'month'" :rules="monthRules"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="py-0">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Weight</div>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="weight_number" dense outlined solo flat type="number" placeholder="0" :rules="weightRules"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select v-model="weight_unit"
                          dense outlined flat solo hide-details
                          :items="['lbs', 'kgs']">
                </v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="py-0">
            <div class="primary--text text-subtitle-1 font-weight-medium">Good with Kids?</div>

            <v-radio-group v-model="good_with_kids" class="mt-2 mb-4" row>
              <v-radio label="Yes" :value="1" class="py-2"></v-radio>
              <v-radio label="No" :value="0" class="py-2"></v-radio>
              <v-radio label="Dont Know" :value="''" class="py-2"></v-radio>
            </v-radio-group>

            <div class="primary--text text-subtitle-1 font-weight-medium">Good with Cats?</div>

            <v-radio-group v-model="good_with_cats" class="mt-2 mb-4" row>
              <v-radio label="Yes" :value="1" class="py-2"></v-radio>
              <v-radio label="No" :value="0" class="py-2"></v-radio>
              <v-radio label="Dont Know" :value="''" class="py-2"></v-radio>
            </v-radio-group>

            <div class="primary--text text-subtitle-1 font-weight-medium">Good with Dogs?</div>

            <v-radio-group v-model="good_with_other_dogs" class="mt-2 mb-4" row>
              <v-radio label="Yes" :value="1" class="py-2"></v-radio>
              <v-radio label="No" :value="0" class="py-2"></v-radio>
              <v-radio label="Dont Know" :value="''" class="py-2"></v-radio>
            </v-radio-group>

            <div class="primary--text text-subtitle-1 font-weight-medium">Potty Trained?</div>

            <v-radio-group v-model="potty_trained" class="mt-2 mb-4" row>
              <v-radio label="Yes" :value="1" class="py-2"></v-radio>
              <v-radio label="No" :value="0" class="py-2"></v-radio>
            </v-radio-group>
          </v-col>


          <v-col cols="12" class="py-0">
            <div class="primary--text text-subtitle-1 font-weight-medium">Size</div>

            <v-radio-group v-model="size" class="mt-2 mb-4">
              <v-radio :label="item.size" :value="item.xid" v-for="(item,index) in this.$store.getters.petSizes" :key="index"></v-radio>
            </v-radio-group>

          </v-col>

          <v-col cols="12" class="py-0" v-if="purpose=='buyer'">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Price</div>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="price" dense outlined solo flat type="number" placeholder="0" :rules="priceRules"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select v-model="price_ccy"
                          dense outlined solo flat hide-details
                          :items="['$ USD', '€ EUR', '$ CAD', '$ AUD', '£ GBP', '₹ INR']">
                </v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="py-0" v-if="purpose=='adopter'">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Adoption Fee</div>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="adoption_fees" dense outlined solo flat type="number" placeholder="0" :rules="priceRules"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select v-model="adoption_fees_ccy"
                          dense outlined solo flat hide-details
                          :items="['$ USD', '€ EUR', '$ CAD', '$ AUD', '£ GBP', '₹ INR']">
                </v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="py-0" v-if="purpose == 'lover' && gender == 'M'">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Stud Fee</div>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="stud_fees" dense outlined solo flat type="number" placeholder="0" :rules="priceRules"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select v-model="stud_fees_ccy"
                          dense outlined solo flat hide-details
                          :items="['$ USD', '€ EUR', '$ CAD', '$ AUD', '£ GBP', '₹ INR']">
                </v-select>
              </v-col>
            </v-row>
          </v-col>






          <v-col cols="12" class="py-0">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Instagram handle (optional)</div>
            <v-text-field class="pt-0" outlined dense v-model="insta_handle" placeholder="your pet's instagram handle" prefix="@"></v-text-field>

          </v-col>

          <v-col cols="12" class="py-0">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">More about your pet</div>
            <v-textarea dense outlined auto-grow row-height="30" rows="5" placeholder="Your pet's temperament, character and what are you looking for" v-model="description"></v-textarea>

          </v-col>

          <v-col cols="12" class="py-0">
            <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Location</div>

            <v-alert v-model="locationPicked" class="pl-0">
              <v-row align="center">
                <v-col class="grow">
                  {{location ? location.description : ''}}
                </v-col>
                <v-col class="shrink">
                  <v-btn icon @click="clearLocation">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>

            <div v-show="!locationPicked">

              <v-autocomplete v-model="location" :items="searchResults" :search-input.sync="search" flat hide-no-data placeholder="Please type your locality" item-text="description" item-value="placeId" clearable hint="So that we can find matches closer to you" persistent-hint prepend-inner-icon="mdi-map-marker-outline" return-object @change="getPlaceDetails" @click:clear="clearLocation"></v-autocomplete>

              <div class="py-4">
                <v-btn text small color="primary" @click="getCurrentLocation" :disabled="gettingLocation">
                  <v-icon left>mdi-crosshairs-gps</v-icon> {{gettingLocation ? 'Getting your location' : 'get your current location'}}
                </v-btn>
              </div>

            </div>

          </v-col>
        </v-row>

      </v-col>

    <v-card-actions>
        <v-btn text outlined @click="show = false" class="mr-1">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn  color="primary" @click="savePet" :loading="submitting" :disabled="submitting">Save</v-btn>
    </v-card-actions>

    <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="dataLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card-text>
  </v-card>
  </v-dialog>
</template>

<script>
import {
  Cropper
} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.compact.css';
import gmapsInit from '../../plugins/gmaps';

export default {
  name: 'EditPet',
  props: ['xid', 'value'],
  async mounted() {
    const google = await gmapsInit();
    //this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    this.service = new google.maps.places.AutocompleteService();
    this.geocoder = new google.maps.Geocoder();

    if (this.$store.getters.petTypes.length == 0) {
      this.getStaticData();
    }
    this.getPetDetails();
  },

  data: () => ({

    snackbar: false,
    snackbar_text: '',

    pet: {},

    purpose: '',
    pet_type: '',
    name: '',
    age_months: '',
    age_years: '',
    gender: '',
    crossbred: false,
    purebred: false,
    breed1: '',
    breed2: '',
    pedigree_certified: 0,
    dna_tested: 0,
    size: '',
    weight_number: '',
    weight_unit: 'lbs',
    description: '',
    price:'',
    insta_handle: '',

    vaccinated: '',
    neutered: '',
    mating_first_time: '',
    good_with_kids: '',
    good_with_cats: '',
    good_with_other_dogs: '',
    potty_trained: '',
    price_ccy: '$ USD',
    adoption_fees: '',
    adoption_fees_ccy: '$ USD',
    stud_fees: '',
    stud_fees_ccy: '$ USD',

    distance: '50',

    location: {
      description: '',
      placeId: '',
      state: '',
      country: '',
      zip: '',
      locality: '',
    },
    searchResults: [],
    service: null,
    geocoder: null,
    sessionToken: null,
    search: null,
    cordinates: null,
    locationPicked: false,

    gettingLocation: false,
    dataLoading: false,

    submitting: false,

    yearRules: [
      v => !v ? true : /^\d*$/.test(v) || 'Age must be valid',
      v => !v || v <= 20 || 'Age must be valid',
    ],

    monthRules: [
      v => !v ? true : /^\d*$/.test(v) || 'Age must be valid',
      v => !v || v <= 12 || 'Month must be valid',

    ],

    weightRules: [
      v => !v ? true : /^\d*$/.test(v) || 'Weight must be valid'
    ],

    priceRules:  [
      v => !v ? true : /^\d*$/.test(v) || 'Price must be valid'
    ],

  }),

  components: {
    Cropper
  },

  computed: {
    show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
    },
    typeBreeds() {
      let petTypes = this.$store.getters.petTypes;
      let selectedPetType = this.pet_type;

      let filteredItem = petTypes.filter(item => {
        return item.xid == selectedPetType
      });

      if (filteredItem.length > 0)
        return filteredItem[0].breeds;
      else
        return [];
    },

  },

  watch: {
    xid() {
        this.getPetDetails();
    },
    crossbred() {
      if (this.crossbred)
        this.purebred = false;
    },

    purebred() {
      if (this.purebred) {
        this.crossbred = false;
        this.breed2 = ''
      }

    },

    search(val) {
      if (val != '' && val !== null)
      {
        if((val.length > 3) && (val.length % 2 == 0)) {
          this.service.getPlacePredictions({
            input: val,
            //sessionToken: this.sessionToken,
            types: ['geocode']
          }, this.displaySuggestions)
        }
      }
    }
  },

  methods: {

    filterObject(item, queryText, itemText) {
      return (
          item.breed.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    getStaticData() {
      this.dataLoading = true;
      this.$store.dispatch('getStaticData').then(() => {
        this.dataLoading = false;
      })
          .catch(err => {
            //console.log(err);
            this.dataLoading = false;
            this.snackbar_text = "Oops, there is an error loading details for the page";
            this.snackbar = true;
          });
    },

    getPetDetails() {
      this.dataLoading = true;
      this.$store.dispatch('petFromId', this.xid).then((resp) => {
        this.pet = resp.data;
        this.dataLoading = false;

        this.purpose = this.pet.purpose
        this.pet_type = this.pet.pet_type.xid
        this.size = this.pet.pet_size.xid
        this.name = this.pet.name
        this.age_months = this.pet.age.months ? this.pet.age.months : 0
        this.age_years = this.pet.age.years ? this.pet.age.years : 0
        this.gender = this.pet.gender;
        this.crossbred = this.pet.crossbred ? true : false;
        this.purebred = this.pet.purebred ? true : false;
        this.breed1 = this.pet.primary_breed;
        this.breed2 = this.pet.secondary_breed ? this.pet.secondary_breed : null;
        this.pedigree_certified = this.pet.pedigree_certified
        this.dna_tested = this.pet.dna_tested
        this.weight_number = this.pet.weight
        this.weight_unit = this.pet.weight_unit
        this.description = this.pet.description
        this.price = this.pet.price
        this.insta_handle = this.pet.insta_handle
        this.location = {
          description: this.pet.location_description,
          placeId: this.pet.place_id,
          state: this.pet.state,
          country: this.pet.country,
          zip: this.pet.zip,
          locality: this.pet.locality,
        };
        this.locationPicked = true;
        this.cordinates = {
          lat: this.pet.latitude,
          lng: this.pet.longitude
        };

        this.vaccinated = this.pet.vaccinated == null ? '' : this.pet.vaccinated;
        this.neutered = this.pet.neutered == null ? '' : this.pet.neutered;
        this.mating_first_time = this.pet.mating_first_time == null ? '' : this.pet.mating_first_time;
        this.good_with_kids = this.pet.good_with_kids == null ? '' : this.pet.good_with_kids;
        this.good_with_cats = this.pet.good_with_cats == null ? '' : this.pet.good_with_cats;
        this.good_with_other_dogs = this.pet.good_with_other_dogs == null ? '' : this.pet.good_with_other_dogs;
        this.potty_trained = this.pet.potty_trained == null ? '' : this.pet.potty_trained;
        this.price_ccy = this.pet.price_ccy == null ? '$ USD' : this.pet.price_ccy;
        this.adoption_fees = this.pet.adoption_fees;
        this.adoption_fees_ccy = this.pet.adoption_fees_ccy == null ? '$ USD' : this.pet.adoption_fees_ccy;
        this.stud_fees = this.pet.stud_fees;
        this.stud_fees_ccy = this.pet.stud_fees_ccy == null ? '$ USD' : this.pet.stud_fees_ccy;

      })
          .catch(err => {
            this.overlay = false;
            this.snackbar_text = "Oops, Unable to get the pet";
            this.snackbar = true;
            this.$router.replace('/home');
          });

    },

    clearLocation() {

      //console.log('clear location');
      this.location = {
        description: '',
        placeId: '',
        state: '',
        country: '',
        zip: '',
        locality: '',
      };
      this.cordinates = null;
      this.searchResults = [];
      this.locationPicked = false;

      //this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    },

    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      this.searchResults = predictions.map(prediction => ({
        description: prediction.description,
        placeId: prediction.place_id
      }))
    },

    getPlaceDetails() {
      if (this.location && this.location.placeId != '') {
        //console.log(this.location.placeId);
        this.geocoder.geocode({
          placeId: this.location.placeId
        }, (results, status) => {
          if (status == window.google.maps.GeocoderStatus.OK) {

            let res = results[0];

            for (var i = 0; i < res.address_components.length; i++) {
              var addressType = res.address_components[i].types[0];
              var addressType2 = res.address_components[i].types[1];

              if (addressType == 'administrative_area_level_1')
                this.location.state = res.address_components[i].long_name;
              else if (addressType == 'country')
                this.location.country = res.address_components[i].short_name;
              else if (addressType == 'postal_code')
                this.location.zip = res.address_components[i].long_name;
              else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                this.location.locality = res.address_components[i].long_name;

            }

            this.cordinates = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            };

            this.locationPicked = true;
            //console.log(this.cordinates);
            //this.closeFieldDialog();

          }
        });
      }
    },

    async getCurrentLocation() {
      this.gettingLocation = true;
      try {

        let location = await this.getLocation();

        //console.log(location);
        this.cordinates = {
          lat: location.coords.latitude,
          lng: location.coords.longitude
        };

        this.geocoder.geocode({
          location: this.cordinates
        }, (results, status) => {
          if (status == window.google.maps.GeocoderStatus.OK) {

            //console.log(results[0]);
            this.gettingLocation = false;

            let res = results[0];

            for (var i = 0; i < res.address_components.length; i++) {
              var addressType = res.address_components[i].types[0];
              var addressType2 = res.address_components[i].types[1];

              if (addressType == 'administrative_area_level_1')
                this.location.state = res.address_components[i].long_name;
              else if (addressType == 'country')
                this.location.country = res.address_components[i].short_name;
              else if (addressType == 'postal_code')
                this.location.zip = res.address_components[i].long_name;
              else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                this.location.locality = res.address_components[i].long_name;

            }

            this.location.description = results[0].formatted_address;
            this.location.placeId = results[0].place_id;

            this.searchResults = [{
              description: results[0].formatted_address,
              placeId: results[0].place_id
            }];

            this.locationPicked = true;

            //console.log(this.searchResults);

            //this.closeFieldDialog();

          }
        });

      } catch (e) {
        this.gettingLocation = false;
        this.errorStr = e.message;
      }

    },

    async getLocation() {

      return new Promise((resolve, reject) => {

        if (!("geolocation" in navigator)) {
          reject(new Error('Geolocation is not available.'));
        }

        navigator.geolocation.getCurrentPosition(pos => {
          resolve(pos);
        }, err => {
          reject(err);
        });

      });
    },

    savePet() {
        if (this.pet == '') {
            this.snackbar_text = "Please select the type of pet";
            this.snackbar = true;
        } else if (this.purpose == '') {
            this.snackbar_text = "Please select your purpose";
            this.snackbar = true;
        } else if (this.name == '') {
            this.snackbar_text = "Please enter your pet's name";
            this.snackbar = true;
        } else if (this.name.includes("@")) {
            this.snackbar_text = "Please enter a valid name";
            this.snackbar = true;
        } else if (this.gender == '') {
            this.snackbar_text = "Please select your pet's gender";
            this.snackbar = true;
        } else if (this.breed1 == '' || this.breed1 == null) {
            this.snackbar_text = "Please select your pet's breed";
            this.snackbar = true;
        } else if (this.age_months == '' && this.age_years == '') {
            this.snackbar_text = "Please enter your pet's age";
            this.snackbar = true;
        } else if (this.weight_number == '') {
            this.snackbar_text = "Please enter your pet's weight";
            this.snackbar = true;
        } else if (this.size == '') {
            this.snackbar_text = "Please select your pet's size";
            this.snackbar = true;
        } else if (this.description == '') {
            this.snackbar_text = "Please write down bit more details of your pet";
            this.snackbar = true;
        } else if (!this.location || this.location.description == '') {
            this.snackbar_text = "Please pick your location";
            this.snackbar = true;
        } else if (this.purpose == 'buyer' && this.price == '') {
            this.snackbar_text = "Please enter a price";
            this.snackbar = true;
        } else {
            this.submitting = true;

            let formData = new FormData();

            formData.append('id', this.xid);
            formData.append('pet_type', this.pet_type);
            formData.append('size', this.size);
            formData.append('purpose', this.purpose);
            formData.append('crossbred', this.crossbred);
            formData.append('purebred', this.purebred);
            formData.append('breed1', JSON.stringify(this.breed1));
            formData.append('breed2', JSON.stringify(this.breed2));
            formData.append('pedigree_certified', this.pedigree_certified);
            formData.append('dna_tested', this.dna_tested);
            formData.append('gender', this.gender);
            formData.append('name', this.name);
            formData.append('age_months', this.age_months);
            formData.append('age_years', this.age_years);
            formData.append('weight_number', this.weight_number);
            formData.append('weight_unit', this.weight_unit);
            formData.append('description', this.description);
            formData.append('price', this.price);
            formData.append('insta_handle', this.insta_handle);
            formData.append('location', JSON.stringify(this.location));
            formData.append('cordinates', JSON.stringify(this.cordinates));

            formData.append('vaccinated', this.vaccinated);
            formData.append('neutered', this.neutered);
            formData.append('mating_first_time', this.mating_first_time);
            formData.append('good_with_kids', this.good_with_kids);
            formData.append('good_with_cats', this.good_with_cats);
            formData.append('good_with_other_dogs', this.good_with_other_dogs);
            formData.append('potty_trained', this.potty_trained);
            formData.append('price_ccy', this.price_ccy);
            formData.append('adoption_fees', this.adoption_fees);
            formData.append('adoption_fees_ccy', this.adoption_fees_ccy);
            formData.append('stud_fees', this.stud_fees);
            formData.append('stud_fees_ccy', this.stud_fees_ccy);

            let xid = this.xid

            this.$store.dispatch('editPet', {
                xid,
                formData
            }).then((resp) => {
                this.submitting = false;
                this.show = false;
                this.$emit('fetch-user-detail');
            }).catch(err => {
                this.snackbar_text = "Oops, We encountered an error while saving. Please try again.";
                this.snackbar = true;
                this.submitting = false;
            });
        }
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>>>fieldset {
  border-color: #adb5bd;
}
</style>
