<template>

<div class="pa-2">
    <div v-if="!user.subscription_route" class="text-center">
        User do not have any subscriptions
    </div>
    <div v-else>
        <v-row no-gutters>

            <v-col cols="12" md="6" v-for="(subscription, index) in user.subscriptions" :key="'s-' + index">
                <v-card class="ma-4 mild-shadow">
                    <v-card-text>
                    <v-row>
                        <v-col><div class="text-overline info--text">Stripe</div></v-col>
                        <v-col class="text-right">
                            <v-chip small class="text-uppercase font-weight-medium" v-show="subscription.stripe_status == 'active'" color="success">ACTIVE</v-chip>
                            <v-chip small class="text-uppercase font-weight-medium" v-show="subscription.stripe_status != 'active'" color="error">{{ subscription.stripe_status }}</v-chip>
                        </v-col>
                    </v-row>
                  
                        <v-row dense>
                            <v-col>Subscription ID</v-col>
                            <v-col>{{subscription.stripe_id}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Subscription Plan</v-col>
                            <v-col>{{subscription.subscriptionPlan}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Subscription Name</v-col>
                            <v-col>{{subscription.name}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Product</v-col>
                            <v-col>{{subscription.items[0].stripe_product}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Created At</v-col>
                            <v-col>{{subscription.created_at | moment("D MMM YY, h:mm a")}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Ends At</v-col>
                            <v-col>{{ formatDate(subscription.ends_at) }}</v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="6" v-for="(subscription, index) in user.fast_springs" :key="'fs-' + index">
                <v-card class="ma-4 mild-shadow">
                    <v-card-text>
                        <v-row dense>
                        <v-col><div class="text-overline info--text">Fastspring</div></v-col>
                        <v-col class="text-right">
                            <v-chip small class="text-uppercase font-weight-medium" v-show="subscription.state == 'active'" color="success">{{ subscription.state }}</v-chip>
                            <v-chip small class="text-uppercase font-weight-medium" v-show="subscription.state != 'active'" color="error">{{ subscription.state }}</v-chip>
                        </v-col>
                    </v-row>
                        <v-row dense>
                            <v-col>Subscription Account</v-col>
                            <v-col>{{subscription.account}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Subscription Product</v-col>
                            <v-col>{{subscription.product}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Subscription</v-col>
                            <v-col>{{subscription.subscription}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Subscription Order</v-col>
                            <v-col>{{subscription.order}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Subscription Price</v-col>
                            <v-col>{{subscription.price}} {{subscription.currency}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Payment Type</v-col>
                            <v-col>{{subscription.payment_type}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Created At</v-col>
                            <v-col>{{subscription.created_at | moment("D MMM YY, h:mm a")}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Subscription Ends At</v-col>
                            <v-col>{{ formatDate(subscription.ends_at) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Last Payment At</v-col>
                            <v-col>{{ formatDate(subscription.last_payment) }}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Next Payment At</v-col>
                            <v-col>{{ formatDate(subscription.next_payment) }}</v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>



            <v-col cols="12" md="6" v-for="(subscription, index) in user.apple_payments" :key="'a-' + index">
                <v-card class="ma-4 mild-shadow">
                    <v-card-text>
                    <v-row>
                        <v-col><div class="text-overline info--text">Apple</div></v-col>
                        <v-col class="text-right">
                            <v-chip small class="text-uppercase font-weight-medium" v-show="subscription.status_desc == 'active'" color="success">ACTIVE</v-chip>
                            <v-chip small class="text-uppercase font-weight-medium" v-show="subscription.status_desc != 'active'" color="error">{{ subscription.status_desc }}</v-chip>
                        </v-col>
                    </v-row>
                  
                        <v-row dense>
                            <v-col>Original Transaction ID</v-col>
                            <v-col>{{subscription.original_transaction_id}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Transaction ID</v-col>
                            <v-col>{{subscription.transaction_id}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Product ID</v-col>
                            <v-col>{{subscription.product_id}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Route</v-col>
                            <v-col>{{subscription.route}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Notification Type</v-col>
                            <v-col>{{subscription.notification_type}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Notification Sub Type</v-col>
                            <v-col>{{subscription.notification_sub_type}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Country</v-col>
                            <v-col>{{subscription.country}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Price</v-col>
                            <v-col>{{(subscription.price / 1000).toFixed(2)}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Currency</v-col>
                            <v-col>{{subscription.currency}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Created At</v-col>
                            <v-col>{{subscription.created_at | moment("D MMM YY, h:mm a")}}</v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>


            <v-col cols="12" md="6" v-for="(subscription, index) in user.cash_free_orders" :key="'cf-' + index">
                <v-card class="ma-4 mild-shadow">
                    <v-card-text>
                    <v-row>
                        <v-col><div class="text-overline info--text">Cashfree</div></v-col>
                        <v-col class="text-right">
                            <v-chip small class="text-uppercase font-weight-medium" v-show="subscription.order_status == 'PAID'" color="success">PAID</v-chip>
                            <v-chip small class="text-uppercase font-weight-medium" v-show="subscription.order_status != 'PAID'" color="error">{{ subscription.order_status }}</v-chip>
                        </v-col>
                    </v-row>
                  
                        <v-row dense>
                            <v-col>Order ID</v-col>
                            <v-col>{{subscription.order_id}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Product Name</v-col>
                            <v-col>{{subscription.product_name}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Order Amount</v-col>
                            <v-col>{{subscription.order_amount}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>Created At</v-col>
                            <v-col>{{subscription.created_at | moment("D MMM YY, h:mm a")}}</v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>

    </div>

    
</div>
</template>

<script>
import moment from 'moment';
export default {
    name: 'UserSubscriptions',
    props: ['user'],
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
    formatDate(date) {
      if (moment(date).isValid()) {
        return moment(date).format("D MMM YY, h:mm a");
      } else {
        return "-";
      }
    }
  }

}
</script>
