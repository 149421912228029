<template>
    <div>
        <v-card class="mild-shadow rounded-lg">

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="text--primary text-caption"><b>User:</b>
                        {{ description.email }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-list-item-action-text class="secondary--text">{{ $moment(description.updated_at).fromNow(true) }}</v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>

            <div>
            <v-alert v-if="description.forbidden_text" text dense type="error" class="text-caption mb-0">
                Forbidden text: <strong>{{ description.forbidden_text }}</strong>
            </v-alert>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">Description:</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn small text color="primary" outlined @click="descriptionDialog = true">
                    EDIT
                </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-card-text class="text-pre-wrap pt-0">
                {{ description.description }}
            </v-card-text>
        </div>
            

            <div v-if="description.willingness_to_travel">
            <v-divider></v-divider>
            <v-alert v-if="description.forbidden_text_willingness" text dense type="error" class="text-caption mb-0">
                Forbidden text: <strong>{{ description.forbidden_text_willingness }}</strong>
            </v-alert>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">Willingness to Travel:</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn small text color="primary" outlined @click="willingnessToTravelDialog = true">
                    EDIT
                </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-card-text class="text-pre-wrap pt-0">
                {{ description.willingness_to_travel }}
            </v-card-text>
            </div>

            <div v-if="description.breeding_terms">
            <v-divider></v-divider>
            <v-alert v-if="description.forbidden_text_breeding_terms" text dense type="error" class="text-caption mb-0">
                Forbidden text: <strong>{{ description.forbidden_text_breeding_terms }}</strong>
            </v-alert>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">Conditions for breeding:</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn small text color="primary" outlined @click="breedingTermsDialog = true">
                    EDIT
                </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-card-text class="text-pre-wrap pt-0">
                {{ description.breeding_terms }}
            </v-card-text>
            </div>
    
            <div v-if="description.accommodation_responsibility">
            <v-divider></v-divider>
            <v-alert v-if="description.forbidden_text_accommodation" text dense type="error" class="text-caption mb-0">
                Forbidden text: <strong>{{ description.forbidden_text_accommodation }}</strong>
            </v-alert>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">Expected accommodation:</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn small text color="primary" outlined @click="accommodationResponsibilityDialog = true">
                    EDIT
                </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-card-text class="text-pre-wrap pt-0">
                {{ description.accommodation_responsibility }}
            </v-card-text>
            </div>


            <div v-if="description.preferred_payment_method">
            <v-divider></v-divider>
            <v-alert v-if="description.forbidden_text_payment_method" text dense type="error" class="text-caption mb-0">
                Forbidden text: <strong>{{ description.forbidden_text_payment_method }}</strong>
            </v-alert>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">Preferred payment options:</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn small text color="primary" outlined @click="preferredPaymentMethodDialog = true">
                    EDIT
                </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-card-text class="text-pre-wrap pt-0">
                {{ description.preferred_payment_method }}
            </v-card-text>
            </div>


            <v-card-actions>
                <v-btn icon @click="likeDescription" :loading="favLoading">
                    <v-icon color="red" v-if="description.description_liked">
                        mdi-heart
                    </v-icon>
                    <v-icon v-else color="grey lighten-1">
                        mdi-heart-outline
                    </v-icon>
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small color="success" depressed v-if="description.description_verified" @click="approve(0)"
                    :loading="approving" :disabled="approving">
                    APPROVED
                </v-btn>
                <v-btn small text color="success" outlined v-else @click="approve(1)" :loading="approving"
                    :disabled="approving">
                    APPROVE
                </v-btn>
            </v-card-actions>

        </v-card>

        <v-dialog v-model="descriptionDialog" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1 font-weight-bold">Description</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="descriptionDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="descriptionForm" v-model="valid" lazy-validation @submit.prevent="updateDescription">
                    <v-card-text class="pt-4">
                        <v-textarea dense outlined auto-grow row-height="30" rows="5" placeholder="Description"
                            v-model="description.description" :rules="descriptionRules"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="updating" :disabled="!valid || updating">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="willingnessToTravelDialog" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1 font-weight-bold">Willingness to travel</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="willingnessToTravelDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="willingnessToTravelForm" v-model="valid" lazy-validation
                    @submit.prevent="updateWillingnessToTravel">
                    <v-card-text class="pt-4">
                        <v-textarea dense outlined auto-grow row-height="30" rows="5"
                            placeholder="How far can you travel for meetings?" v-model="description.willingness_to_travel"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="updating" :disabled="!valid || updating">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="breedingTermsDialog" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1 font-weight-bold">Breeding Terms</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="breedingTermsDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="descriptionForm" v-model="valid" lazy-validation @submit.prevent="updateBreedingTerms">
                    <v-card-text class="pt-4">
                        <v-textarea dense outlined auto-grow row-height="30" rows="5"
                            placeholder="Describe any special conditions you require for breeding."
                            v-model="description.breeding_terms"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="updating" :disabled="!valid || updating">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="accommodationResponsibilityDialog" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1 font-weight-bold">Accommodation Responsibility</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="accommodationResponsibilityDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="descriptionForm" v-model="valid" lazy-validation
                    @submit.prevent="updateAccommodationResponsibility">
                    <v-card-text class="pt-4">
                        <v-textarea dense outlined auto-grow row-height="30" rows="5"
                            placeholder="Will you arrange accommodation, or should the other party?"
                            v-model="description.accommodation_responsibility"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="updating" :disabled="!valid || updating">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="preferredPaymentMethodDialog" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1 font-weight-bold">Preferred Payment Method</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="preferredPaymentMethodDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="descriptionForm" v-model="valid" lazy-validation @submit.prevent="updatePreferredPaymentType">
                    <v-card-text class="pt-4">
                        <v-textarea dense outlined auto-grow row-height="30" rows="5"
                            placeholder="Do you prefer cash or online methods?"
                            v-model="description.preferred_payment_method"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="updating" :disabled="!valid || updating">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'DescriptionCard',
    props: ['description'],
    data() {
        return {
            approving: false,
            updating: false,
            descriptionDialog: false,
            willingnessToTravelDialog: false,
            breedingTermsDialog: false,
            accommodationResponsibilityDialog: false,
            preferredPaymentMethodDialog: false,
            favLoading: false,

            valid: true,

            descriptionRules: [
                v => !!v || 'Description is required',
            ],
        }
    },

    methods: {

        approve(val) {
            let pet_id = this.description.xid;
            let description = this.description.description
            let approve = val

            this.approving = true;
            this.$store.dispatch('saveDescription', {
                description,
                approve,
                pet_id,
            }).then(() => {
                this.approving = false;
                this.description.description_verified = val
            })
                .catch(err => {
                    this.approving = false;
                });
        },

        updateDescription() {
            let pet_id = this.description.xid;
            let description = this.description.description
            let approve = this.description.description_verified

            this.updating = true;
            this.$store.dispatch('saveDescription', {
                description,
                approve,
                pet_id,
            }).then(() => {
                this.updating = false;
                this.descriptionDialog = false;
            })
                .catch(err => {
                    this.updating = false;
                });
        },

        updateWillingnessToTravel() {
            let pet_id = this.description.xid;
            let willingness_to_travel = this.description.willingness_to_travel

            this.updating = true;
            this.$store.dispatch('saveWillingnessToTravel', {
                willingness_to_travel,
                pet_id,
            }).then(() => {
                this.updating = false;
                this.willingnessToTravelDialog = false;
            })
                .catch(err => {
                    this.updating = false;
                });
        },
        updateBreedingTerms() {
            let pet_id = this.description.xid;
            let breeding_terms = this.description.breeding_terms

            this.updating = true;
            this.$store.dispatch('saveBreedingTerms', {
                breeding_terms,
                pet_id,
            }).then(() => {
                this.updating = false;
                this.breedingTermsDialog = false;
            })
                .catch(err => {
                    this.updating = false;
                });
        },
        updateAccommodationResponsibility() {
            let pet_id = this.description.xid;
            let accommodation_responsibility = this.description.accommodation_responsibility

            this.updating = true;
            this.$store.dispatch('saveAccommodationResponsibility', {
                accommodation_responsibility,
                pet_id,
            }).then(() => {
                this.updating = false;
                this.accommodationResponsibilityDialog = false;
            })
                .catch(err => {
                    this.updating = false;
                });
        },
        updatePreferredPaymentType() {
            let pet_id = this.description.xid;
            let preferred_payment_method = this.description.preferred_payment_method

            this.updating = true;
            this.$store.dispatch('savePreferredPaymentType', {
                preferred_payment_method,
                pet_id,
            }).then(() => {
                this.updating = false;
                this.preferredPaymentMethodDialog = false;
            })
                .catch(err => {
                    this.updating = false;
                });
        },

        likeDescription() {
            let pet_id = this.description.xid;

            this.favLoading = true;
            this.$store.dispatch('likeDescription', {
                pet_id,
            }).then(() => {
                this.favLoading = false;
                this.description.description_liked = !this.description.description_liked
            })
                .catch(err => {
                    this.favLoading = false;
                });

        },

    }

}
</script>
