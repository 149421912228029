<template>
    <div>
        <v-card class="mild-shadow rounded-lg">

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="text--primary text-caption"><b>User:</b>
                        {{ description.email }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-list-item-action-text class="secondary--text">{{ $moment(description.updated_at).fromNow(true) }}</v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>

            <div>
            <v-alert v-if="description.forbidden_text" text dense type="error" class="text-caption mb-0">
                Forbidden text: <strong>{{ description.forbidden_text }}</strong>
            </v-alert>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">Description:</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn small text color="primary" outlined @click="descriptionDialog = true">
                    EDIT
                </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-card-text class="text-pre-wrap pt-0">
                {{ description.description }}
            </v-card-text>
        </div>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="success" depressed v-if="description.description_verified" @click="approve(0)"
                    :loading="approving" :disabled="approving">
                    APPROVED
                </v-btn>
                <v-btn small text color="success" outlined v-else @click="approve(1)" :loading="approving"
                    :disabled="approving">
                    APPROVE
                </v-btn>
            </v-card-actions>

        </v-card>

        <v-dialog v-model="descriptionDialog" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1 font-weight-bold">Description</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="descriptionDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="descriptionForm" v-model="valid" lazy-validation @submit.prevent="updateDescription">
                    <v-card-text class="pt-4">
                        <v-textarea dense outlined auto-grow row-height="30" rows="5" placeholder="Description"
                            v-model="description.description" :rules="descriptionRules"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="updating" :disabled="!valid || updating">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'PreferenceDescriptionCard',
    props: ['description'],
    data() {
        return {
            approving: false,
            updating: false,
            descriptionDialog: false,
            favLoading: false,

            valid: true,

            descriptionRules: [
                v => !!v || 'Description is required',
            ],
        }
    },

    methods: {

        approve(val) {
            let pref_id = this.description.xid;
            let description = this.description.description
            let approve = val

            this.approving = true;
            this.$store.dispatch('savePreferenceDescription', {
                description,
                approve,
                pref_id,
            }).then(() => {
                this.approving = false;
                this.description.description_verified = val
            })
                .catch(err => {
                    this.approving = false;
                });
        },

        updateDescription() {
            let pref_id = this.description.xid;
            let description = this.description.description
            let approve = this.description.description_verified

            this.updating = true;
            this.$store.dispatch('savePreferenceDescription', {
                description,
                approve,
                pref_id,
            }).then(() => {
                this.updating = false;
                this.descriptionDialog = false;
            })
                .catch(err => {
                    this.updating = false;
                });
        },
    }

}
</script>
