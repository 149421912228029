import Vue from 'vue'
import Vuex from 'vuex'
import VueMoment from 'vue-moment'

import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import VueCookie from 'vue-cookie'

import auth from './auth'
import app from './app'

Vue.use(Vuex)
Vue.use(VueCookie)

export default new Vuex.Store({
  modules: {
      auth,
      app,
  },
  state: {
      petTypes:[],
      petSizes:[],
      routerHistory: [],
  },
  getters: {


      petTypes: state => state.petTypes,
      petSizes: state => state.petSizes,
      
      previousRoute: (state) => {
          const historyLen = state.routerHistory.length;
          if (historyLen == 0) return null;
          return state.routerHistory[historyLen - 1];
        },

      breedDetails: (state) => (type_id, breed_id) =>{
          let pet_type = state.petTypes.find(
              pet => pet.xid === type_id
          );
          return pet_type.breeds.find(
              breed => breed.xid === breed_id
          );
      }, 

      sizeDetails: (state) => (size_id) =>{
          return state.petSizes.find(
              size => size.xid === size_id
          );
      }, 

      typeDetails: (state) => (type_id) =>{
          return state.petTypes.find(
              type => type.xid === type_id
          );
      }, 

  },
  mutations: {

      setStaticData(state,payload)
      {
          state.petTypes = payload.petTypes;
          state.petSizes = payload.petSizes;
      },




  },
  actions: {
    


      getStaticData({ commit }) {
          return new Promise((resolve, reject) => {
              axios
                  .get("/v1/static-data")
                  .then(resp => {
                      const data = resp.data;
                      commit("setStaticData", data);
                      resolve();
                  })
                  .catch(err => {
                      Bugsnag.notify(err);
                      reject(err);
                  });
          });
      },


    }
  
})
