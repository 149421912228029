<template>
  <video ref="videoPlayer" class="video-js"></video>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css'

export default {
  name: 'VideoPlayer',
  props: {
    source: String,
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {

    let type = this.source.startsWith('https://storage.googleapis.com/') ? 'video/mp4' : 'application/x-mpegURL';

    this.options.sources = [
      {
        src:this.source,
        type: type
      }
    ];
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
    });

    this.player.bigPlayButton.on('click', function(event) {
        let video_id = this.id().split('_')[0];
        const videos = document.querySelectorAll('.video-js');
        videos.forEach(video => {
            if (video.player.id() != video_id) {
                video.player.pause();
            } else {
                video.player.play();
            }
        });
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>
<style>
.video-js {
  width: 100%;
  height: 100%;
}
</style>