<template>
    <v-row no-gutters>
        <v-col cols="12" class="text-center" v-if="!user.scamReportings.length">
          User do not have any scam reportings
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-else v-for="(item, index) in user.scamReportings" :key="index" class="pa-2">
          <v-card class="mild-shadow ma-1" :color="item.reported_by == user.xid ? '#E8F5E9' : '#FFEBEE'">
              <v-card-text class="py-0">
                  <v-row>
                      <v-col cols="12" md="6">
                          <div class="text-caption font-weight-medium black--text">Suspect:</div>
                          <div class="text-caption">{{item.scam_user.name}}</div>
                          <div class="text-caption">{{item.scam_user.email}}</div>
                          <v-divider class="my-2"></v-divider>
                      </v-col>
                      <v-col cols="12" md="6">
                          <div class="text-caption font-weight-medium black--text">Reported By:</div>
                          <div class="text-caption">{{item.reported_user.name}}</div>
                          <div class="text-caption">{{item.reported_user.email}}</div>
                          <v-divider class="my-2"></v-divider>
                          <div class="text-caption font-weight-medium black--text">Reason:</div>
                          <div class="text-caption">{{item.reason}}</div>
                      </v-col>
                  </v-row>
              </v-card-text>
          </v-card>
        </v-col>
    </v-row>

</template>

<script>
export default {
  name: 'UserScamReporting',
  props: ['user'],
}
</script>
