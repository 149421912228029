<template>
    <div>
        <v-dialog v-model="editDialog" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <div class="text-subtitle-1 font-weight-bold">{{ title }}</div>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="editDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="descriptionForm" v-model="valid" lazy-validation @submit.prevent="updatePetInfo">
                    <v-card-text class="pt-4">
                        <v-textarea dense outlined auto-grow row-height="30" rows="5" placeholder="Description"
                            v-model="updateValue" ></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="updating" :disabled="!valid || updating">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
      <v-card class="mild-shadow rounded-lg">

        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle class="text--primary text-caption"><b>User:</b>
              {{ pet.user.email }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text class="secondary--text">{{ $moment(pet.updated_at).fromNow(true) }}
              </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>


        <div>
          <v-alert v-if="pet.forbidden_text_health_issues" text dense type="error" class="text-caption mb-0">
            Forbidden text: <strong>{{ pet.forbidden_text_health_issues }}</strong>
          </v-alert>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-caption font-weight-bold">{{ health_issues }}:</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small text color="primary" outlined @click="title=health_issues;updateValue=pet.health_issues;updateField='health_issues';editDialog = true">
                EDIT
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text class="text-pre-wrap pt-0">
            {{ pet.health_issues }}
          </v-card-text>
        </div>

        <div v-if="pet.temperament">
          <v-divider></v-divider>
          <v-alert v-if="pet.forbidden_text_temperament" text dense type="error" class="text-caption mb-0">
            Forbidden text: <strong>{{ pet.forbidden_text_temperament }}</strong>
          </v-alert>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-caption font-weight-bold">{{ temperament }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small text color="primary" outlined @click="title=temperament;updateValue=pet.temperament;updateField='temperament';editDialog = true">
                EDIT
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text class="text-pre-wrap pt-0">
            {{ pet.temperament }}
          </v-card-text>
        </div>

        <div v-if="pet.care_and_placement_plan">
          <v-divider></v-divider>
          <v-alert v-if="pet.forbidden_text_care_and_placement_plan" text dense type="error" class="text-caption mb-0">
            Forbidden text: <strong>{{ pet.forbidden_text_care_and_placement_plan }}</strong>
          </v-alert>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-caption font-weight-bold">{{ care_and_placement_plan }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small text color="primary" outlined @click="title=care_and_placement_plan;updateValue=pet.care_and_placement_plan;updateField='care_and_placement_plan';editDialog = true">
                EDIT
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text class="text-pre-wrap pt-0">
            {{ pet.care_and_placement_plan }}
          </v-card-text>
        </div>

        <div v-if="pet.pet_source">
          <v-divider></v-divider>
          <v-alert v-if="pet.forbidden_text_pet_source" text dense type="error" class="text-caption mb-0">
            Forbidden text: <strong>{{ pet.forbidden_text_pet_source }}</strong>
          </v-alert>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-caption font-weight-bold">{{ pet_source }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small text color="primary" outlined @click="title=pet_source;updateValue=pet.pet_source;updateField='pet_source';editDialog = true">
                EDIT
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text class="text-pre-wrap pt-0">
            {{ pet.pet_source }}
          </v-card-text>
        </div>

        <div v-if="pet.living_situation">
          <v-divider></v-divider>
          <v-alert v-if="pet.forbidden_text_living_situation" text dense type="error" class="text-caption mb-0">
            Forbidden text: <strong>{{ pet.forbidden_text_living_situation }}</strong>
          </v-alert>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-caption font-weight-bold">{{ living_situation }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small text color="primary" outlined @click="title=living_situation;updateValue=pet.living_situation;updateField='living_situation';editDialog = true">
                EDIT
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text class="text-pre-wrap pt-0">
            {{ pet.living_situation }}
          </v-card-text>
        </div>

        <div v-if="pet.diet_and_exercise">
          <v-divider></v-divider>
          <v-alert v-if="pet.forbidden_text_diet_and_exercise" text dense type="error" class="text-caption mb-0">
            Forbidden text: <strong>{{ pet.forbidden_text_diet_and_exercise }}</strong>
          </v-alert>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-caption font-weight-bold">{{ diet_and_exercise }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small text color="primary" outlined @click="title=diet_and_exercise;updateValue=pet.diet_and_exercise;updateField='diet_and_exercise';editDialog = true">
                EDIT
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text class="text-pre-wrap pt-0">
            {{ pet.diet_and_exercise }}
          </v-card-text>
        </div>

        <div v-if="pet.rehoming_reason">
          <v-divider></v-divider>
          <v-alert v-if="pet.forbidden_text_rehoming_reason" text dense type="error" class="text-caption mb-0">
            Forbidden text: <strong>{{ pet.forbidden_text_rehoming_reason }}</strong>
          </v-alert>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-caption font-weight-bold">{{rehoming_reason}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small text color="primary" outlined @click="title=rehoming_reason;updateValue=pet.rehoming_reason;updateField='rehoming_reason';editDialog = true">
                EDIT
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text class="text-pre-wrap pt-0">
            {{ pet.rehoming_reason }}
          </v-card-text>
        </div>

        <div v-if="pet.included_items">
          <v-divider></v-divider>
          <v-alert v-if="pet.forbidden_text_included_items" text dense type="error" class="text-caption mb-0">
            Forbidden text: <strong>{{ pet.forbidden_text_included_items }}</strong>
          </v-alert>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-caption font-weight-bold">{{included_items}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small text color="primary" outlined @click="title=included_items;updateValue=pet.included_items;updateField='included_items';editDialog = true">
                EDIT
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text class="text-pre-wrap pt-0">
            {{ pet.included_items }}
          </v-card-text>
        </div>

        <div v-if="pet.health_test_results">
          <v-divider></v-divider>
          <v-alert v-if="pet.forbidden_text_health_test_results" text dense type="error" class="text-caption mb-0">
            Forbidden text: <strong>{{ pet.forbidden_text_health_test_results }}</strong>
          </v-alert>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-caption font-weight-bold">{{health_test_results}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small text color="primary" outlined @click="title=health_test_results;updateValue=pet.health_test_results;updateField='health_test_results';editDialog = true">
                EDIT
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text class="text-pre-wrap pt-0">
            {{ pet.health_test_results }}
          </v-card-text>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="success" depressed v-if="pet.extra_info_verified" @click="approve(0)"
                 :loading="approving" :disabled="approving">
            APPROVED
          </v-btn>
          <v-btn small text color="success" outlined v-else @click="approve(1)" :loading="approving"
                 :disabled="approving">
            APPROVE
          </v-btn>
        </v-card-actions>

      </v-card>
    </div>
</template>

<script>
export default {
    name: 'PetInfoVerificationCard',
    props: {
    pet: {
      type: Object,
      required: true,
    },
  },
  watch: {
    pet(newValue) {
      // This will trigger whenever the pet prop changes
      //console.log('Pet data updated:', newValue);
    }
  },
    data() {
        return {
            approving: false,
            updating: false,
            editDialog: false,
            updateValue:'',
            updateField: '',
            title:'',

            valid: true,

            health_issues: 'Does the pet have any known health issues? If so, please describe.',
            temperament: 'Describe the pet\'s personality, energy level, and behavior with people and other animals.',
            care_and_placement_plan: 'Do you have a plan for caring for and placing the puppies/kittens?',
            pet_source: 'Where did you get the pet?',
            living_situation: 'Describe the pet\'s current living environment – indoor/outdoor, type of housing, etc.',
            diet_and_exercise: 'What type of food do you feed the pet? How much exercise does it get daily?',
            rehoming_reason: 'Be as detailed as possible about why you are rehoming the pet',
            included_items: 'Included with Sale: (Food, toys, crate, leash, etc.)',
            health_test_results: 'Details of the tests and results.',

            descriptionRules: [
                v => !!v || 'Value should not be empty',
            ],
        }
    },

    methods: {

        approve(val) {
            let pet_xid = this.pet.xid;
            let updateField = 'approve';
            let updateValue = val;
            this.approving = true;

            this.$store.dispatch('updatePetVerificationInfo', {
              updateField,
              updateValue,
                pet_xid,
            }).then(() => {
                this.approving = false;
                this.pet.extra_info_verified = val
            })
                .catch(err => {
                    this.approving = false;
                });
        },

        updatePetInfo() {
            let pet_xid = this.pet.xid;
            let updateField = this.updateField;
            let updateValue = this.updateValue;

            this.updating = true;
            this.$store.dispatch('updatePetVerificationInfo', {
                updateField,
                updateValue,
                pet_xid,
            }).then((resp) => {
                this.updating = false;
                this.editDialog = false;
            })
                .catch(err => {
                    this.updating = false;
                });
        },
    }

}
</script>
