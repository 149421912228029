<template>
    <v-container fluid class="home">

        <div v-if="this.$store.getters.userDetails.name == '' || loading" class="pt-12">

            <v-row no-gutters>
                <v-col cols="12" justify="center" align="center" class="pt-12">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>

            </v-row>

        </div>
        <div v-else class="mb-16">

            <v-tabs v-model="tab" background-color="#f3eeff" grow active-class="active-tab-pill" color="transparent">
                <v-tab @click="getCertificate(0);">Pending</v-tab>
                <v-tab @click="getCertificate(1);">Skipped</v-tab>
            </v-tabs>

        <div class="pa-2">

                    <v-row v-if="!this.certificate.xid">
                        <v-col cols="12" class="pa-12 text-center">
                            <v-icon size="120" color="#eee">mdi-thumb-up-outline</v-icon>
                            <div>Well Done!!</div>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" sm="8">
                            <v-btn color="primary" dark  fab class="float-right" @click="rotateImage">
                                <v-icon>mdi-rotate-left</v-icon>
                            </v-btn>
                            <v-img :src="certificate.cloud_url" cover @click="show = true" id="certificateImage"></v-img>
                            
                            <div class="caption pt-2">
                            <a class="info--text text-caption" :href="certificate.cloud_url" target="_blank"><v-icon small color="blue">mdi-open-in-new</v-icon> Open File</a>
</div>

                        </v-col>
                        <v-col cols="12" sm="4" style="border-left: 1px solid #eee" class="mt-4">
                            <div class="secondary--text text-caption text-right">{{ $moment(certificate.created_at).fromNow(true) }}</div>
            
                            <div class="text-subtitle-1 font-weight-bold black--text">{{ certificate.pet.name }} <v-chip
                                    class="font-weight-regular ml-1 mt-n1" v-show="certificate.pet.is_premium"
                                    color="primary" small style="letter-spacing:1.5px"><v-icon small
                                        left>mdi-crown</v-icon>PREMIUM</v-chip>
                            </div>

                            <div class="text-caption">{{ certificate.pet.breed }}</div>
                            <div class="text-truncate text-caption">{{ certificate.pet.age ? certificate.pet.age.long : ''
                            }} old,
                                {{ certificate.pet.gender == 'M' ? 'Male' : 'Female' }}</div>
                            <div class="text-truncate text-caption">{{ certificate.pet.location }}
                            </div>
                            <div class="text-caption py-2"><b>Owner:</b> {{ certificate.pet.user.name }} ({{ certificate.email }})</div>

                            <div class="text-truncate text-caption py-2 secondary--text">Looking for a {{
                                certificate.pet.purpose }}</div>

                            <div class="text-caption py-2">{{ certificate.pet.description }}</div>

                            <v-row justify="start" align="center" class="pt-4">

                                <v-col cols="4" :key="idx" v-for="(pic, idx) in certificate.pet.photos" align="center">
                                    <v-img :src="pic.photo" cover style="border-radius:5px" aspect-ratio="1">
                                    </v-img>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </div>



        </div>

        <v-dialog v-model="rejectDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1">Why rejecting?</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="rejectDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="rejectForm" v-model="valid" lazy-validation @submit.prevent="reject">
                    <v-card-text class="pt-4">
                        <div class="pb-4">Provide a reason for rejection which will be communicated with the user.</div>
                        <v-textarea dense outlined auto-grow row-height="20" rows="3" placeholder="Reject Reason"
                            v-model="rejectReason" :rules="rejectReasonRules"></v-textarea>


                        <v-chip small class="ma-1" @click="rejectReason = 'Pet details not matching'">Pet details not
                            matching</v-chip>
                        <v-chip small class="ma-1" @click="rejectReason = 'Not a Pedigree Certificate'">Not a Pedigree Certificate</v-chip>
                        <v-chip small class="ma-1" @click="rejectReason = 'Visuals are not clear'">Visuals are not
                            clear</v-chip>
                        <v-chip small class="ma-1" @click="rejectReason = 'Irrelevant photo'">Irrelevant photo</v-chip>



                    </v-card-text>
                    <v-card-actions>
                        <!-- Need this only for old certificates before the UI change -->
                        <v-btn v-show="new Date(certificate.created_at) < new Date('2023-08-07')" small text color="error"
                            outlined :loading="rejecting" :disabled="!valid || rejecting" @click="moveToPhotos">Move to
                            photos</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="rejecting"
                            :disabled="!valid || rejecting">Reject</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>


        <v-dialog v-model="approveDialog" max-width="400">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="approveDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="approveForm" v-model="valid" lazy-validation @submit.prevent="approve">
                    <v-card-text class="pt-4">
                        <div class="pb-2">Registered In</div>
                        <v-text-field outlined dense v-model="reg_in" placeholder="Association/Club"></v-text-field>
                


                        <v-chip small class="ma-1" @click="reg_in = 'American Kennel Club'">American Kennel Club</v-chip>
                        <v-chip small class="ma-1" @click="reg_in = 'American Canine Association, Inc.'">American Canine Association, Inc.</v-chip>
                        <v-chip small class="ma-1" @click="reg_in = 'America\'s Pet Registry, Inc.'">America's Pet Registry, Inc.</v-chip>
                        <v-chip small class="ma-1" @click="reg_in = 'Continental Kennel Club'">Continental Kennel Club</v-chip>
                        <v-chip small class="ma-1" @click="reg_in = 'International Canine Association, Inc.'">International Canine Association, Inc.</v-chip>
                        <v-chip small class="ma-1" @click="reg_in = 'Canadian Canine Registry'">Canadian Canine Registry</v-chip>
                        <v-chip small class="ma-1" @click="reg_in = 'Canadian Kennel Club'">Canadian Kennel Club</v-chip>
                        <v-chip small class="ma-1" @click="reg_in = 'United Kennel Club'">United Kennel Club</v-chip>
                        <v-chip small class="ma-1" @click="reg_in = 'The Royal Kennel Club'">The Royal Kennel Club</v-chip>
                        <v-chip small class="ma-1" @click="reg_in = 'European Kennel Club'">European Kennel Club</v-chip>
                        <v-chip small class="ma-1" @click="reg_in = 'Indian National Kennel Club'">Indian National Kennel Club</v-chip>
                        <v-chip small class="ma-1" @click="reg_in = 'Kennel Club of India'">Kennel Club of India</v-chip>


                        <div class="pt-4 pb-2">Registration #</div>
                        <v-text-field outlined dense v-model="reg_num" placeholder="Registration Number"></v-text-field>

                    </v-card-text>
                    <v-card-actions>
   
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="approving"
                            :disabled="approving">Approve</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="show">
            <v-img :src="certificate.cloud_url" contain @click="show = false"></v-img>
        </v-dialog>

        <v-app-bar bottom fixed class="d-flex justify-space-around" v-if="this.certificate.xid">

            <v-btn color="success" class="mx-2" @click="approveDialog=true">
                APPROVE
            </v-btn>
            <v-btn color="error" class="mx-2" @click="rejectDialog=true">
                REJECT
            </v-btn>
            <v-btn color="warning" class="mx-2" :loading="skipping" :disabled="skipping"  @click="skip()" v-show="!this.certificate.skipped">
                SKIP
            </v-btn>


        </v-app-bar>

        <v-snackbar v-model="snackbar" bottom>
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <!--  <v-btn color="primary" dark fixed bottom right fab @click="getCertificates()">
        <v-icon>mdi-sync</v-icon>
    </v-btn> -->

    </v-container>
</template>

<script>

export default {
    name: 'Certificates',
    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => { });
        } else {
            this.getCertificate(0);
        }

    },
    data() {
        return {
            loading: false,
            snackbar: false,
            snackbar_text: '',
            tab: null,
            show: false,
            certificate: {},
            approving: false,
            skipping: false,
            rejecting: false,
            rejectDialog: false,
            approveDialog: false,
            rejectReason: '',
            reg_in: '',
            reg_num:'',
            valid: true,
            isMounted: false,
            petProfileDialog: false,
            rejectReasonRules: [
                v => !!v || 'Reject reason is required',
            ],
            skipped: 0,
            angle:0,
        }
    },

    methods: {

        getCertificate(skipped = 0) {
            this.loading = true;
            this.skipped = skipped;

            this.$store.dispatch('getCertificate', { skipped: skipped }).then(() => {
                this.loading = false;
                this.certificate = this.$store.getters.certificate;
                this.rejectReason =  ''
                this.reg_in =  ''
                this.reg_num = ''
            })
                .catch(err => {
                    this.loading = false;
                    this.snackbar_text = "Oops, Couldn't get data. Please try again.";
                    this.snackbar = true;
                });
        },

        approve() {
            let verification_id = this.certificate.xid;
            let approve = 1
            let reg_in = this.reg_in
            let reg_num = this.reg_num

            this.approving = true;
            this.$store.dispatch('saveCertificate', {
                approve,
                verification_id,
                reg_in,
                reg_num
            }).then(() => {
                this.approving = false;
                this.approveDialog = false;
                this.getCertificate(this.skipped);
                console.log('approved')
            })
                .catch(err => {
                    this.approving = false;
                });
        },

        skip() {
            let verification_id = this.certificate.xid;
            this.skipping = true;
            this.$store.dispatch('skipCertificate', {
                verification_id,
            }).then(() => {
                this.getCertificate(this.skipped);
                this.skipping = false;
            })
                .catch(err => {
                    this.skipping = false;
                });
        },


        reject() {
            if (this.$refs.rejectForm.validate()) {
                this.rejecting = true;
                let verification_id = this.certificate.xid;
                let reject_reason = this.rejectReason

                this.$store.dispatch('rejectCertificate', {
                    verification_id,
                    reject_reason
                }).then(() => {
                    this.rejecting = false;
                    this.rejectDialog = false;
                    this.getCertificate(this.skipped);
                })
                    .catch(err => {
                        this.rejecting = false;
                    });
            }
        },
        showPetDetails() {
            this.petProfileDialog = true;
        },
        moveToPhotos() {
            this.rejecting = true;
            let verification_id = this.certificate.xid;

            this.$store.dispatch('moveCertificate', {
                verification_id
            }).then(() => {
                this.rejecting = false;
                this.rejectDialog = false;
                this.getCertificate(this.skipped);
            })
                .catch(err => {
                    this.rejecting = false;
                });
        },

        rotateImage() {
            var div = document.querySelector('#certificateImage');
            this.angle = (this.angle - 90) % 360; // Increment the angle by 90 degrees
            div.style.transform = 'rotate(' + this.angle + 'deg)';
        }
    }

}
</script>
