<template>
<div>
    <v-card class="mild-shadow rounded-lg">

        <v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle class="text--primary text-caption"><v-btn icon small color="primary" @click="getDiscountOffer(message.to_email)"><v-icon small>mdi-wallet-giftcard</v-icon></v-btn><b>To:</b> {{message.to_email}} </v-list-item-subtitle>
                <v-list-item-subtitle class="text-caption"><v-btn icon small color="primary" @click="getDiscountOffer(message.from_email)"><v-icon small>mdi-wallet-giftcard</v-icon></v-btn><b>From:</b> {{message.from_email}} </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-list-item-action-text class="secondary--text">{{ $moment(message.created_at).fromNow(true) }}</v-list-item-action-text>
            </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-alert v-if="message.forbidden_text"
        text
        dense
        type="error"
        class="text-caption mb-0"
        >
        Forbidden text found: <strong>{{ message.forbidden_text }}</strong>
        </v-alert>

        <v-card-text class="text-pre-wrap">
            {{message.message}}
        </v-card-text>

        <v-card-actions>

  
            <v-btn icon  text color="primary"  @click="getConversation()">
                <v-icon>mdi-chat-processing-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn small text color="primary" outlined @click="messageDialog=true" :disabled="message.verified == 1">
                EDIT
            </v-btn>
            <v-btn small color="success" depressed v-if="message.verified" @click="approve(0)" :loading="approving" :disabled="approving || message.verified == 1">
                APPROVED
            </v-btn>
            <v-btn small text color="success" outlined v-else @click="approve(1)" :loading="approving" :disabled="approving">
                APPROVE
            </v-btn>

        </v-card-actions>

         

    </v-card>

    <v-dialog v-model="messageDialog" max-width="600">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Message</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="messageDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-form ref="messageForm" v-model="valid" lazy-validation @submit.prevent="updateMessage">
                <v-card-text class="pt-4">
                    <v-textarea dense outlined auto-grow row-height="30" rows="5" placeholder="Message" v-model="message.message" :rules="messageRules" persistent-hint hint="Mask the contact details with ##***Contact Details***##"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit" :loading="updating" :disabled="!valid || updating">Save</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <v-dialog v-model="conversationDialog" max-width="500">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Conversation</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="conversationDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            
                <v-card-text class="pt-4">

                    <v-progress-linear v-if="loading"
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                        class="my-4"
                    ></v-progress-linear>


                     <v-row v-for="(item,index) in conversation" :key="index" :justify="item.fuid == message.fuid?'end':'start'">
                    <v-col cols="10">
                    <v-sheet :color="item.fuid == message.fuid ? 'primary' : '#edf0f5'" :dark="item.fuid == message.fuid" :class="item.fuid == message.fuid ? 'rounded-br-0 rounded-lg pa-2' : 'rounded-tl-0 rounded-lg pa-2'">
                            <span class="text-pre-wrap text-caption">{{item.message}}</span>
                            <div class="text-caption text-right">{{item.created_at||new Date() | moment("D MMM, h:mm a")}}</div>
                    </v-sheet>
                    </v-col>
                     </v-row>
                </v-card-text>
               
            
        </v-card>
    </v-dialog>

    <v-dialog v-model="discountDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Offers</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="discountDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            
                <v-card-text class="pt-4">

                    <v-btn block small color="primary" :loading="offering && offerType == 'DiscountOffer'" :disabled="offering && offerType == 'DiscountOffer'" @click="offerDiscount('DiscountOffer')">Offer Discount Coupon</v-btn>
       
                    <v-btn block small class="mt-4" color="primary" :loading="offering && offerType == 'DirectConnectForReview'" :disabled="offering && offerType == 'DirectConnectForReview'" @click="offerDiscount('DirectConnectForReview')">Offer Direct Connect For Review</v-btn>

                   <v-progress-linear v-if="getting"
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                        class="my-4"
                    ></v-progress-linear>

                    <div class="mt-4">
                        <div v-for="(item,index) in this.discountLog" :key="index" class="text-caption pb-1">
                            <v-icon small color="primary">mdi-email</v-icon> {{item.offer}} - {{item.created_at | moment("from", "now")}}
                        </div>
                    </div>



                </v-card-text>
                <!-- <v-card-actions>
                    <v-btn color="grey" text @click="discountDialog=false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary"  :loading="offering" :disabled="offering" @click="offerDiscount">Yes</v-btn>
                </v-card-actions> -->
            
        </v-card>
    </v-dialog>

</div>
</template>

<script>
export default {
    name: 'MessageCard',
    props: ['message'],
    data() {
        return {
            approving: false,
            updating: false,
            messageDialog: false,
            discountDialog: false,
            offering: false,
            conversationDialog: false,
            offerType: '',
            discountLog: [],
            getting: false,
            email: '',
            conversation: [],
            loading: false,

            ex11: false,

            valid: true,

            messageRules: [
                v => !!v || 'Message is required',
            ],
        }
    },

    methods: {

        approve(val) {
            let msg_id = this.message.xid;
            let message = this.message.message
            let approve = val

            this.approving = true;
            this.$store.dispatch('saveMessage', {
                    message,
                    approve,
                    msg_id,
                }).then(() => {
                    this.approving = false;
                    this.message.verified = val
                })
                .catch(err => {
                    this.approving = false;
                });
        },

        updateMessage() {
            let msg_id = this.message.xid;
            let message = this.message.message
            let approve = this.message.verified

            this.updating = true;
            this.$store.dispatch('saveMessage', {
                    message,
                    approve,
                    msg_id,
                }).then(() => {
                    this.updating = false;
                    this.messageDialog = false;
                })
                .catch(err => {
                    this.updating = false;
                });

        },

        getDiscountOffer(email)
        {
            this.getting =true;
            this.discountDialog = true;
            this.email = email
            this.discountLog = [];

            this.$store.dispatch('getDiscountOffer',email).then((resp) => {
                    if(resp)
                    {
                        this.discountLog = resp;
                    }
                    this.getting =false;
                })
                .catch(err => {
                    this.getting =false;
                });

        },

        offerDiscount(type)
        {
            let email = this.email
            this.offering = true;
            this.offerType = type;
            this.$store.dispatch('sendDiscountOffer', {
                    email, type
                }).then((resp) => {
                    this.offering = false;
                    this.discountLog = resp;
                })
                .catch(err => {
                    this.offering = false;
                });

        },

        getConversation()
        {
            this.conversation = [];
            this.conversationDialog = true;
            this.loading = true;
            this.$store.dispatch('getConversation',this.message.cxid).then((resp) => {
                    this.conversation = resp;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading =false;
                });

        }



    }

}
</script>
