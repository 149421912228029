<template>
<v-app>
    <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->
    <!-- <v-row no-gutters justify="center">
        <v-col cols="12" sm="8" md="6"> -->
    <top-bar v-if="!noHeader.includes($route.name)" />
    <v-main>
        <router-view />
    </v-main>
    <!--  </v-col>
    </v-row> -->


    <v-overlay :value="dataLoading" color="white" opacity="1">

        <v-row align="center" justify="center">

            <v-col cols="12">
                <v-img src='@/assets/logo/icon-196.png' max-width="196" class="mx-auto"></v-img>
            </v-col>

            <v-col cols="12">
                <v-img src='@/assets/logo/petmeetly-text-color.png' max-width="200" class="mx-auto"></v-img>
            </v-col>

            <v-col cols="12" class="pa-16">
                <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>

    </v-overlay>

    <v-dialog v-model="promptUpdate" persistent max-width="450" :retain-focus="false">

    <v-card>

        <v-card-text class="text-center pt-8">
            <div>
            <v-img src='@/assets/logo/icon-196.png' max-width="90" class="mx-auto"></v-img>
            </div>
            <div class="text-h5 py-4">Update Petmeetly</div>
            <div class="pb-8">
                A new version is found. <br/>we recommend that you update to the latest version.
            </div>
            <div>
                <v-btn color="primary" @click="updateApp">Update</v-btn>
            </div>
        </v-card-text>
        

    </v-card>

</v-dialog>

</v-app>
</template>

<script>
import TopBar from './components/TopBar.vue'

export default {
    name: 'App',

    created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.promptUpdate = true;
      });
    }
  },

    mounted() {

        if (this.$store.getters.isLoggedIn) {
            this.dataLoading = true;
            this.$store.dispatch('profile').then(() => {
                this.dataLoading = false;
            });
            this.$store.dispatch('pending').then(() => {
                this.dataLoading = false;
            });
        } else {
            this.$router.replace('login');
        }

    },

    components: {
        TopBar,
    },

    data: () => ({
        noHeader: [],
        dataLoading: false,
        promptUpdate: false,
    }),

    methods: {
        async updateApp() {
            this.promptUpdate = false;
            await this.$workbox.messageSW({ type: "SKIP_WAITING" });
        },
    },

};
</script>
