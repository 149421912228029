<template>
    <div>
        <v-card class="mild-shadow rounded-lg">

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="text--primary text-caption"><b>User:</b>
                        {{ questionnaire.email }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-list-item-action-text class="secondary--text">{{ $moment(questionnaire.updated_at).fromNow(true) }}</v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>

         
            
            <v-card-text>
                <div class="text-caption">Where do you live?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.residence_type }}</div>

                <div class="text-caption pt-2">Do you own or rent your home?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.owns_home == 1 ? "Own" : "Rented" }}</div>

                <div class="text-caption pt-2">Do you have permission from your landlord to have a pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.has_landlord_permission == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2">Have you ever owned a pet before?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.has_previous_pet_experience == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2">What type of pet(s) did you owned and for how long?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.previous_pet_details}}</div>

                <div class="text-caption pt-2">How active is your lifestyle? Do you enjoy outdoor activities?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.activity_level}}</div>

                <div class="text-caption pt-2">How much time are you willing to dedicate to caring for a pet each day?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.time_commitment}}</div>

                <div class="text-caption pt-2">What are you looking for in a pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.expectations}}</div>

                <div class="text-caption pt-2">Are you willing to invest time and resources in training your pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.willing_to_train  == 1 ? "Yes" : "No"}}</div>

                <div class="text-caption pt-2">How much are you willing to spend on pet care expenses like food, vet visits, grooming, etc.?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.pet_care_budget}}</div>

                <div class="text-caption pt-2">Do you have a fenced yard?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.has_fenced_yard == 1 ? "Yes" : "No"}}</div>

                <div class="text-caption pt-2">How do you plan to exercise your pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.exercise_plan}}</div>

                <div class="text-caption pt-2">Do you have other pets in the household?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.has_other_pets == 1 ? "Yes" : "No"}}</div>

                <div class="text-caption pt-2">Please describe them (Species/Breed/Age/Temperament)</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.other_pet_details}}</div>

                <div class="text-caption pt-2">Do you have any allergies?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.has_allergies  == 1 ? "Yes" : "No"}}</div>

                <div class="text-caption pt-2">Please specify</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.allergies}}</div>

                <div class="text-caption pt-2">Who will be the primary caregiver for the pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.primary_caregiver}}</div>

                <div class="text-caption pt-2">What are your plans if you go on vacation or are unable to care for the pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.vacation_care_plan}}</div>

                <div class="text-caption pt-2">Is there anything else you would like to share about yourself or your interest in getting a pet?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.additional_information}}</div>

                <div class="text-caption pt-2">How did you hear about Petmeetly?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.referral_source}}</div>
                
            </v-card-text>
      

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small text color="error" outlined @click="rejectDialog=true" :disabled="disabled">
                    REJECT
                </v-btn>

                <v-btn small color="success" depressed v-if="questionnaire.verified" @click="approve(0)"
                    :loading="approving" :disabled="approving || disabled">
                    APPROVED
                </v-btn>
                <v-btn small text color="success" outlined v-else @click="approve(1)" :loading="approving"
                    :disabled="approving || disabled">
                    APPROVE
                </v-btn>
                
            </v-card-actions>

        </v-card>

        <v-dialog v-model="rejectDialog" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1 font-weight-bold">Reason</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="rejectDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="rejectForm" v-model="valid" lazy-validation @submit.prevent="approve(0)">
                    <v-card-text class="pt-4">
                        <div class="pb-4">Provide a reason for rejection which will be communicated with the user.</div>
                        <v-textarea dense outlined auto-grow row-height="30" rows="5" placeholder="Reason"
                            v-model="reason" :rules="rejectRules"></v-textarea>

                            <v-chip small class="ma-1" @click="reason='Provided details does not look genuine'">Details Not Genuine</v-chip>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="approving" :disabled="!valid || approving">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    </div>

</template>

<script>

export default {
    name: 'adopterQuestionnaires',
    props: ['questionnaire'],
    data() {
        return {
            reason: '',
            approving: false,
            updating: false,
            rejectDialog: false,
            snackbar: false,
            snackbar_text: '',
            disabled: false,
            valid: true,

            rejectRules: [
                v => !!v || 'Reason is required',
            ],
        }
    },

    methods: {

        approve(val) {

            if(val == 0 && this.reason == '')
            {
                this.snackbar_text = "Reason is required";
                this.snackbar = true;
            }
            else
            {
                let id = this.questionnaire.xid;
                let approve = val
                let reason = this.reason;
                this.approving = true;
                this.$store.dispatch('saveAdopterQuestionnaire', {
                    approve,
                    reason,
                    id,
                }).then(() => {
                    this.approving = false;
                    this.rejectDialog = false
                    this.questionnaire.verified = val

                    if(val == 0)
                    {
                        this.disabled = true;
                    }
                })
                    .catch(err => {
                        this.approving = false;
                    });
            }
            
        },

       
    }

}
</script>
