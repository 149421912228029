<template>
<div>
    <v-card class="mild-shadow rounded-lg">

        <v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle class="text--primary text-caption pb-1">
                    <v-icon  v-if="pet.is_premium" color="primary">mdi-crown</v-icon>
                    <b> &nbsp;</b> {{pet.email}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-list-item-action-text class="secondary--text">{{ $moment(pet.updated_at).fromNow(true) }}</v-list-item-action-text>
            </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-card-text class="text-pre-wrap">
            {{pet.description}}
        </v-card-text>

        <v-card-actions>

            <v-btn icon @click="likeDescription" :loading="favLoading">
                    <v-icon color="red" v-if="pet.description_liked">
                        mdi-heart
                    </v-icon>
                    <v-icon v-else color="grey lighten-1">
                        mdi-heart-outline
                    </v-icon>
            </v-btn>
            <!-- <v-icon  v-if="pet.is_premium" color="primary">mdi-crown</v-icon> -->
            
            <v-spacer></v-spacer>
            <v-btn small text color="primary" outlined @click="detailsDialog=true">
                VIEW
            </v-btn>
            

        </v-card-actions>

    </v-card>


    <v-dialog v-model="detailsDialog" max-width="600">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Profile</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="detailsDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-4">

                <p>
                    🥁 Meet {{pet.name}},
                    <br /><br />
                    Breed:<b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{pet.breed}}</b> (Use hashtag)
                    <br />
                    Age:<b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{pet.age.long}}</b>
                    <br />
                    Gender:<b> &nbsp;{{pet.gender == 'M' ? 'Male' : 'Female'}}</b>
                    <br />
                    Weight:<b> &nbsp;&nbsp;{{pet.weight}} {{pet.weight_unit}}</b>
                    <br />
                    <br />
                    {{pet.gender == 'M' ? 'He' : 'She'}} is looking for {{pet.purpose == 'lover' ? 'a Lover 💕' : (pet.purpose == 'adopter' ? 'an Adopter 😇' : (pet.purpose == 'buyer' ? 'a Buyer 🥰' : 'Friends 💛'))}}
                    <br />
                    <br />
                    Twitter>>>Get in touch with {{pet.gender == 'M' ? 'him' : 'her'}} 👉 https://app.petmeetly.com/pet/{{pet.xid}}
                    <br />
                    Facebook>>>Get in touch with {{pet.gender == 'M' ? 'him' : 'her'}} on @petmeetly
                    <br />
                    Instagram>>>Get in touch with {{pet.gender == 'M' ? 'him' : 'her'}} 👉 Click the link in our bio @petmeetly
                    <br />
                    <br />
                    <span class="text-pre-wrap">{{pet.description}}</span>
                    <br />
                    <br />
                    {{pet.purpose == 'lover' ? '#petmeetly #dogstagram #dogsofinstagram #dogoftheday #dogbreeding #dogbreeder #kennels #breeder #purebreddog #breeding #breedingdogs #caninebreeding #ethicalbreeding #selectivebreeding #dogfeatures' : (pet.purpose == 'adopter' ? '#petmeetly #dogstagram #dogsofinstagram #dogoftheday #adoptdontshop #adopt #adoptme #adoptable #adoptdog #rescuedogs #savedogs #offertoadopt' : (pet.purpose == 'buyer' ? '#petmeetly #dogstagram #dogsofinstagram #dogoftheday #doggo #dogfeatures #dogoftheday #dogforsale #puppyforsale #saledog #puppiesforsale #forsale #lovefordogs #dogisgood' : '#petmeetly #dogstagram #dogsofinstagram #dogoftheday #dogfriend #dogbuddy #dogdad #puppyfriends #dogfamily #doglife #dogfriendship #dogsiblings #dogfun #happypuppy #dogplaying #doggroup #doggathering #puppybrothers #puppyfun #bestfriend'))}}

                    <br />
                    <br />
                    <v-divider></v-divider>
                    <span class="text-caption">Generate suitable hashtags for the breed from <a href="https://predis.ai/free-hashtag-generator/" target="_blank">Here</a></span>
                    <br />
                    <span class="text-caption">Add location tags and relevant hashtags for <span class="primary--text">{{this.pet.locality+', '+this.pet.city+', '+this.pet.state}}</span></span>
                    <br />
                    <span class="text-caption">Mention the pet: <span class="primary--text">@{{pet.insta_handle}}</span></span>

                </p>

                <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">photos</div>

                <v-row justify="start" align="center" class="pt-4">

                    <v-col cols="4" :key="idx" v-for="(pic, idx) in pet.photos" align="center">
                        <v-img :src="pic.photo" cover style="border-radius:5px" aspect-ratio="1">

                            <v-btn fab text small dark absolute bottom right class="mb-8" style="background-color:rgba(0,0,0,0.20)" :href="pic.photo" target="_blank" download>
                                <v-icon>
                                    mdi-download
                                </v-icon>
                            </v-btn>

                        </v-img>

                    </v-col>

                </v-row>

            </v-card-text>

        </v-card>
    </v-dialog>

</div>
</template>

<script>
export default {
    name: 'FavoriteCard',
    props: ['pet'],
    data() {
        return {
            approving: false,
            updating: false,
            detailsDialog: false,
            favLoading: false,

            valid: true,

        }
    },

    methods: {


        likeDescription() {
            let pet_id = this.pet.xid;

            this.favLoading = true;
            this.$store.dispatch('likeDescription', {
                    pet_id,
                }).then(() => {
                    this.favLoading = false;
                    this.pet.description_liked = !this.pet.description_liked
                })
                .catch(err => {
                    this.favLoading = false;
                });

        },

    }

}
</script>
