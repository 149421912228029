<template>
<div>
    <v-card class="mild-shadow rounded-lg">

        <v-list-item class="py-2">

            <v-list-item-avatar size="48">
        
                        <v-img v-if="user.photo" :src="user.photo"></v-img>
                        <v-icon color="#7E0DE7" v-else size="24">$vuetify.icons.account-icon</v-icon>
            
          </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title class="primary--text">{{user.name}}</v-list-item-title>
                <v-list-item-subtitle class="text--primary text-caption">{{user.email}}</v-list-item-subtitle>
            </v-list-item-content>

        </v-list-item>

       <!-- <v-card-text class="py-0">
        <span class="text-caption font-weight-medium">A/c Closed: </span><span class="secondary--text text-caption">{{user.feedback.created_at | moment("MMM D YYYY")}} ({{ user.feedback.created_at | moment("from", "now") }})</span>
        <div>
            <span class="text-caption font-weight-medium">Recommend: </span><span class="secondary--text text-caption"> {{user.feedback.will_recommend}} </span>
        </div>
       </v-card-text> -->



        <!-- <v-card-text>
            <div class="text-caption font-weight-medium black--text">Followups:</div>
            <p class="text-caption text-pre-wrap">{{user.feedback.followups}}</p>

            <span class="text-caption">Updated &nbsp;</span><span class="secondary--text text-caption">{{ user.feedback.updated_at | moment("from", "now") }}</span>
       
        </v-card-text> -->


        <v-card-actions>

            <!-- <div><v-chip class="ma-1" :color="user.feedback.status == 'Responded' ? 'success' : ''" small v-if="user.feedback.status">
                {{user.feedback.status}}
            </v-chip></div> -->

            

            <v-spacer></v-spacer>

            <v-btn small color="primary" @click="detailsDialog = true">
                Details
            </v-btn>



        </v-card-actions>

    </v-card>

    <v-dialog v-model="detailsDialog" max-width="600">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="detailsDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>

               <v-list-item class="py-2">

            <v-list-item-avatar size="48">
        
                        <v-img v-if="user.photo" :src="user.photo"></v-img>
                        <v-icon color="#7E0DE7" v-else size="24">$vuetify.icons.account-icon</v-icon>
            
          </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="primary--text">{{user.name}}</v-list-item-title>
                <v-list-item-subtitle class="text--primary text-caption">{{user.email}}</v-list-item-subtitle>
            </v-list-item-content>

        </v-list-item>

            <!-- <div class="py-0">
        <span class="text-caption font-weight-medium">A/c Closed: </span><span class="secondary--text text-caption">{{user.feedback.created_at | moment("MMM D YYYY")}} ({{ user.feedback.created_at ? user.feedback.created_at : user.feedback.created_at | moment("from", "now") }})</span>
       </div> -->

       <v-divider class="mt-3 mb-1"></v-divider>

            <div v-if="user.pets">

                <div class="pa-1 primary--text text-overline font-weight-medium">Pets</div>

                <v-list-item v-for="(pet,index) in user.pets" :key="index" >
          
          <v-list-item-avatar>
            <v-img :src="pet.avatar ? pet.avatar.photo : require('@/assets/logo/logo-bg2.jpg')" :lazy-src="require('@/assets/img/lazy-bg.jpg')"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2" v-html="pet.name + ' (' + pet.breed + ')'"></v-list-item-title>
            <v-list-item-subtitle class="text-caption" v-html="'Looking for a '+pet.purpose"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

            </div>

            <div v-if="user.preferences" class="pb-4">

        <div class="pa-1 primary--text text-overline font-weight-medium">Preferences</div>

        <div>
                        <v-chip small class="ma-1" style="height: 20px"> {{user.preferences.pet_type.type}} </v-chip>
                        <v-chip small class="ma-1" style="height: 20px"> {{user.preferences.age == 'B' ? 'Baby ' : (user.preferences.age == 'Y' ? 'Young ' : (user.preferences.age == 'A' ? 'Adult ' : ( user.preferences.age == 'S' ? 'Senior ' : 'Any age')))}} </v-chip>
                        <v-chip small class="ma-1" style="height: 20px"> {{user.preferences.gender == 'M' ? 'Male ' : (user.preferences.gender == 'F' ? 'Female ' : 'Any gender')}} </v-chip>
                        <v-chip small class="ma-1" style="height: 20px"> {{user.preferences.pet_breed ? user.preferences.pet_breed.breed+' ':'Any breed'}} </v-chip>
                        <v-chip small class="ma-1" style="height: 20px"> {{user.preferences.available_for ? 'For '+ user.preferences.available_for: 'For sale/adoption'}} </v-chip>
                        <v-chip small class="ma-1" style="height: 20px"> {{'Within ' + user.preferences.pref_distance + ' Miles'}} </v-chip>
                    </div>

            </div>

            <div class="pb-4">
            <div class="pa-1 primary--text text-overline font-weight-medium">Messages</div>
            <div class="pa-2">
                <div class="text-caption font-weight-medium">Messages Sent: {{user.total_messages_sent}}</div>
                <div class="text-caption font-weight-medium">Messages Received: {{user.total_messages_received}}</div>
                <div class="text-caption font-weight-medium">2-Way Conversations: {{user.total_twoway}}</div>
            </div>
        </div>

          <!--   <v-divider class="mb-2"></v-divider>

            <div class="pa-1 primary--text text-body-2 font-weight-medium">Follow-up</div>

            <v-textarea style="font-size:14px !important" dense outlined auto-grow row-height="30" rows="3" placeholder="Followups" v-model="user.feedback.followups" ></v-textarea>

            <div class="pa-1 primary--text text-body-2 font-weight-medium">Status</div>
            <v-select style="font-size:14px !important" :items="items" item-text="label" item-value="value" v-model="user.feedback.status" mandatory solo outlined flat dense></v-select>

             -->

            </v-card-text>
<!-- 
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.prevent="update" :loading="updating" :disabled="updating">UPDATE</v-btn>
            </v-card-actions> -->

        </v-card>
    </v-dialog>

</div>
</template>

<script>
export default {
    name: 'FeedbackCard',
    props: ['user'],
    data() {
        return {
            updating: false,
            detailsDialog: false,
            notifyDialog: false,

            items: [{
                    label: 'Following up',
                    value: 'Following up'
                },
                {
                    label: 'Responded',
                    value: 'Responded'
                },
                {
                    label: 'Received',
                    value: 'Received'
                },
                {
                    label: 'Stopped',
                    value: 'Stopped'
                }
            ],

        }
    },



    methods: {

        update()
        {
            let feedback_id = this.user.feedback.xid;
            let followups = this.user.feedback.followups;
            let status = this.user.feedback.status;

            this.updating = true;
            this.$store.dispatch('updateFeedback', {
                    feedback_id,
                    followups,
                    status
                }).then(() => {
                    this.updating = false;
                    this.detailsDialog = false;
                })
                .catch(err => {
                    this.updating = false;
                });

        }

        /* notify() {
            let pet_id = this.pet.xid;
            let insta_published_url = this.pet.insta_published_url;

            this.notifying = true;
            this.$store.dispatch('notifyPremium', {
                    pet_id,
                    insta_published_url,
                }).then(() => {
                    this.notifying = false;
                    this.notifyDialog = false;
                    this.pet.insta_published = true;
                })
                .catch(err => {
                    this.notifying = false;
                });
        } */

    }

}
</script>
