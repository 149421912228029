<template>
    <div>
        <v-card class="mild-shadow rounded-lg">

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="text--primary text-caption"><b>User:</b>
                        {{ questionnaire.email }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-list-item-action-text class="secondary--text">{{ $moment(questionnaire.updated_at).fromNow(true) }}</v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>

         
            
            <v-card-text>
                <div class="text-caption">Organization Name</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.organization_name }}</div>

                <div class="text-caption pt-2">Official Phone Number</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.phone_number }}</div>

                <div class="text-caption pt-2">Official Email Address</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.email }}</div>

                <div class="text-caption pt-2">Website</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.website }}</div>

                <div class="text-caption pt-2">Social Media Links</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.social_media_links }}</div>

                <div class="text-caption pt-2">Type of Organization</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.organization_type }}</div>

                <div class="text-caption pt-2">Legal Status</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.legal_status }}</div>

                <div class="text-caption pt-2">Mission Statement</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.mission_statement }}</div>

                <div class="text-caption pt-2">Years of Operation</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.years_of_operation }}</div>

                <div class="text-caption pt-2">Sources of Animals</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.animal_sources }}</div>

                <div class="text-caption pt-2">Describe how you evaluate and intake animals</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.intake_process }}</div>

                <div class="text-caption pt-2">Type of housing</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.housing_type }}</div>

                <div class="text-caption pt-2">Cleaning and sanitation protocols</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.cleaning_protocols }}</div>

                <div class="text-caption pt-2">Enrichment activities for animals</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.enrichment_activities }}</div>

                <div class="text-caption pt-2">Veterinary care provided</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.veterinary_care }}</div>

                <div class="text-caption pt-2">How do you assess the temperament and behavior of animals?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.behavioral_assessment }}</div>

                <div class="text-caption pt-2">Do you accept and care for animals with special needs?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.accepts_special_needs == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2">Describe your adoption application process.</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.adoption_application_process }}</div>

                <div class="text-caption pt-2">What criteria do you use to evaluate potential adopters?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.screening_criteria }}</div>

                <div class="text-caption pt-2">Do you conduct home visits prior to adoption?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.conducts_home_visits == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2">What kind of support do you offer to adopters after adoption?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.post_adoption_support}}</div>

                <div class="text-caption pt-2">Euthanasia Policy</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.euthanasia_policy }}</div>

                <div class="text-caption pt-2">Spay/Neuter Policy: (Do you require adopters to spay/neuter their pets?)</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.requires_spay_neuter == 1 ? "Yes" : "No" }}</div>

                <div class="text-caption pt-2">Return Policy</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.return_policy }}</div>

                <div class="text-caption pt-2">Do you have a volunteer program?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.has_volunteer_program == 1 ? "Yes" : "No" }}</div>
                <div class="text-caption pt-2">If so, how can people get involved in volunteer program?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.volunteer_info }}</div>

                <div class="text-caption pt-2">Do you have a foster program?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.has_foster_program == 1 ? "Yes" : "No" }}</div>
                <div class="text-caption pt-2">What are the requirements for becoming a foster parent?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.foster_requirements }}</div>

                <div class="text-caption pt-2">Do you collaborate with other animal welfare organizations?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.partner_organizations}}</div>

                <div class="text-caption pt-2">How do you engage with the community to promote adoption and responsible pet ownership?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.community_outreach}}</div>

                <div class="text-caption pt-2">How do you fund your operations?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.fundraising_efforts}}</div>

                <div class="text-caption pt-2">Number of animals currently in your care</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.animals_in_care}}</div>

                <div class="text-caption pt-2">Types of animals you typically have available for adoption:</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.available_animal_types }}</div>

                <div class="text-caption pt-2">Is there any additional information you would like to share about your organization?</div>
                <div class="text-caption black--text font-weight-bold">{{ questionnaire.additional_information }}</div>

            </v-card-text>
      

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small text color="error" outlined @click="rejectDialog=true" :disabled="disabled">
                    REJECT
                </v-btn>

                <v-btn small color="success" depressed v-if="questionnaire.verified" @click="approve(0)"
                    :loading="approving" :disabled="approving || disabled">
                    APPROVED
                </v-btn>
                <v-btn small text color="success" outlined v-else @click="approve(1)" :loading="approving"
                    :disabled="approving || disabled">
                    APPROVE
                </v-btn>
                
            </v-card-actions>

        </v-card>

        <v-dialog v-model="rejectDialog" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1 font-weight-bold">Reason</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="rejectDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="rejectForm" v-model="valid" lazy-validation @submit.prevent="approve(0)">
                    <v-card-text class="pt-4">
                        <div class="pb-4">Provide a reason for rejection which will be communicated with the user.</div>
                        <v-textarea dense outlined auto-grow row-height="30" rows="5" placeholder="Reason"
                            v-model="reason" :rules="rejectRules"></v-textarea>

                            <v-chip small class="ma-1" @click="reason='Provided details does not look genuine'">Details Not Genuine</v-chip>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="approving" :disabled="!valid || approving">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    </div>

</template>

<script>

export default {
    name: 'shelterQuestionnaires',
    props: ['questionnaire'],
    data() {
        return {
            reason: '',
            approving: false,
            updating: false,
            rejectDialog: false,
            snackbar: false,
            snackbar_text: '',
            disabled: false,
            valid: true,

            rejectRules: [
                v => !!v || 'Reason is required',
            ],
        }
    },

    methods: {

        approve(val) {

            if(val == 0 && this.reason == '')
            {
                this.snackbar_text = "Reason is required";
                this.snackbar = true;
            }
            else
            {
                let id = this.questionnaire.xid;
                let approve = val
                let reason = this.reason;
                this.approving = true;
                this.$store.dispatch('saveShelterQuestionnaire', {
                    approve,
                    reason,
                    id,
                }).then(() => {
                    this.approving = false;
                    this.rejectDialog = false
                    this.questionnaire.verified = val

                    if(val == 0)
                    {
                        this.disabled = true;
                    }
                })
                    .catch(err => {
                        this.approving = false;
                    });
            }
            
        },

       
    }

}
</script>
