<template>
<v-container fluid class="home">

    <div v-if="this.$store.getters.userDetails.name == ''" class="pt-12">

        <v-row no-gutters>
            <v-col cols="12" justify="center" align="center" class="pt-12">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>

        </v-row>

    </div>
    <div v-else class="mb-16">

       <div class="my-2 mb-4">
            <v-row no-gutters>
                <v-col cols="12">
                    <h4 class="text-h6"></h4>
                </v-col>

            </v-row>

        </div>

        <v-row>
            <v-col cols="12" sm="6" v-for="(item,index) in this.$store.getters.scamReportings" :key="index" class="pa-2">
                <scam-reporting-card :item="item"></scam-reporting-card>
            </v-col>
        </v-row>
    </div>

    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    <v-btn color="primary" dark fixed bottom right fab @click="getScamReportings" :loading="loading">
        <v-icon>mdi-chevron-double-down</v-icon>
    </v-btn>

</v-container>
</template>

<script>
import ScamReportingCard from './components/ScamReportingCard.vue';
export default {
    name: 'ScamReporting',
    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => {});
        } else {
            this.getScamReportings();
        }

    },
    data() {
        return {
            loading: false,
            snackbar: false,
            snackbar_text: '',
            page: 0,
        }
    },
    components: {
        ScamReportingCard,
    },
    methods: {

        getScamReportings() {
            this.loading = true;
            let page = this.page+1
            this.$store.dispatch('getScamReportings', page).then(() => {
                    this.loading = false;
                    this.page = page;
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false;
                    this.snackbar_text = "Oops, Couldn't get data. Please try again.";
                    this.snackbar = true;
                });
        }
    }

}
</script>
