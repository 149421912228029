import Vue from 'vue'
import VueCookie from 'vue-cookie'
import axios from 'axios'
import Bugsnag from '@bugsnag/js'

Vue.use(VueCookie)


export default {
    state: {
        token: Vue.cookie.get("pma_token") || "",
        user: {
            name: "",
        },
        
    },

    getters: {
        isLoggedIn: state => !!state.token,
        userDetails: state => state.user,
        
    },

    mutations: {
        login(state, token) {
            state.token = token;
        },

        logout(state) {
            state.token = "";
            state.user = {
                name: '',
            };

        },

        profile(state, user) {
            state.user = user;
        },


    },
    actions: {

        login({ commit/* , dispatch */ }, credentials) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/login", credentials)
                    .then(resp => {
                        const token = resp.data.token;
                        Vue.cookie.set("pma_token", resp.data.token, 30);
                        axios.defaults.headers.common["Authorization"] =
                            "Bearer " + token;
                        commit("login", token);
                        /* dispatch('profile').then((resp) =>
                        {
                            resolve(resp);
                        }) */
                        resolve(resp.data);

                    })
                    .catch(err => {
                        Vue.cookie.delete("pma_token");
                        reject(err);
                    });
            });
        },



        logout({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/logout")
                    .then(resp => {
                        Vue.cookie.delete("pma_token");
                        delete axios.defaults.headers.common["Authorization"];
                        commit("logout");
                        commit('resetAppState', null, { root: true });
                        resolve(resp);
                    })
                    .catch(err => {
                        Vue.cookie.delete("pma_token");
                        delete axios.defaults.headers.common["Authorization"];
                        //Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },


        profile({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/profile")
                    .then(resp => {
                        const user = resp.data;
                        commit("profile", user);
                        resolve(user);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        
    }
};

