<template>
<div>
    <v-card class="mild-shadow rounded-lg">

        <v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle class="text--primary text-caption"><b>User:</b> {{photo.email}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-list-item-action-text class="secondary--text">{{ $moment(photo.created_at).fromNow(true) }}</v-list-item-action-text>
            </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-img :src="photo.photo" cover max-height="300" @click="show=true"></v-img>

        <v-card-actions>

            <v-btn icon text color="primary" @click="showPetDetails()">
                <v-icon>mdi-information-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn small text color="error" outlined @click="rejectDialog=true">
                REJECT
            </v-btn>
            
            <v-btn small color="success" depressed v-if="photo.verified" @click="approve(0)" :loading="approving" :disabled="approving" class="photo_approve_btn">
                APPROVED
            </v-btn>
            <v-btn small text color="success" outlined v-else  @click="approve(1)" :loading="approving" :disabled="approving" class="photo_approve_btn">
                APPROVE
            </v-btn>

        </v-card-actions>

    </v-card>
    <pet-profile-info v-if="isMounted" v-model="petProfileDialog" :pet="photo.pet" />

    <v-dialog v-model="show" >
                <v-img :src="photo.photo"  contain @click="show = false"></v-img>
            </v-dialog>


    <v-dialog v-model="rejectDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Why rejecting?</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="rejectDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-form ref="rejectForm" v-model="valid" lazy-validation @submit.prevent="reject">
                <v-card-text class="pt-4">
                    <v-radio-group v-model="rejectReason" class="mt-2" mandatory>
                        <v-radio label="Text on image" value="Text on image"></v-radio>
                        <v-radio label="Poor quality" value="Poor quality"></v-radio>
                        <v-radio label="Irrelevant image" value="Irrelevant image"></v-radio>
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit" :loading="rejecting" :disabled="!valid || rejecting">Reject</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import PetProfileInfo from "./PetProfileInfo.vue";

export default {
    name: 'PhotoCard',
    components: {PetProfileInfo},
    props: ['photo'],
    mounted() {
      this.isMounted = true;
    },
    data() {
        return {
            petProfileDialog: false,
            approving: false,
            rejecting: false,
            rejectDialog: false,
            rejectReason: '',
            show: false,
            valid: true,
            isMounted: false,
        }
    },

    methods: {

        approve(val) {
            let photo_id = this.photo.xid;
            let approve = val

            this.approving = true;
            this.$store.dispatch('savePhoto', {
                    approve,
                    photo_id,
                }).then(() => {
                    this.approving = false;
                    this.photo.verified = val
                })
                .catch(err => {
                    this.approving = false;
                });
        },

        reject() {
            let photo_id = this.photo.xid;
            let reject_reason = this.rejectReason

            this.rejecting = true;
            this.$store.dispatch('rejectPhoto', {
                    photo_id,
                    reject_reason
                }).then(() => {
                    this.rejecting = false;
                    this.rejectDialog = false;
                })
                .catch(err => {
                    this.rejecting = false;
                });

        },
        showPetDetails() {
            this.petProfileDialog = true;
        },

    }

}
</script>
