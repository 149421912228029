<template>
<v-container fluid class="home">

    <div v-if="this.$store.getters.userDetails.name == '' || loading" class="pt-12">

        <v-row no-gutters>
            <v-col cols="12" justify="center" align="center" class="pt-12">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>

        </v-row>

    </div>
    <div v-else class="mb-16">

        <div class="my-2 mb-4">
            <v-row no-gutters>
                <v-col cols="12">
                    <h4 class="text-h6">User Names</h4>
                </v-col>

            </v-row>

        </div>
        <v-row v-if="this.$store.getters.user_names.length == 0">
            <v-col cols="12" class="pa-12 text-center">
                <v-icon size="120" color="#eee">mdi-thumb-up-outline</v-icon>
                <div>Well Done!!</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item,index) in this.$store.getters.user_names" :key="index" class="pa-2">
                <user-name-card :name="item"></user-name-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="text-center py-12">
                <v-btn color="primary" @click="approve_all" :loading="approving" :disabled="approving">APPROVE ALL {{$store.getters.user_names.length}}</v-btn>
            </v-col>
        </v-row>
    </div>

    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    <v-btn color="primary" dark fixed bottom right fab @click="getUserNames">
        <v-icon>mdi-sync</v-icon>
    </v-btn>

</v-container>
</template>

<script>
import UserNameCard from "./components/UserNameCard.vue";
export default {
    name: 'UserNames',
    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => {});
        } else {
            this.getUserNames();
        }

    },
    data() {
        return {
            loading: false,
            snackbar: false,
            snackbar_text: '',
        }
    },
    components: {
      UserNameCard,
    },
    methods: {

        getUserNames() {
            this.loading = true;
            this.$store.dispatch('getUserNames').then(() => {
                    this.loading = false;
                })
                .catch(err => {
                    //console.log(err)
                    this.loading = false;
                    this.snackbar_text = "Oops, Couldn't get data. Please try again.";
                    this.snackbar = true;
                });
        },
        approve_all() {
            const btns = document.querySelectorAll(".name_approve_btn");
            btns.forEach(function(btn) {
                btn.click();
            });
        },
    }

}
</script>
