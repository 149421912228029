import Vue from 'vue'
import VueMoment from 'vue-moment'
import VueCookie from 'vue-cookie'
import Axios from 'axios'

import Main from './layouts/Main.vue'
import App from './layouts/App.vue'
//import './registerServiceWorker'

import wb from "./registerServiceWorker";

Vue.prototype.$workbox = wb;

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueApexCharts from 'vue-apexcharts'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  releaseStage: process.env.VUE_APP_BUGSNAG_RELEASE_STAGE
})

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)


import './styles/custom.scss'


import VueMeta from 'vue-meta'
 
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueMoment)
Vue.use(VueCookie)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

if (Vue.cookie.get('pma_token')) {
  Axios.defaults.headers.common['Authorization'] = 'Bearer '+Vue.cookie.get('pma_token');
}
else{
    Axios.defaults.headers.common['Authorization'] = '';
}

Axios.defaults.baseURL = process.env.VUE_APP_API_URL+'/api'


new Vue({
  router,
  store,
  vuetify,
  render: h => h(store.getters.isLoggedIn ? App : Main)
}).$mount('#app')
