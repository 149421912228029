<template>
<div>
    <v-card class="mild-shadow rounded-lg ma-1">

        <!-- <v-list-item class="py-2">

            <v-list-item-avatar size="48">
        
                        <v-img v-if="item.reported_user.photo" :src="item.reported_user.photo"></v-img>
                        <v-icon color="#7E0DE7" v-else size="24">$vuetify.icons.account-icon</v-icon>
            
          </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title class="primary--text">{{item.reported_user.name}}</v-list-item-title>
                <v-list-item-subtitle class="text--primary text-caption">{{item.reported_user.email}}</v-list-item-subtitle>
            </v-list-item-content>

        </v-list-item> -->

       <v-card-text class="py-0">
        <!-- <span class="text-caption font-weight-medium">A/c Closed: </span><span class="secondary--text text-caption">{{user.feedback.created_at | moment("MMM D YYYY")}} ({{ user.feedback.created_at | moment("from", "now") }})</span>
        <div>
            <span class="text-caption font-weight-medium">Recommend: </span><span class="secondary--text text-caption"> {{user.feedback.will_recommend}} </span>
        </div> -->
        <v-row>
            <v-col cols="12" md="6">
                <div class="text-caption font-weight-medium black--text">Suspect:</div>
                <div class="text-caption">{{item.scam_user.name}}</div>
                <div class="text-caption">{{item.scam_user.email}}</div>
                <v-divider class="my-2"></v-divider>
                <div><span class="text-caption font-weight-medium">Pets: </span><span class="secondary--text text-caption">{{item.scam_user.pets}}</span></div>
                <div><span class="text-caption font-weight-medium">Locations: </span><span class="secondary--text text-caption">{{item.scam_user.pets_locations}}</span></div>
                <div><span class="text-caption font-weight-medium">Conversations: </span><span class="secondary--text text-caption">{{item.scam_user.conversations}}</span></div>
            </v-col>
            <v-col cols="12" md="6">
                <div class="text-caption font-weight-medium black--text">Reported By:</div>
                <div class="text-caption">{{item.reported_user.name}}</div>
                <div class="text-caption">{{item.reported_user.email}}</div>
                <v-divider class="my-2"></v-divider>
                <div class="text-caption font-weight-medium black--text">Reason:</div>
                <div class="text-caption">{{item.reason}}</div>
            </v-col>

        </v-row>
       </v-card-text>

        <v-card-actions>

            <div class="text-caption pl-2 font-weight-medium black--text">Reported At:&nbsp;</div><div class="text-caption"> {{ item.created_at || new Date() | moment("D MMM YY, h:mm a") }}</div>
            <v-spacer></v-spacer>

            <v-btn small color="success" @click="update('ignored')" :loading="updating" :disabled="updating">
                IGNORE
            </v-btn>

            <v-btn small color="error" @click="banConfirm = true">
                BAN
            </v-btn>



        </v-card-actions>

    </v-card>

    <v-dialog v-model="banConfirm" max-width="400">
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <p>
                    Are you sure you want to ban the suspected user
                </p>
            </v-card-text>

            <v-card-actions>
                <v-btn text color="grey" @click="banConfirm = false">No</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="update('banned')" :loading="updating" :disabled="updating">Yes, Ban The Suspect</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

    



</div>
</template>

<script>
export default {
    name: 'ScamReportingCard',
    props: ['item'],
    data() {
        return {
            updating: false,
            banConfirm: false,
        

        }
    },



    methods: {

        update(action)
        {
            let scam_reporting_id = this.item.xid;
            let scammer_id = this.item.scam_user_id;

            this.updating = true;
            this.$store.dispatch('updateScamReporting', {
                scam_reporting_id,
                scammer_id,
                action
                }).then(() => {
                    this.updating = false;
                    this.banConfirm = false;
                })
                .catch(err => {
                    this.updating = false;
                });

        }


    }

}
</script>
