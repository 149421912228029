<template>
  <v-row no-gutters>
      <v-col cols="12" class="text-center" v-if="!user.conversation.length">
        User do not have any conversations
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-else v-for="(conversation, index) in user.conversation" :key="index" class="pa-2">
          <v-card class="my-4 mild-shadow">
            <v-card-title>
                  <v-list-item v-if="conversation.parties[0].user.xid == user.xid">
                      <v-list-item-content class="py-4" v-if="conversation.parties[1].pet">
                        <v-list-item-title class="font-weight-medium">
                          <span class="float-left mr-1">{{conversation.parties[1].pet.name}}</span>
                          <v-icon small color="#d4af37" v-if="conversation.parties[1].pet.is_premium">mdi-crown</v-icon>
                          <v-icon small color="#ff0000" v-if="conversation.parties[1].pet.user.banned">mdi-account-cancel</v-icon>
                        </v-list-item-title>

                        <v-list-item-subtitle class="text-caption secondary--text" v-html="conversation.parties[1].pet.breed"></v-list-item-subtitle>
                        <v-list-item-subtitle v-if="conversation.parties[0].pet" class="text-caption" v-html="'For '+conversation.parties[0].pet.name"></v-list-item-subtitle>
          
                      </v-list-item-content>
                      <v-list-item-content class="py-4" v-else>
                        <v-list-item-title class="font-weight-medium">
                          <span class="float-left mr-1">{{conversation.parties[1].user.name}}</span>
                          <v-icon small color="#d4af37" v-if="conversation.parties[1].user.is_premium">mdi-crown</v-icon>
                          <v-icon small color="#ff0000"  v-if="conversation.parties[1].user.banned">mdi-account-cancel</v-icon>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="conversation.parties[0].pet" class="text-caption" v-html="'For '+conversation.parties[0].pet.name"></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action class="align-center justify-center" style="max-width:200px">
                        <v-chip v-if="conversation.unread_messages_count > 0" color="green" small dark style="padding:4px 8px; line-height:1; min-width:24px; text-align:center; display:inline-block">{{conversation.unread_messages_count}}</v-chip>
                        <v-list-item-action-text class="pt-1 text-truncate">{{conversation.updated_at | moment("D MMM YY")}}</v-list-item-action-text>
                      </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-else>
                      <v-badge color="#d4af37" icon="mdi-crown" offset-y="25" offset-x="30" class="mt-2" :value="(conversation.parties[0].pet && conversation.parties[0].pet.is_premium) || conversation.parties[0].user.is_premium">
                        <v-list-item-avatar v-if="conversation.parties[0].pet" class="ml-0 justify-center" color="primary">
                          <v-img v-if="conversation.parties[0].pet.avatar" :src="conversation.parties[0].pet.avatar.photo"></v-img>
                          <v-icon dark v-else size="24">{{conversation.parties[0].pet.pet_type ? '$vuetify.icons.'+conversation.parties[0].pet.pet_type.icon : 'mdi-paw'}}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-avatar v-else class="ml-0 justify-center" color="primary">
                          <v-img v-if="conversation.parties[0].user.photo" :src="conversation.parties[0].user.photo"></v-img>
                          <v-icon dark v-else size="24">$vuetify.icons.account-icon</v-icon>
                        </v-list-item-avatar>
                      </v-badge>
                      <v-list-item-content class="py-4" v-if="conversation.parties[0].pet">
                        <v-list-item-title class="font-weight-medium">
                            <span class="float-left mr-1">{{conversation.parties[0].pet.name}}</span>
                            <v-icon small color="#d4af37" v-if="conversation.parties[0].pet.is_premium">mdi-crown</v-icon>
                            <v-icon small color="#ff0000" v-if="conversation.parties[0].pet.user.banned">mdi-account-cancel</v-icon>
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-caption secondary--text" v-html="conversation.parties[0].pet.breed"></v-list-item-subtitle>
                        <v-list-item-subtitle v-if="conversation.parties[1].pet" class="text-caption" v-html="'For '+conversation.parties[1].pet.name"></v-list-item-subtitle>
                        
                      </v-list-item-content>
                      <v-list-item-content class="py-4" v-else>
                          <v-list-item-title class="font-weight-medium">
                              <span class="float-left mr-1">{{conversation.parties[0].user.name}}</span>
                              <v-icon small color="#d4af37" v-if="conversation.parties[0].user.is_premium">mdi-crown</v-icon>
                              <v-icon small color="#ff0000" v-if="conversation.parties[0].user.banned">mdi-account-cancel</v-icon>
                          </v-list-item-title>
                        <v-list-item-subtitle v-if="conversation.parties[1].pet" class="text-caption" v-html="'For '+conversation.parties[1].pet.name"></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action class="align-center justify-center" style="max-width:200px">
                        <v-chip v-if="conversation.unread_messages_count > 0" color="green" small dark style="padding:4px 8px; line-height:1; min-width:24px; text-align:center; display:inline-block">{{conversation.unread_messages_count}}</v-chip>
                        <v-list-item-action-text class="pt-1 text-truncate">{{conversation.updated_at | moment("D MMM YY")}}</v-list-item-action-text>
                      </v-list-item-action>
                  </v-list-item>

                  <v-list-item class="rounded-lg" style="background-color: #e7f3ff ;">
                      <v-list-item-content  v-if="conversation.parties[0].user.xid == user.xid" style="margin:0">
                        <v-list-item-subtitle v-if="conversation.parties[1].pet" class="text-caption info--text"><strong class="font-weight-medium">{{conversation.parties[1].pet.user.name}}</strong> <br/> {{conversation.parties[1].pet.user.email}}</v-list-item-subtitle>
                        <v-list-item-subtitle v-else class="text-caption info--text"><strong class="font-weight-medium">{{conversation.parties[1].user.name}}</strong> <br/> {{conversation.parties[1].user.email}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content class="align-center justify-center" v-else style="margin:0">
                          <v-list-item-subtitle v-if="conversation.parties[0].pet" class="text-caption info--text"><strong class="font-weight-medium">{{conversation.parties[0].pet.user.name}}</strong> <br/> {{conversation.parties[0].pet.user.email}}</v-list-item-subtitle>
                          <v-list-item-subtitle v-else class="text-caption info--text"><strong class="font-weight-medium">{{conversation.parties[0].user.name}}</strong> <br/> {{conversation.parties[0].user.email}}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                  
              </v-card-title>
              <v-card-text>
                  <v-row v-for="(item, index) in conversation.messages"
                         :key="index" :justify="item.fuid == user.xid ? 'end' : 'start'">
                    <v-col cols="10">
                      <div class="text-caption pa-1" v-show="item.fuid != user.xid">
                        {{ item.created_at || new Date() | moment("D MMM YY, h:mm a") }}
                        <span class="pl-2" v-if="item.verified && !item.read" @click="legendDialog = true">
                            <v-icon small color="green">mdi-check</v-icon>
                        </span>
                        <span class="pl-2" v-if="item.read" @click="legendDialog = true">
                            <v-icon small color="blue">mdi-check-all</v-icon>
                        </span>
                      </div>


                      <v-sheet :color="item.fuid == user.xid ? 'primary' : '#edf0f5'"
                               :dark="item.fuid == user.xid"
                               :class="item.fuid == user.xid ? 'rounded-br-0 rounded-xl pa-4' : 'rounded-tl-0 rounded-xl pa-4'">
                        <span class="text-pre-wrap" v-if="item.message_type == 'text'">{{ item.message }}</span>
                        <v-img v-else class="ml-auto rounded-xl" max-height="200px" :src="item.photo.photo" cover aspect-ratio="1"></v-img>
                      </v-sheet>

                      <div class="text-caption text-right pa-1" v-show="item.fuid == user.xid">
                        {{ item.created_at || new Date() | moment("D MMM YY, h:mm a") }}

                        <span class="pl-2" v-if="item.verified && !item.read" @click="legendDialog = true">
                                      <v-icon small color="green">
                                          mdi-check
                                      </v-icon>
                                  </span>
                        <span class="pl-2" v-if="item.read" @click="legendDialog = true">
                                      <v-icon small color="blue">
                                          mdi-check-all
                                      </v-icon>
                                  </span>

                      </div>
                    </v-col>
                  </v-row>
              </v-card-text>
            </v-card>
        </v-col>
    </v-row>

</template>

<script>
export default {
  name: 'UserConversations',
  props: ['user'],
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }

}
</script>
