<template>
<div>
    <v-card class="mild-shadow rounded-lg">

        <v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle class="text--primary text-caption"><b>User:</b> {{name.email}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-list-item-action-text class="secondary--text">{{ $moment(name.updated_at).fromNow(true) }}</v-list-item-action-text>
            </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-card-text>
            <div class="text-h6 pb-2">{{name.name}}</div>
            
            <div class="text-caption text-pre-wrap">{{name.description}}</div>

        </v-card-text>

        <v-card-actions>
            
            <v-spacer></v-spacer>
            <v-btn small text color="primary" outlined @click="nameDialog=true">
                EDIT
            </v-btn>
            <v-btn small color="success" depressed v-if="name.name_verified" @click="approve(0)" :loading="approving" :disabled="approving">
                APPROVED
            </v-btn>
            <v-btn small text color="success" outlined v-else  @click="approve(1)" :loading="approving" :disabled="approving">
                APPROVE
            </v-btn>

        </v-card-actions>

    </v-card>


    <v-dialog v-model="nameDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Name</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="nameDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-form ref="nameForm" v-model="valid" lazy-validation @submit.prevent="updateName">
                <v-card-text class="pt-4">
                    <v-text-field outlined dense v-model="name.name" placeholder="Name" :rules="nameRules"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit" :loading="updating" :disabled="!valid || updating">Save</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
export default {
    name: 'NameCard',
    props: ['name'],
    data() {
        return {
            approving: false,
            updating: false,
            nameDialog: false,

            ex11: false,

            valid: true,

            nameRules: [
                v => !!v || 'Name is required',
                v => !(/@/.test(v)) || 'Name must be valid',
            ],
        }
    },

    methods: {

        approve(val) {
            let pet_id = this.name.xid;
            let name = this.name.name
            let approve = val

            this.approving = true;
            this.$store.dispatch('saveName', {
                    name,
                    approve,
                    pet_id,
                }).then(() => {
                    this.approving = false;
                    this.name.name_verified = val
                })
                .catch(err => {
                    this.approving = false;
                });
        },

        updateName() {
            let pet_id = this.name.xid;
            let name = this.name.name
            let approve = this.name.name_verified

            this.updating = true;
            this.$store.dispatch('saveName', {
                    name,
                    approve,
                    pet_id,
                }).then(() => {
                    this.updating = false;
                    this.nameDialog = false;
                })
                .catch(err => {
                    this.updating = false;
                });

        }

    }

}
</script>
