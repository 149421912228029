<template>
  <v-row>

      <v-col cols="6" sm="3" md="2" lg="2" :key="item.xid" v-for="(item) in pedigree" class="text-center">
          <v-img :src="item.cloud_url" cover  :title="item.reject_reason" aspect-ratio="1.333" style="border-radius:10px; min-height:100px"></v-img>
          <v-chip small class="ma-2" :color="item.verified ? 'success' : 'error'"><v-icon small left> {{ item.verified ? 'mdi-thumb-up' : 'mdi-thumb-down' }}</v-icon>Pedigree Certificate</v-chip>
        </v-col>


      <v-col cols="6" sm="3" md="2" lg="2" :key="item.xid" v-for="(item) in dna" class="text-center">
          <v-img :src="item.cloud_url" cover  :title="item.reject_reason" aspect-ratio="1.333" style="border-radius:10px; min-height:100px"></v-img>
          <v-chip small class="ma-2" :color="item.verified ? 'success' : 'error'"><v-icon small left> {{ item.verified ? 'mdi-thumb-up' : 'mdi-thumb-down' }}</v-icon>DNA Report</v-chip>
        </v-col>

     
      <v-col cols="6" sm="3" md="2" lg="2" :key="item.xid" v-for="(item) in profileVideo" class="text-center">
        <v-card height="147">
            <video-player :options="videoOptions" :source="item.cloud_url" :id="item.xid" :title="item.reject_reason"/>
        </v-card>
        <v-chip small class="ma-2" :color="item.verified ? 'success' : 'error'"> 
            <v-icon small left> {{ item.verified ? 'mdi-thumb-up' : 'mdi-thumb-down' }}</v-icon> Verification Video</v-chip>
      </v-col>
  </v-row>
</template>
<script>

import VideoPlayer from "./VideoPlayer.vue";

export default {
    name: "UserDetailsPetProfileVerification",
  components: {VideoPlayer},
    props: ["pet"],
    data() {
        return {
            pedigree : [],
            dna : [],
            profileVideo : [],

            updateField: '',
            updateValue: '',
            rejecting: false,
            rejectDialog: false,
            rejectReason: '',
            valid: true,

            updating: false,
            confirmDialog: false,
            send_notification: false,

            videoOptions: {
              autoplay: false,
              controls: true,
              playbackRates: [0.25, 0.5, 1, 1.5, 2, 4]
            }
        }
    },
    mounted() {
        this.pedigree = this.pet.pet_profile_verification.filter((o) => {
            return o.type == 'pedigree';
        })
        let pedigreeRejected = this.pet.pet_profile_verification_rejected.filter((o) => {
            return o.type == 'pedigree';
        })

        this.pedigree = this.pedigree.concat(pedigreeRejected);

        this.dna = this.pet.pet_profile_verification.filter((o) => {
            return o.type == 'dna';
        })
        let dnaRejected = this.pet.pet_profile_verification_rejected.filter((o) => {
            return o.type == 'dna';
        })

        this.dna = this.dna.concat(dnaRejected);

        this.profileVideo = this.pet.pet_profile_verification.filter((o) => {
            return o.type == 'profile_video';
        })
        let profileVideoRejected = this.pet.pet_profile_verification_rejected.filter((o) => {
            return o.type == 'profile_video';
        })

        this.profileVideo = this.profileVideo.concat(profileVideoRejected);
    },
    methods: {
    }
}
</script>