<template>
<div>

    <v-app-bar color="primary" flat app dark v-if="showSearchBar">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-btn icon v-if="this.$store.getters.previousRoute != null && ($route.name == 'SearchUser' || $route.name == 'UserDetails')" @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
        <v-text-field v-model.trim="searchText" dense filled rounded clearable placeholder="Search" prepend-inner-icon="mdi-magnify" class="pt-7"  @keyup.enter="onSearch"></v-text-field>
        <v-btn icon @click="showSearchBar=false"><v-icon>mdi-close</v-icon></v-btn>
    </v-app-bar>

    <v-app-bar color="primary" flat app dark v-else>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-badge content="A D M I N" inline>
        <v-toolbar-title class="pl-0">
            <v-img alt="Petmeetly Logo" class="shrink" contain src="@/assets/logo/petmeetly-text-white.png" max-width="120" max-height="35" />
            
        </v-toolbar-title>
        </v-badge>

        <v-spacer></v-spacer>
        <v-btn icon @click="showSearchBar=true"><v-icon>mdi-magnify</v-icon></v-btn>
        </v-app-bar>

    

    <v-navigation-drawer v-model="drawer" absolute temporary class="menu-bg" height="100vh" :width="360">
        <v-list>

            <div class="pt-2 pl-4">
                <v-avatar size="64" v-if="this.$store.getters.userDetails.photo">
                    <v-img v-if="this.$store.getters.userDetails.photo" :src="this.$store.getters.userDetails.photo"></v-img>
                </v-avatar>
            </div>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="primary--text font-weight-medium">
                        {{this.$store.getters.userDetails.name}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption">{{this.$store.getters.userDetails.email}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

        </v-list>
        <v-divider></v-divider>
        <v-list flat>
            <v-list-item-group v-model="group" color="primary">

                <v-list-item :to="'/home'">
                    <v-list-item-icon>
                        <v-icon>$vuetify.icons.home-icon</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item :to="'/reports'">
                    <v-list-item-icon>
                        <v-icon>mdi-chart-areaspline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Reports</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->

                <v-list-group
                    :value="true"
                    prepend-icon="mdi-shield-check-outline"
                >
                    <template v-slot:activator>
                        <v-list-item-title>Verify</v-list-item-title>
                    </template>

                <v-list-item :to="'/descriptions'" class="ml-4">
                    <v-list-item-icon>
                        <v-icon>mdi-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Descriptions</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip small>
                            {{this.$store.getters.pending.descriptions}}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item :to="'/pet-info-verifications'" class="ml-4">
                    <v-list-item-icon>
                        <v-icon>mdi-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Pet Info Verifications</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip small>
                            {{this.$store.getters.pending.petInfoVerifications}}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item :to="'/preference-descriptions'" class="ml-4">
                    <v-list-item-icon>
                        <v-icon>mdi-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Preference Descriptions</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip small>
                            {{this.$store.getters.pending.preferences}}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>

                  <v-list-item :to="'/names'" class="ml-4">
                    <v-list-item-icon>
                        <v-icon>mdi-text-short</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Names</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip small>
                            {{this.$store.getters.pending.names}}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item :to="'/user-names'" class="ml-4">
                    <v-list-item-icon>
                        <v-icon>mdi-text-short</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>User Names</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip small>
                            {{this.$store.getters.pending.userNames}}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>

              <v-list-item :to="'/photos'" class="ml-4">
                <v-list-item-icon>
                  <v-icon>mdi-image-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Photos</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small>
                    {{this.$store.getters.pending.photos}}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>


              <v-list-item :to="'/user-photos'" class="ml-4">
                <v-list-item-icon>
                  <v-icon>mdi-image-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>User Photos</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small>
                    {{this.$store.getters.pending.userPhotos}}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>

              <v-list-item :to="'/videos'" class="ml-4">
                <v-list-item-icon>
                  <v-icon>mdi-video-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Videos</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small>
                    {{this.$store.getters.pending.videos}}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>

                <v-list-item :to="'/messages'" class="ml-4">
                    <v-list-item-icon>
                        <v-icon>$vuetify.icons.messages-icon</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Messages</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip small>
                            {{this.$store.getters.pending.messages}}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item :to="'/profile-videos'" class="ml-4">
                <v-list-item-icon>
                  <v-icon>mdi-video-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Profile Videos</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small>
                    {{this.$store.getters.pending.profileVideos}}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>


              <v-list-item :to="'/adopter-questionnaire'" class="ml-4">
                    <v-list-item-icon>
                      <v-icon>mdi-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Adopter Questionnaire</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip small>
                        {{this.$store.getters.pending.adopterQuestionnaire}}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item :to="'/user-profile-videos'" class="ml-4">
                    <v-list-item-icon>
                      <v-icon>mdi-video-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>User Profile Videos</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip small>
                        {{this.$store.getters.pending.userVideos}}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  
              <v-list-item :to="'/breeder-questionnaire'" class="ml-4">
                  <v-list-item-icon>
                      <v-icon>mdi-text</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>Breeder Questionnaire</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                      <v-chip small>
                          {{this.$store.getters.pending.breederQuestionnaire}}
                      </v-chip>
                  </v-list-item-action>
              </v-list-item>
              <v-list-item :to="'/shelter-questionnaire'" class="ml-4">
                  <v-list-item-icon>
                      <v-icon>mdi-text</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title>Shelter Questionnaire</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                      <v-chip small>
                          {{this.$store.getters.pending.shelterQuestionnaire}}
                      </v-chip>
                  </v-list-item-action>
              </v-list-item>

                  

              <v-list-item :to="'/certificates'" class="ml-4">
                <v-list-item-icon>
                  <v-icon>mdi-certificate</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Certificates</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small>
                    {{this.$store.getters.pending.certificates}}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>

              <v-list-item :to="'/dna-reports'" class="ml-4">
                <v-list-item-icon>
                  <v-icon>mdi-certificate-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Dna Reports</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small>
                    {{this.$store.getters.pending.dnaReports}}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>

              </v-list-group>

              <v-list-group
                    :value="true"
                    prepend-icon="mdi-account-check-outline"
                >
                    <template v-slot:activator>
                        <v-list-item-title>Social Media Following</v-list-item-title>
                    </template>

                    <v-list-item :to="'/instagram-followers'" class="ml-4">
                        <v-list-item-icon>
                            <v-icon>mdi-instagram</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Instagram</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-chip small>
                                {{this.$store.getters.pending.instagramFollowers}}
                            </v-chip>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item :to="'/twitter-followers'" class="ml-4">
                        <v-list-item-icon>
                            <v-icon>mdi-twitter</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Twitter</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-chip small>
                                {{this.$store.getters.pending.twitterFollowers}}
                            </v-chip>
                        </v-list-item-action>
                    </v-list-item>
                </v-list-group>

                <v-list-item :to="'/feedback'">
                    <v-list-item-icon>
                        <v-icon>mdi-comment-quote-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Feedback</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip small>
                            {{this.$store.getters.pending.feedback}}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item :to="'/scam-reportings'">
                    <v-list-item-icon>
                        <v-icon>mdi-alert-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Scam Reports</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip small>
                            {{this.$store.getters.pending.scamReportings}}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>

                <v-list-group
                    :value="true"
                    prepend-icon="mdi-heart-outline"
                >
                    <template v-slot:activator>
                        <v-list-item-title>Favorites</v-list-item-title>
                    </template>

                    <v-list-item :to="'/favorites'" class="ml-4">
                        <v-list-item-icon>
                            <v-icon>mdi-text</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Descriptions</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-chip small>
                                {{this.$store.getters.pending.favorites}}
                            </v-chip>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item :to="'/favorite-videos'" class="ml-4">
                        <v-list-item-icon>
                            <v-icon>mdi-video-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Videos</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-chip small>
                                {{this.$store.getters.pending.favoriteVideos}}
                            </v-chip>
                        </v-list-item-action>
                    </v-list-item>
                </v-list-group>

                <v-list-item :to="'/premium'">
                    <v-list-item-icon>
                        <v-icon>mdi-crown-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Premium</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip small>
                            {{this.$store.getters.pending.premium}}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>

            </v-list-item-group>
        </v-list>

        <template v-slot:append>

            <v-list flat>

                <v-list-item-group v-model="group" color="primary">

                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon>$vuetify.icons.logout-icon</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list-item-group>

            </v-list>

        </template>
    </v-navigation-drawer>
</div>
</template>

<script>
export default {
    name: 'TopBar',

    data: () => ({
        drawer: false,
        group: null,
        markingRead: false,
        searchText: null,
        searchBoxClosed: true,
        showSearchBar: false,
    }),

    watch: {
        // group() {
        //     this.drawer = false
        // },
    },

    methods: {
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push('/login').catch(err => {});
                })
                .catch(err => {

                });
        },
      onSearch() {
          this.$router.push({path: '/search-user', query: {email:this.searchText}}).catch(err => {});
      }
    }

}
</script>

