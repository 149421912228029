<template>
    <v-container fluid class="home pa-0">

        <div v-if="!this.$store.getters.instagramFollowers" class="pt-12">

            <v-row no-gutters>
                <v-col cols="12" justify="center" align="center" class="pt-12">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
            </v-row>
        </div>

        <div v-else class="mb-16">

            <v-row v-if="this.$store.getters.instagramFollowers.length == 0">
            <v-col cols="12" class="pa-12 text-center">
                <v-icon size="120" color="#eee">mdi-thumb-up-outline</v-icon>
                <div>Well Done!!</div>
            </v-col>
        </v-row>

            <div>

                <v-row no-gutters>
                    <v-col class="col-12" md="12">
                        <v-list two-line>
                            <v-list-item v-for="(item, index) in this.$store.getters.instagramFollowers" :key="index"
                                style="border-bottom:1px solid #eee" @click="navigateToInstagram(item.instagram_handle)">
                                <v-list-item-avatar>
                                    <v-img v-if="item.photo" :src="item.photo"></v-img>
                                    <v-icon color="#7E0DE7" v-else size="36">mdi-instagram</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.instagram_handle"></v-list-item-title>
                                    <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>

                                    <v-btn small color="success" class="ma-2" @click.native.prevent.stop="updateInstagramFollower(item.xid, true)"
                                            :loading="submitting && current_xid == item.xid"
                                            :disabled="submitting && current_xid == item.xid">Following</v-btn>
                                        <v-btn small color="error" class="ma-2" @click.native.prevent.stop="updateInstagramFollower(item.xid, false)"
                                            :loading="nsubmitting && current_xid == item.xid"
                                            :disabled="nsubmitting && current_xid == item.xid">Not Following</v-btn>

                                </v-list-item-action>
                            </v-list-item>

                        </v-list>
                    </v-col>
                </v-row>
            </div>
        </div>

        <v-snackbar v-model="snackbar" bottom>
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <!--  <v-btn color="primary" dark fixed bottom right fab @click="searchUser" :loading="loading">
        <v-icon>mdi-chevron-double-down</v-icon>
    </v-btn> -->
    </v-container>
</template>

<script>

export default {
    name: 'InstagramFollowers',

    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => { });
        } else {
            this.getInstagramFollowers();
        }

    },
    data() {
        return {
            loading: false,
            snackbar: false,
            snackbar_text: '',
            page: 0,
            submitting: false,
            nsubmitting: false,
            current_xid: null,
        }
    },

    methods: {

        getInstagramFollowers() {
            this.loading = true;
            let page = this.page + 1
            this.$store.dispatch('getInstagramFollowers', page).then(() => {
                this.loading = false;
                this.page = page;
            })
                .catch(err => {
                    this.loading = false;
                    this.snackbar_text = "Oops, Couldn't get data. Please try again.";
                    this.snackbar = true;
                });
        },

        navigateToInstagram(handle) {
            window.open('https://instagram.com/' + handle, '_blank');
        },

        updateInstagramFollower(xid, status) {
            this.current_xid = xid;

            if(status == true)
                this.submitting = true;
            else
                this.nsubmitting = true;


            this.$store.dispatch('updateInstagramFollower', {
                xid, status
            })
                .then((resp) => {
                    this.submitting = false;
                    this.nsubmitting = false;
                })
                .catch(err => {
                    this.submitting = false;
                    this.nsubmitting = false;
                    this.snackbar_text = "Error verifying";
                    this.snackbar = true;
                });

        }
    }

}
</script>
