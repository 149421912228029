<template>
  <div>
    <v-card class="mild-shadow rounded-lg">

      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle class="text--primary text-caption"><b>User:</b> {{video.email}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-action-text class="secondary--text">{{ $moment(video.created_at).fromNow(true) }}</v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>

      <v-divider></v-divider>

      <v-card height="300">
        <video-player :options="videoOptions" :source="getVideoUrl(video)" :id="video.xid"/>
      </v-card>

      <v-card-text>
        <!-- <div class="text-caption">{{ video.video_name }}</div> -->
        <v-chip-group
            multiple
            active-class="primary--text"
            v-model="selectedTags"
        >
          <v-chip
              v-for="tag in tags"
              :key="tag"
              outlined
              small
              :disabled="true"
              :value="tag"
          >
            {{ tag }}
          </v-chip>
        </v-chip-group>
       </v-card-text>

      <v-card-actions>

        <v-chip class="golden-chip" v-show="video.is_premium_pet" small style="letter-spacing:1px"><v-icon small left>mdi-crown</v-icon>PREMIUM</v-chip>
      
        <v-spacer></v-spacer>
        <v-btn small text color="error" outlined :disabled="downloading" @click="download()">
          DOWNLOAD
        </v-btn>

        <v-btn small color="success" depressed v-if="video.sm_published" @click="publishVideo(0)" :loading="approving" :disabled="approving || disabled" class="video_approve_btn">
          PUBLISHED
        </v-btn>
        <v-btn small text color="success" outlined v-else  @click="publishVideo(1)" :loading="approving" :disabled="approving || disabled" class="video_approve_btn">
          PUBLISH
        </v-btn>
        <!-- <v-btn small color="success" depressed v-if="video.is_premium_pet" class="video_approve_btn">
          PREMIUM
        </v-btn> -->

      </v-card-actions>

    </v-card>

  </div>
</template>

<script>
import VideoPlayer from './VideoPlayer.vue'

export default {
  name: 'VideoCard',
  props: ['video'],
  data() {
    return {
      approving: false,
      downloading: false,
      selectedTags: [],
      tags: ['Cute', 'Funny'],

      valid: true,
      disabled: false,
      videoOptions: {
        autoplay: false,
        controls: true,
        playbackRates: [0.25, 0.5, 1, 1.5, 2, 4]
      }
    }
  },

  components: {
    VideoPlayer
  },
  mounted() {
    this.selectedTags = this.video.tags ? this.video.tags.split(',') : [];
  },
  methods: {

    getVideoUrl(item) {
      let url='https://api.dyntube.com/v1/live/videos/'+item.video_key+'.m3u8';
      return url;
    },

    publishVideo(val) {
      let video_id = this.video.xid;

      this.approving = true;
      this.$store.dispatch('publishVideo', {
        video_id,
      }).then(() => {
        this.approving = false;
        this.video.sm_published = val
      })
          .catch(err => {
            this.approving = false;
          });
    },

    download() {
      let video_id = this.video.xid;

      this.downloading = true;
      this.$store.dispatch('downloadVideo', video_id).then((res) => {
          window.open(res.data.url, "_blank");
          this.downloading = false;
      })
          .catch(err => {
            this.downloading = false;
          });
    }
  }

}
</script>
