import Vue from 'vue'
import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'


export default {
    state: {
        pending: {
            names: 0,
            user_names: 0,
            descriptions: 0,
            petInfoVerifications: 0,
            preferenceDescriptions: 0,
            adopterQuestionnaires: 0,
            breederQuestionnaires: 0,
            shelterQuestionnaires: 0,
            preferences: 0,
            messages: 0,
            photos: 0,
            user_photos: 0,
            videos: 0,
            profileVideos: 0,
            userProfileVideos: 0,
            certificates: 0,
            dnaReports: 0,
            premium: 0,
            favorites: 0,
            favoriteVideos: 0,
            feedback: 0,
            scamReportings: 0,
            instagramFollowers:0,
            twitterFollowers:0,
        },
        names:[],
        user_names:[],
        adopterQuestionnaires: [],
        breederQuestionnaires: [],
        shelterQuestionnaires: [],
        descriptions: [],
        petInfoVerifications: [],
        preferenceDescriptions: [],
        preferences: [],
        photos: [],
        user_photos: [],
        videos: [],
        profileVideos: [],
        userProfileVideos: [],
        certificate: {},
        dnaReports: [],
        messages: [],
        premium: [],
        feedback: [],
        favorites: [],
        favoriteVideos: [],
        scamReportings: [],
        users: [],
        instagramFollowers: [],
        twitterFollowers: [],
    },
    getters: {

        pending: state => state.pending,
        names: state => state.names,
        user_names: state => state.user_names,
        adopterQuestionnaires: state => state.adopterQuestionnaires,
        breederQuestionnaires: state => state.breederQuestionnaires,
        shelterQuestionnaires: state => state.shelterQuestionnaires,
        descriptions: state => state.descriptions,
        petInfoVerifications: state => state.petInfoVerifications,
        preferenceDescriptions: state => state.preferenceDescriptions,
        photos: state => state.photos,
        user_photos: state => state.user_photos,
        videos: state => state.videos,
        profileVideos: state => state.profileVideos,
        userProfileVideos: state => state.userProfileVideos,
        certificate: state => state.certificate,
        dnaReports: state => state.dnaReports,
        messages: state => state.messages,
        premium: state => state.premium,
        feedback: state => state.feedback,
        favorites: state => state.favorites,
        favoriteVideos: state => state.favoriteVideos,
        scamReportings: state => state.scamReportings,
        users: state => state.users,
        instagramFollowers: state => state.instagramFollowers,
        twitterFollowers: state => state.twitterFollowers,
    },
    mutations: {
        resetAppState(state)
        {
            
        },

        pending(state, pending) {
            state.pending = pending;
        },
        names(state, names) {
            state.names = names;
        },
        user_names(state, user_names) {
            state.user_names = user_names;
        },
        updateName(state, payload) {
            let idx = state.names.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.names[idx] = payload;
            }
        },

        updateUserName(state, payload) {
            let idx = state.user_names.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.user_names[idx] = payload;
            }
        },

        descriptions(state, descriptions) {
            state.descriptions = descriptions;
        },

        updateDescription(state, payload) {
            let idx = state.descriptions.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.descriptions[idx] = payload;
            }
        },

        petInfoVerifications(state, petInfoVerifications) {
            state.petInfoVerifications = petInfoVerifications;
        },

        updatePetInfoVerifications(state, payload) {
            let idx = state.petInfoVerifications.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1) {
                //state.petInfoVerifications[idx] = payload;
                Vue.set(state.petInfoVerifications, idx, payload);
            }
        },

        preferenceDescriptions(state, preferenceDescriptions) {
            state.preferenceDescriptions = preferenceDescriptions;
        },

        updatePreferenceDescription(state, payload) {
            let idx = state.preferenceDescriptions.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.preferenceDescriptions[idx] = payload;
            }
        },

        adopterQuestionnaires(state, adopterQuestionnaires) {
            state.adopterQuestionnaires = adopterQuestionnaires;
        },

        updateAdopterQuestionnaire(state, payload) {
            let idx = state.adopterQuestionnaires.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.adopterQuestionnaires[idx] = payload;

                /* if(payload.status == 'pending')
                    {
                        state.adopterQuestionnaires = state.adopterQuestionnaires.filter(function( item ) {
                            return item.xid !== payload.xid;
                        });
                    } */
            }
        },
        breederQuestionnaires(state, breederQuestionnaires) {
            state.breederQuestionnaires = breederQuestionnaires;
        },

        updateBreederQuestionnaire(state, payload) {
            let idx = state.breederQuestionnaires.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                // state.breederQuestionnaires[idx] = payload;
                Vue.set(state.breederQuestionnaires, idx, payload);
            }
        },
        shelterQuestionnaires(state, shelterQuestionnaires) {
            state.shelterQuestionnaires = shelterQuestionnaires;
        },

        updateShelterQuestionnaire(state, payload) {
            let idx = state.shelterQuestionnaires.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.shelterQuestionnaires[idx] = payload;
            }
        },

        messages(state, messages) {
            state.messages = messages;
        },

        updateMessage(state, payload) {
            let idx = state.messages.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.messages[idx] = payload;  
            }
        },

        user_photos(state, user_photos) {
            state.user_photos = user_photos;
        },

        photos(state, photos) {
            state.photos = photos;
        },

        videos(state, videos) {
            state.videos = videos;
        },

        profileVideos(state, profileVideos) {
            state.profileVideos = profileVideos;
        },

        userProfileVideos(state, userProfileVideos) {
            state.userProfileVideos = userProfileVideos;
        },

        certificate(state, certificate) {
            state.certificate = certificate;
        },

        dnaReports(state, dnaReports) {
            state.dnaReports = dnaReports;
        },

        updateUserPhoto(state, payload) {
            let idx = state.user_photos.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.user_photos[idx] = payload;
            }
        },
        rejectUserPhoto(state, payload) {
            state.user_photos = state.user_photos.filter(function( item ) {
                return item.xid !== payload.xid;
            });
        },

        updatePhoto(state, payload) {
            let idx = state.photos.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.photos[idx] = payload;
            }
        },

        rejectPhoto(state, payload) {
            state.photos = state.photos.filter(function( item ) {
                return item.xid !== payload.xid;
            });
        },

        updateVideo(state, payload) {
            let idx = state.videos.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.videos[idx] = payload;
            }
        },

        rejectVideo(state, payload) {
            /*state.videos = state.videos.filter(function( item ) {
                return item.xid !== payload.xid;
            });*/
        },

        updateProfileVideo(state, payload) {
            let idx = state.profileVideos.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.profileVideos[idx] = payload;
            }
        },

        updateUserProfileVideo(state, payload) {
            let idx = state.userProfileVideos.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.userProfileVideos[idx] = payload;
            }
        },

        rejectProfileVideo(state, payload) {
        },

        updateCertificate(state, payload) {
            /* let idx = state.certificates.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.certificates[idx] = payload;
            } */
            state.certificate = payload;
        },

        rejectCertificate(state, payload) {
            /* state.certificates = state.certificates.filter(function( item ) {
                return item.xid !== payload.xid;
            }); */
            state.certificate = payload;
        },
        updateDnaReport(state, payload) {
            let idx = state.dnaReports.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.dnaReports[idx] = payload;
            }
        },

        rejectDnaReport(state, payload) {
            state.dnaReports = state.dnaReports.filter(function( item ) {
                return item.xid !== payload.xid;
            });
        },

        premium(state, premium) {
            state.premium = premium;
        },

        addPremium(state, premium) {
            state.premium = [...state.premium, ...premium]
        },

        updatePremium(state, payload) {
            let idx = state.premium.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.premium[idx] = payload;
            }
        },

        favoriteVideos(state, favoriteVideos) {
            state.favoriteVideos = favoriteVideos;
        },
        updateFavoriteVideo(state, payload) {
            let idx = state.favoriteVideos.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.favoriteVideos[idx] = payload;
            }
        },
        addFavoriteVideos(state, favoriteVideos) {
            state.favoriteVideos = [...state.favoriteVideos, ...favoriteVideos]
        },

        favorites(state, favorites) {
            state.favorites = favorites;
        },

        addFavorites(state, favorites) {
            state.favorites = [...state.favorites, ...favorites]
        },

        updateFavorite(state, payload) {
            let idx = state.favorites.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.favorites[idx] = payload;
            }
        },

        feedback(state, feedback) {
            state.feedback = feedback;
        },

        addFeedback(state, feedback) {
            state.feedback = [...state.feedback, ...feedback]
        },

        updateFeedback(state, payload) {
            let idx = state.feedback.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.feedback[idx] = payload;
            }
        },

        scamReportings(state, scamReportings) {
            state.scamReportings = scamReportings;
        },

        addScamReporting(state, scamReporting) {
            state.scamReportings = [...state.scamReportings, ...scamReporting]
        },

        updateScamReporting(state, payload) {
            let idx = state.scamReportings.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.scamReportings.splice(idx,1);
            }
        },

        users(state, users) {
            state.users = users;
        },

        instagramFollowers(state, followers) {
            state.instagramFollowers = followers;
        },
        updateInstagramFollower(state, payload) {
            let idx = state.instagramFollowers.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.instagramFollowers.splice(idx,1);
            }
        },

        twitterFollowers(state, followers) {
            state.twitterFollowers = followers;
        },
        updateTwitterFollower(state, payload) {
            let idx = state.twitterFollowers.findIndex((obj => obj.xid == payload.xid));
            if(idx!==-1)
            {
                state.twitterFollowers.splice(idx,1);
            }
        },

    },
    actions: {

        pending({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/pending")
                    .then(resp => {
                        const pending = resp.data;
                        commit("pending", pending);
                        resolve(pending);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        getNames({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/names")
                    .then(async(resp) => {
                        const names = resp.data;
                        commit("names", names);
                        await dispatch('pending');
                        resolve(names);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveName({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/names", data)
                    .then(resp => {
                        commit("updateName", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getUserNames({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/user-names")
                    .then(async(resp) => {
                        const usernames = resp.data;
                        commit("user_names", usernames);
                        await dispatch('pending');
                        resolve(usernames);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveUserName({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/user-names", data)
                    .then(resp => {
                        commit("updateUserName", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getDescriptions({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/descriptions")
                    .then(async(resp) => {
                        const descriptions = resp.data;
                        commit("descriptions", descriptions);
                        await dispatch('pending');
                        resolve(descriptions);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },
        getPetInfoVerifications({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/pet-info-verifications")
                    .then(async(resp) => {
                        const petInfoVerifications = resp.data;
                        commit("petInfoVerifications", petInfoVerifications);
                        await dispatch('pending');
                        resolve(petInfoVerifications);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },
        updatePetVerificationInfo({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/update-pet-verification-info", data)
                    .then(resp => {
                        commit("updatePetInfoVerifications", resp.data);
                        // dispatch('getPetInfoVerifications');
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getAdopterQuestionnaires({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/adopter-questionnaires")
                    .then(async(resp) => {
                        const adopterQuestionnaires = resp.data;
                        commit("adopterQuestionnaires", adopterQuestionnaires);
                        await dispatch('pending');
                        resolve(adopterQuestionnaires);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveAdopterQuestionnaire({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/adopter-questionnaire", data)
                    .then(resp => {
                        commit("updateAdopterQuestionnaire", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getBreederQuestionnaires({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/breeder-questionnaires")
                    .then(async(resp) => {
                        const breederQuestionnaires = resp.data;
                        commit("breederQuestionnaires", breederQuestionnaires);
                        await dispatch('pending');
                        resolve(breederQuestionnaires);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveBreederQuestionnaire({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/breeder-questionnaire", data)
                    .then(resp => {
                        commit("updateBreederQuestionnaire", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        verifyQuestionnaireField({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/verify-questionnaire-field", data)
                    .then(resp => {
                        //commit("updateBreederQuestionnaire", resp.data);
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getShelterQuestionnaires({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/shelter-questionnaires")
                    .then(async(resp) => {
                        const shelterQuestionnaires = resp.data;
                        commit("shelterQuestionnaires", shelterQuestionnaires);
                        await dispatch('pending');
                        resolve(shelterQuestionnaires);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveShelterQuestionnaire({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/shelter-questionnaire", data)
                    .then(resp => {
                        commit("updateShelterQuestionnaire", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        saveWillingnessToTravel({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/willingness-to-travel", data)
                    .then(resp => {
                        commit("updateDescription", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        saveBreedingTerms({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/breeding-terms", data)
                    .then(resp => {
                        commit("updateDescription", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        saveAccommodationResponsibility({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/accommodation-responsibility", data)
                    .then(resp => {
                        commit("updateDescription", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        savePreferredPaymentType({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/preferred-payment-method", data)
                    .then(resp => {
                        commit("updateDescription", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        saveDescription({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/descriptions", data)
                    .then(resp => {
                        commit("updateDescription", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        likeDescription({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/description-like", data)
                    .then(resp => {
                        commit("updateDescription", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },


        getUserPhotos({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/user-photos")
                    .then(async(resp) => {
                        const user_photos = resp.data;
                        commit("user_photos", user_photos);
                        await dispatch('pending');
                        resolve(user_photos);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveUserPhoto({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/user-photo", data)
                    .then(resp => {
                        commit("updateUserPhoto", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        rejectUserPhoto({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/admin/user-photos", {data : data})
                    .then(resp => {
                        commit("rejectUserPhoto", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getPhotos({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/photos")
                    .then(async(resp) => {
                        const photos = resp.data;
                        commit("photos", photos);
                        await dispatch('pending');
                        resolve(photos);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        savePhoto({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/photos", data)
                    .then(resp => {
                        commit("updatePhoto", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        rejectPhoto({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/admin/photos", {data : data})
                    .then(resp => {
                        commit("rejectPhoto", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getVideos({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/videos")
                    .then(async(resp) => {
                        const videos = resp.data;
                        commit("videos", videos);
                        await dispatch('pending');
                        resolve(videos);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveVideo({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/videos", data)
                    .then(resp => {
                        commit("updateVideo", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        updateVideo({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/update-video-tags", data)
                    .then(resp => {
                        commit("updateVideo", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        rejectVideo({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/admin/videos", {data : data})
                    .then(resp => {
                        commit("rejectVideo", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getProfileVideos({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/profile-videos")
                    .then(async(resp) => {
                        const profileVideos = resp.data;
                        console.log(profileVideos);
                        commit("profileVideos", profileVideos);
                        await dispatch('pending');
                        resolve(profileVideos);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveProfileVideo({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/profile-video", data)
                    .then(resp => {
                        commit("updateProfileVideo", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        rejectProfileVideo({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/admin/profile-video", {data : data})
                    .then(resp => {
                        commit("rejectProfileVideo", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },



        getUserProfileVideos({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/user-profile-videos")
                    .then(async(resp) => {
                        const userProfileVideos = resp.data;

                        commit("userProfileVideos", userProfileVideos);
                        await dispatch('pending');
                        resolve(userProfileVideos);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveUserProfileVideo({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/user-profile-video", data)
                    .then(resp => {
                        commit("updateUserProfileVideo", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        rejectUserProfileVideo({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/admin/user-profile-video", {data : data})
                    .then(resp => {
                        commit("rejectUserProfileVideo", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },


        getCertificate({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/certificate?skipped="+data.skipped)
                    .then(async(resp) => {
                        const certificate = resp.data;
                        commit("certificate", certificate);
                        await dispatch('pending');
                        resolve(certificate);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveCertificate({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/certificate", data)
                    .then(resp => {
                        commit("updateCertificate", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        skipCertificate({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/certificate-skip", data)
                    .then(resp => {
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },


        moveCertificate({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/certificate-move", data)
                    .then(resp => {
                        commit("rejectCertificate", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        rejectCertificate({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/admin/certificate", {data : data})
                    .then(resp => {
                        commit("rejectCertificate", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },


        getDnaReports({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/dna-reports")
                    .then(async(resp) => {
                        const dnaReports = resp.data;
                        commit("dnaReports", dnaReports);
                        await dispatch('pending');
                        resolve(dnaReports);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveDnaReport({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/dna-report", data)
                    .then(resp => {
                        commit("updateDnaReport", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        rejectDnaReport({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/admin/dna-report", {data : data})
                    .then(resp => {
                        commit("rejectDnaReport", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getMessages({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/messages")
                    .then(async(resp) => {
                        const messages = resp.data;
                        commit("messages", messages);
                        await dispatch('pending');
                        resolve(messages);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        saveMessage({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/messages", data)
                    .then(resp => {
                        commit("updateMessage", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getPremium({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/premium", data)
                    .then(async(resp) => {
                        const premium = resp.data;
                        if(data.page == 1)
                        {
                            commit("premium", premium);
                        }
                        else
                        {
                            commit("addPremium", premium);
                        }
                        await dispatch('pending');
                        resolve(premium);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        notifyPremium({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/notify-premium", data)
                    .then(resp => {
                        commit("updatePremium", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getFeedback({ commit, dispatch }, page) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/feedback/"+page)
                    .then(async(resp) => {
                        const feedback = resp.data;
                        if(page == 1)
                        {
                            commit("feedback", feedback);
                        }
                        else
                        {
                            commit("addFeedback", feedback);
                        }
                        await dispatch('pending');
                        resolve(feedback);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        updateFeedback({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/feedback", data)
                    .then(resp => {
                        commit("updateFeedback", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getUser({ commit, dispatch }, xid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/user?xid="+xid)
                    .then(async(resp) => {
                        const user = resp.data;
                        resolve(user);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },
        searchUser({ commit, dispatch }, email) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/search-user?email="+email)
                    .then(async(resp) => {
                        const users = resp.data;
                        commit("users", users);
                        resolve(users);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },
        updateUser({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/update-user", data)
                    .then(resp => {
                        const userRec = resp.data;
                        resolve(userRec);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        updatePet({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/update-pet", data)
                    .then(resp => {
                        const userRec = resp.data;
                        resolve(userRec);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        updatePhoto({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/update-photo", data)
                    .then(resp => {
                        const userRec = resp.data;
                        resolve(userRec);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getScamReportings({ commit, dispatch }, page) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/scam-reportings/"+page)
                    .then(async(resp) => {
                        const scamReportings = resp.data;
                        if(page == 1)
                        {
                            commit("scamReportings", scamReportings);
                        }
                        else
                        {
                            commit("addScamReporting", scamReportings);
                        }
                        await dispatch('pending');
                        resolve(scamReportings);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        updateScamReporting({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/scam-reportings", data)
                    .then(resp => {
                        commit("updateScamReporting", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getFavoriteVideos({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/favorite-videos/"+data.page+'?tags='+data.tags+'&published='+data.published+'&breed_id='+data.breed_id)
                    .then(async(resp) => {
                        const favoriteVideos = resp.data;
                        if(data.page == 1) {
                            commit("favoriteVideos", favoriteVideos);
                        } else {
                            commit("addFavoriteVideos", favoriteVideos);
                        }
                        resolve(favoriteVideos);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        publishVideo({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/publish-video", data)
                    .then(resp => {
                        commit("updateFavoriteVideo", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        downloadVideo({ commit, dispatch }, video_xid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/download-video/"+video_xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getFavorites({ commit, dispatch }, page) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/favorites/"+page)
                    .then(async(resp) => {
                        const favorites = resp.data;
                        if(page == 1)
                        {
                            commit("favorites", favorites);
                        }
                        else
                        {
                            commit("addFavorites", favorites);
                        }
                        await dispatch('pending');
                        resolve(favorites);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        updateFavorite({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/favorites", data)
                    .then(resp => {
                        commit("updateFavorite", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getDiscountOffer({ commit, dispatch }, email) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/get-offer/"+email)
                    .then(resp => {
                        const data = resp.data;
                        resolve(data);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        sendDiscountOffer({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/send-offer", data)
                    .then(resp => {
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getConversation({ commit, dispatch }, cxid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/get-conversation/"+cxid)
                    .then(resp => {
                        const data = resp.data;
                        resolve(data);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        getStats({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/stats?category="+data.category)
                    .then(resp => {
                        const data = resp.data;
                        resolve(data);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        getInstagramFollowers({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/instagram-followers")
                    .then(async(resp) => {
                        const followers = resp.data;
                        commit("instagramFollowers", followers);
                        await dispatch('pending');
                        resolve();
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        updateInstagramFollower({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/instagram-followers", data)
                    .then(resp => {
                        commit("updateInstagramFollower", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        getTwitterFollowers({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/twitter-followers")
                    .then(async(resp) => {
                        const followers = resp.data;
                        commit("twitterFollowers", followers);
                        await dispatch('pending');
                        resolve();
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },

        updateTwitterFollower({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/twitter-followers", data)
                    .then(resp => {
                        commit("updateTwitterFollower", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        sendDirectMessage({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/send-direct-message", data)
                    .then(resp => {
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
		petFromId({ commit }, xid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/pet/"+xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        editPet({ commit, dispatch }, data) {

            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/pet/" + data.xid, data.formData)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        addVideo({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/video", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        deleteVideo({ commit }, xid)
        {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/admin/video/"+ xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        addPhoto({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/photo", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        deletePhoto({ commit }, xid)
        {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/admin/photo/"+ xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },
        addAvatar({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/avatar", data,{
                        headers: {
                            'Content-Type':'multipart/form-data'
                        }
                    })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        // same as petFromId but this will update my pets
        getPetDetails({ commit }, xid) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/pet/"+xid)
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        deletePreferences({ commit, dispatch }, xid) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/v1/admin/preferences/"+xid)
                    .then(resp => {
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getPreferenceDescriptions({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/v1/admin/preference-descriptions")
                    .then(async(resp) => {
                        const preferenceDescriptions = resp.data;
                        commit("preferenceDescriptions", preferenceDescriptions);
                        await dispatch('pending');
                        resolve(preferenceDescriptions);
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        reject(err);
                    });
            });
        },
        savePreferenceDescription({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/preference-description", data)
                    .then(resp => {
                        commit("updatePreferenceDescription", resp.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        sendOneWeekPremiumOffer({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/v1/admin/send-one-week-premium-offer", data)
                    .then(resp => {
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
    }
}