<template>
    <div>
        <v-card class="mild-shadow rounded-lg">

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle class="text--primary text-caption"><b>User:</b>
                        {{ questionnaire.email }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-list-item-action-text class="secondary--text">{{ $moment(questionnaire.updated_at).fromNow(true) }}</v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>

         
            
            <v-card-text>
              <div class="row">
                <div class="col-1"><v-icon disabled color="grey">mdi-cancel</v-icon></div>
                <div class="col-11">
                  <div class="text-caption">Full name</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.full_name }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-1"><v-icon disabled color="grey">mdi-cancel</v-icon></div>
                <div class="col-11">
                  <div class="text-caption">Kennel Name (if applicable)</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.kennel_name }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-1"><v-icon disabled color="grey">mdi-cancel</v-icon></div>
                <div class="col-11">
                  <div class="text-caption">Website</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.website }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-1"><v-icon disabled color="grey">mdi-cancel</v-icon></div>
                <div class="col-11">
                  <div class="text-caption">Social Media Links</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.social_media_links }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('years_of_experience')" color="success"
                          @click="verifyQuestionnaireField('years_of_experience', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('years_of_experience', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Years of Breeding Experience</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.years_of_experience }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('specialized_breeds')" color="success"
                          @click="verifyQuestionnaireField('specialized_breeds', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('specialized_breeds', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Breeds You Specialize In</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.specialized_breeds }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('certifications')" color="success"
                          @click="verifyQuestionnaireField('certifications', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('certifications', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Certifications or Memberships</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.certifications }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('breeding_philosophy')" color="success"
                          @click="verifyQuestionnaireField('breeding_philosophy', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('breeding_philosophy', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Describe your approach to breeding and your goals for your breeding program</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.breeding_philosophy }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('health_testing_protocols')" color="success"
                          @click="verifyQuestionnaireField('health_testing_protocols', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('health_testing_protocols', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">What health tests do you perform on your breeding animals?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.health_testing_protocols }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('average_litter_size')" color="success"
                          @click="verifyQuestionnaireField('average_litter_size', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('average_litter_size', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Average Litter Size</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.average_litter_size }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('breeding_frequency')" color="success"
                          @click="verifyQuestionnaireField('breeding_frequency', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('breeding_frequency', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">How often do you breed your females?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.breeding_frequency }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('puppy_socialization')" color="success"
                          @click="verifyQuestionnaireField('puppy_socialization', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('puppy_socialization', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Describe how you socialize your puppies/kittens during their first weeks.</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.puppy_socialization }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('veterinary_care')" color="success"
                          @click="verifyQuestionnaireField('veterinary_care', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('veterinary_care', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">What veterinary care do you provide for your breeding animals and puppies/kittens?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.veterinary_care }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('living_conditions')" color="success"
                          @click="verifyQuestionnaireField('living_conditions', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('living_conditions', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Describe the living conditions and housing for your breeding animals.</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.living_conditions }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('animal_welfare_commitment')" color="success"
                          @click="verifyQuestionnaireField('animal_welfare_commitment', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('animal_welfare_commitment', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">How do you ensure the physical and mental well-being of your breeding animals?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.animal_welfare_commitment }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('ethical_considerations')" color="success"
                          @click="verifyQuestionnaireField('ethical_considerations', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('ethical_considerations', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">What ethical considerations do you take into account in your breeding practices?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.ethical_considerations }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon onclick="return false;" color="grey">mdi-checkbox-marked</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Do you require buyers to spay/neuter their pets?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.requires_spay_neuter == 1 ? "Yes" : "No" }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('rehoming_policy')" color="success"
                          @click="verifyQuestionnaireField('rehoming_policy', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('rehoming_policy', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">What is your policy if a buyer can no longer care for the pet?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.rehoming_policy }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('guarantee_contract')" color="success"
                          @click="verifyQuestionnaireField('guarantee_contract', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('guarantee_contract', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Do you offer any health guarantees or contracts to buyers?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.guarantee_contract }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('pricing_details')" color="success"
                          @click="verifyQuestionnaireField('pricing_details', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('pricing_details', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">How do you determine the price of your puppies/kittens?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.pricing_details }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon onclick="return false;" color="grey">mdi-checkbox-marked</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Do you have a waiting list for your puppies/kittens?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.has_waiting_list == 1 ? "Yes" : "No" }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('screening_process')" color="success"
                          @click="verifyQuestionnaireField('screening_process', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('screening_process', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">How do you screen potential buyers to ensure they are suitable homes for your animals?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.screening_process }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('post_sale_support')" color="success"
                          @click="verifyQuestionnaireField('post_sale_support', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('post_sale_support', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">What kind of support do you offer to buyers after they take home a puppy/kitten?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.post_sale_support }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon onclick="return false;" color="grey">mdi-checkbox-marked</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Do you participate in any dog shows or competitions? (If applicable)</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.participates_in_shows == 1 ? "Yes" : "No" }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon onclick="return false;" color="grey">mdi-checkbox-marked</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Are you willing to provide photos or videos of your kennel and breeding animals?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.willing_to_provide_media == 1 ? "Yes" : "No" }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <v-icon v-if="questionnaire.verified_fields.includes('additional_information')" color="success"
                          @click="verifyQuestionnaireField('additional_information', 'remove')">mdi-checkbox-marked</v-icon>
                  <v-icon v-else color="success" @click="verifyQuestionnaireField('additional_information', 'add')">mdi-checkbox-blank-outline</v-icon>
                </div>
                <div class="col-11">
                  <div class="text-caption">Is there any additional information you would like to share about your breeding program?</div>
                  <div class="text-caption black--text font-weight-bold">{{ questionnaire.additional_information}}</div>
                </div>
              </div>

            </v-card-text>
      

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small text color="error" outlined @click="rejectDialog=true" :disabled="disabled">
                    REJECT
                </v-btn>

                <v-btn small color="success" depressed v-if="questionnaire.verified" @click="approve(0)"
                    :loading="approving" :disabled="approving || disabled">
                    APPROVED
                </v-btn>
                <v-btn small text color="success" outlined v-else @click="approve(1)" :loading="approving"
                    :disabled="approving || disabled">
                    APPROVE
                </v-btn>
                
            </v-card-actions>

        </v-card>

        <v-dialog v-model="rejectDialog" max-width="600">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title class="text-subtitle-1 font-weight-bold">Reason</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="rejectDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="rejectForm" v-model="valid" lazy-validation @submit.prevent="approve(0)">
                    <v-card-text class="pt-4">
                        <div class="pb-4">Provide a reason for rejection which will be communicated with the user.</div>
                        <v-textarea dense outlined auto-grow row-height="30" rows="5" placeholder="Reason"
                            v-model="reason" :rules="rejectRules"></v-textarea>

                            <v-chip small class="ma-1" @click="reason='Provided details does not look genuine'">Details Not Genuine</v-chip>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="approving" :disabled="!valid || approving">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    </div>

</template>

<script>

export default {
    name: 'breederQuestionnaires',
    props: ['questionnaire'],
    data() {
        return {
            reason: '',
            approving: false,
            updating: false,
            rejectDialog: false,
            snackbar: false,
            snackbar_text: '',
            disabled: false,
            valid: true,

            rejectRules: [
                v => !!v || 'Reason is required',
            ],
        }
    },
    methods: {
        verifyQuestionnaireField(val, opr) {
          let id = this.questionnaire.xid;
          let questionnaire = 'breeder';

          this.$store.dispatch('verifyQuestionnaireField', {
            questionnaire,
            opr,
            val,
            id,
          }).then((res) => {
            this.$emit('update-questionnaire', res);
          })
          .catch(err => {
            this.approving = false;
          });
        },
        approve(val) {

            if(val == 0 && this.reason == '')
            {
                this.snackbar_text = "Reason is required";
                this.snackbar = true;
            }
            else
            {
                let id = this.questionnaire.xid;
                let approve = val
                let reason = this.reason;
                this.approving = true;
                this.$store.dispatch('saveBreederQuestionnaire', {
                    approve,
                    reason,
                    id,
                }).then(() => {
                    this.approving = false;
                    this.rejectDialog = false
                    this.questionnaire.verified = val

                    if(val == 0)
                    {
                        this.disabled = true;
                    }
                })
                    .catch(err => {
                        this.approving = false;
                    });
            }
            
        },

       
    }

}
</script>
<style>
.row + .row {
  margin-top: 0;
}
</style>
