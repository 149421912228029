<template>
<div>
    <v-card class="mild-shadow rounded-lg">

        <v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle class="text--primary text-caption pb-1"><b>User:</b> {{pet.email}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-list-item-action-text class="secondary--text">{{ $moment(pet.subscribed_at ? pet.subscribed_at : pet.created_at).fromNow(true) }}</v-list-item-action-text>
            </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-img @click="detailsDialog = true" :src="pet.avatar ? pet.avatar.photo : require('@/assets/logo/logo-bg2.jpg')" :lazy-src="require('@/assets/img/lazy-bg.jpg')" cover max-height="300" class="white--text align-end" gradient="to left bottom , rgba(0,0,0,.01) 50%, rgba(0,0,0,.7)">
            <div class="px-2 pb-2">
                <div style="position:absolute; top:10px; right:15px" v-show="pet.photos.length > 0">
                    <v-icon dark size="16">mdi-image-multiple-outline</v-icon> {{pet.photos.length}}
                </div>
                <div style="position:absolute; top:9px; right:50px" v-show="pet.videos.length > 0">
                    <v-icon dark size="25">mdi-video-outline</v-icon> {{pet.videos.length}}
                </div>

                <!-- <v-badge bordered color="primary" icon="mdi-crown" :value="pet.is_premium" inline> -->
                <div class="text-h6 font-weight-medium text-truncate">{{pet.name}}</div>
                <!-- </v-badge> -->
                <div class="text-truncate text-caption">{{pet.breed}}</div>
                <div class="text-truncate text-caption">{{pet.age ? pet.age.long : ''}} old, {{pet.gender == 'M' ? 'Male' : 'Female'}}</div>
                <div class="text-truncate text-caption">
                    <v-icon dark x-small>
                        mdi-map-marker-outline
                    </v-icon> {{location}}
                </div>
            </div>

        </v-img>

        <v-card-text>
            <div class="text-caption blue--text">{{pet.insta_published_url}}</div>
        </v-card-text>
        <v-card-actions>

            <v-chip class="ma-1" small>
                <v-avatar left>
                    <v-icon small>mdi-arrow-top-right-thick</v-icon>
                </v-avatar>
                {{pet.messages_sent}}
            </v-chip>
            <v-chip class="ma-1" small>
                <v-avatar left>
                    <v-icon small>mdi-arrow-bottom-left-thick</v-icon>
                </v-avatar>
                {{pet.messages_recieved}}
            </v-chip>
            <v-spacer></v-spacer>

            <v-btn small color="success" depressed v-if="pet.insta_published" :disabled="notifying">
                PUBLISHED
            </v-btn>
            <v-btn small text color="success" outlined v-else @click="notifyDialog = true" :loading="notifying" :disabled="notifying">
                NOTIFY
            </v-btn>
        </v-card-actions>

    </v-card>

    <v-dialog v-model="detailsDialog" max-width="600">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Profile</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="detailsDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-4">

                <p>
                    🥁 Meet {{pet.name}}, 👑 {{pet.gender == 'M' ? 'King' : 'Queen'}} of the day
                    <br /><br />
                    Breed:<b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{pet.breed}}</b> (Use hashtag)
                    <br />
                    Age:<b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{pet.age.long}}</b>
                    <br />
                    Gender:<b> &nbsp;{{pet.gender == 'M' ? 'Male' : 'Female'}}</b>
                    <br />
                    Weight:<b> &nbsp;&nbsp;{{pet.weight}} {{pet.weight_unit}}</b>
                    <br />
                    <br />
                    {{pet.gender == 'M' ? 'He' : 'She'}} is looking for {{pet.purpose == 'lover' ? 'a Lover 💕' : (pet.purpose == 'adopter' ? 'an Adopter 😇' : (pet.purpose == 'buyer' ? 'a Buyer 🥰' : 'Friends 💛'))}}
                    <br />
                    <br />
                    Twitter>>>Get in touch with {{pet.gender == 'M' ? 'him' : 'her'}} 👉 https://app.petmeetly.com/pet/{{pet.xid}}
                    <br />
                    Facebook>>>Get in touch with {{pet.gender == 'M' ? 'him' : 'her'}} on @petmeetly
                    <br />
                    Instagram>>>Get in touch with {{pet.gender == 'M' ? 'him' : 'her'}} 👉 Click the link in our bio @petmeetly
                    <br />
                    <br />
                    <span class="text-pre-wrap">{{pet.description}}</span>
                    <br />
                    <br />
                    {{pet.purpose == 'lover' ? '#petmeetly #dogstagram #dogsofinstagram #dogoftheday #dogbreeding #dogbreeder #kennels #breeder #purebreddog #breeding #breedingdogs #caninebreeding #ethicalbreeding #selectivebreeding #dogfeatures' : (pet.purpose == 'adopter' ? '#petmeetly #dogstagram #dogsofinstagram #dogoftheday #adoptdontshop #adopt #adoptme #adoptable #adoptdog #rescuedogs #savedogs #offertoadopt' : (pet.purpose == 'buyer' ? '#petmeetly #dogstagram #dogsofinstagram #dogoftheday #doggo #dogfeatures #dogoftheday #dogforsale #puppyforsale #saledog #puppiesforsale #forsale #lovefordogs #dogisgood' : '#petmeetly #dogstagram #dogsofinstagram #dogoftheday #dogfriend #dogbuddy #dogdad #puppyfriends #dogfamily #doglife #dogfriendship #dogsiblings #dogfun #happypuppy #dogplaying #doggroup #doggathering #puppybrothers #puppyfun #bestfriend'))}}

                    <br />
                    <br />
                    <v-divider></v-divider>
                    <span class="text-caption">Generate suitable hashtags for the breed from <a href="https://predis.ai/free-hashtag-generator/" target="_blank">Here</a></span>
                    <br />
                    <span class="text-caption">Add location tags and relevant hashtags for <span class="primary--text">{{this.pet.locality+', '+this.pet.city+', '+this.pet.state}}</span></span>
                    <br />
                    <span class="text-caption">Mention the pet: <span class="primary--text">@{{pet.insta_handle}}</span></span>

                </p>

                <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">photos</div>

                <v-row justify="start" align="center" class="pt-4">

                    <v-col cols="4" :key="idx" v-for="(pic, idx) in pet.photos" align="center">
                        <v-img :src="pic.photo" cover style="border-radius:5px" aspect-ratio="1">

                            <v-btn fab text small dark absolute bottom right class="mb-8" style="background-color:rgba(0,0,0,0.20)" :href="pic.photo" target="_blank" download>
                                <v-icon>
                                    mdi-download
                                </v-icon>
                            </v-btn>

                        </v-img>

                    </v-col>

                    <v-col cols="4" :key="'vid'+idx" v-for="(vid, idx) in pet.videos" align="center">
                        <v-card width="500px" height="160">
                            <video-player :options="videoOptions" :source="getVideoUrl(vid)" :id="vid.xid"/>
                            <v-btn fab text small dark absolute top right class="mt-8" style="background-color:rgba(0,0,0,0.20)" @click="download(vid.xid)">
                                <v-icon white>mdi-download</v-icon>
                            </v-btn>
                        </v-card>
                    </v-col>
                </v-row>

            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="notifyDialog" max-width="600">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Notify User</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="notifyDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="pt-4">
                <div class="pa-1 primary--text text-subtitle-1 font-weight-medium">Instagram Url (optional)</div>
                <v-text-field class="pt-0" outlined dense v-model="pet.insta_published_url" placeholder="Insta published Url"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.prevent="notify" :loading="notifying" :disabled="notifying">Notify</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

</div>
</template>

<script>
import VideoPlayer from "./VideoPlayer.vue";

export default {
    name: 'PremiumCard',
  components: {VideoPlayer},
    props: ['pet'],
    data() {
        return {
            notifying: false,
            detailsDialog: false,
            notifyDialog: false,
            videoOptions: {
              autoplay: false,
              controls: true,
              playbackRates: [0.25, 0.5, 1, 1.5, 2, 4]
            }
        }
    },

    computed: {

        location() {
            return (this.pet.locality ? this.pet.locality : (this.pet.city ? this.pet.city : this.pet.state)) + (this.pet.distance > 0 ? ' (' + Math.round(this.pet.distance) + ' Miles)' : '')
        }
    },

    watch : {
        detailsDialog() {
          this.handleChange();
        }
    },
    methods: {
      handleChange() {
        const videos = document.querySelectorAll('.video-js');
        videos.forEach(video => {
          video.player.pause();
        });
      },
      download(video_id) {
          this.downloading = true;
          this.$store.dispatch('downloadVideo', video_id).then((res) => {
            window.open(res.data.url, "_blank");
            this.downloading = false;
          })
              .catch(err => {
                this.downloading = false;
              });
        },
        getVideoUrl(item) {
            let url='https://api.dyntube.com/v1/live/videos/'+item.video_key+'.m3u8';
            return url;
        },
        notify() {
            let pet_id = this.pet.xid;
            let insta_published_url = this.pet.insta_published_url;

            this.notifying = true;
            this.$store.dispatch('notifyPremium', {
                    pet_id,
                    insta_published_url,
                }).then(() => {
                    this.notifying = false;
                    this.notifyDialog = false;
                    this.pet.insta_published = true;
                })
                .catch(err => {
                    this.notifying = false;
                });
        }

    }

}
</script>
