<template>
<v-container fluid class="home">

    <div v-if="this.$store.getters.userDetails.name == ''" class="pt-12">

        <v-row no-gutters>
            <v-col cols="12" justify="center" align="center" class="pt-12">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>

        </v-row>

    </div>
    <div v-else class="mb-16">

        <div class="my-2 mb-4">
            <v-row class="d-flex align-center">
                <v-col md="2" xs="12" cols="12">
                    <h4 class="text-h6">Liked Videos</h4>
                </v-col>
                <v-col md="3" xs="12"  cols="12">
                  <v-chip-group
                      multiple
                      active-class="primary--text"
                      v-model="selectedTags"
                      @change="getFavoriteVideos(1)"
                      class="float-left"
                  >
                    <v-chip
                        v-for="tag in tags"
                        :key="tag"
                        outlined
                        :value="tag"
                        :disabled="disabled"
                        filter
                    >
                      {{ tag }}
                    </v-chip>

                  </v-chip-group>

                  <v-chip-group
                      active-class="primary--text"
                      v-model="published"
                      @change="getFavoriteVideos(1)"
                      tag="span"
                      class="float-left"
                  >
                    <v-chip
                        outlined
                        value="1"
                        :disabled="disabled"
                        filter
                    >
                      Published
                    </v-chip>

                  </v-chip-group>
                </v-col>

<!--            </v-row>-->
<!--            <v-row>-->
              <v-col cols="12" md="2">
                <v-select v-model="pet_type" hide-details :items="petTypes" item-text="type" item-value="xid" placeholder="Pet Type" dense clearable outlined></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete class="mt-0" v-model="breed" hide-no-data :filter="filterObject" :items="typeBreeds" item-text="breed" item-value="xid" placeholder="Breed" return-object hide-details dense clearable outlined>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.breed"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2" class="text-center">
                <v-btn block color="primary" @click="search" :loading="loading">
                  <v-icon left >mdi-magnify</v-icon> Search
                </v-btn>

              </v-col>
            </v-row>

        </div>
        <v-row v-if="this.$store.getters.favoriteVideos.length == 0">
            <v-col cols="12" class="pa-12 text-center">
                <v-icon size="120" color="#eee">mdi-thumb-up-outline</v-icon>
                <div>Well Done!!</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item) in this.$store.getters.favoriteVideos" :key="item.xid" class="pa-2">
                <favorite-video-card :video="item"></favorite-video-card>
            </v-col>
        </v-row>
    </div>

    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    <v-btn color="primary" dark fixed bottom right fab @click="getFavoriteVideos(page + 1)" :loading="loading">
        <v-icon>mdi-chevron-double-down</v-icon>
    </v-btn>

</v-container>
</template>

<script>
import FavoriteVideoCard from './components/FavoriteVideoCard.vue'
export default {
    name: 'FavoriteVideos',
    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => {});
        } else {
            this.getStaticData();
            this.getFavoriteVideos(1);
        }

    },
    data() {
        return {
            breed: '',
            pet_type: '',

            loading: false,
            disabled: false,
            snackbar: false,
            snackbar_text: '',

            page: 0,

            published: false,
            tags: ['Cute', 'Funny'],
            selectedTags: [],
            selectedTagsStr: '',
        }
    },
    components: {
        FavoriteVideoCard,
    },
    computed : {
        petTypes() {
            let petTypes = this.$store.getters.petTypes;
            return petTypes;
        },
        typeBreeds() {
          let petTypes = this.$store.getters.petTypes;
          let selectedPetType = this.pet_type;

          let filteredItem = petTypes.filter(item => {
              return item.xid == selectedPetType
          });

          if (filteredItem.length > 0)
              return filteredItem[0].breeds;
          else
              return [];
        },
    },
    methods: {
        updatePublished() {
            this.getFavoriteVideos(1)
        },
        getStaticData() {
            this.dataLoading = true;
            this.$store.dispatch('getStaticData').then(() => {
                this.dataLoading = false;
            }).catch(err => {
                //console.log(err);
                this.dataLoading = false;
                this.snackbar_text = "Oops, there is an error loading details for the page";
                this.snackbar = true;
            });
        },
        filterObject(item, queryText, itemText) {
            return (
                item.breed.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
        getFavoriteVideos(page) {
            this.loading = true;
            var breed_id = this.breed ? this.breed.xid : null;
            this.$store.dispatch('getFavoriteVideos', {
                page: page,
                tags: this.selectedTags.sort().join(),
                breed_id,
                published: this.published == 1 ? 1 : 0
            }).then(() => {
                    this.loading = false;
                    this.page = page;
                })
                .catch(err => {
                    //console.log(err)
                    this.loading = false;
                    this.snackbar_text = "Oops, Couldn't get data. Please try again.";
                    this.snackbar = true;
                });
        },
        search() {
            this.getFavoriteVideos(1);
        },
    }

}
</script>
