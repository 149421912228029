<template>
<v-container fluid class="home">

    <div v-if="this.$store.getters.userDetails.name == ''" class="pt-12">

        <v-row no-gutters>
            <v-col cols="12" justify="center" align="center" class="pt-12">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>

        </v-row>

    </div>
    <div v-else class="mb-16">
        <v-card class="px-4 py-4 mb-6 mild-shadow">

      
            
            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field v-model="filterName" :append-icon="'mdi-magnify'" hide-details flat outlined dense clear-icon="mdi-close" clearable placeholder="Pet's Name" type="text" ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select v-model="filterPurpose" hide-details :items="['adopter', 'buyer', 'friend', 'lover']" clearable placeholder="Looking for" dense outlined></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select v-model="hideNotified" hide-details :items="['Show Notified', 'Hide Notified']"  placeholder="Notified" dense clearable outlined></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select v-model="pet_type" hide-details :items="petTypes" item-text="type" item-value="xid" placeholder="Pet Type" dense clearable outlined></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-autocomplete class="mt-0" v-model="breed" hide-no-data hide-details :filter="filterObject" :items="typeBreeds" item-text="breed" item-value="xid" placeholder="Breed" dense clearable outlined return-object>
                        <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.breed"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" class="text-center">
                    <v-btn block color="primary" @click="search" :loading="loading">
                        <v-icon left >mdi-magnify</v-icon> Search
                    </v-btn>

                </v-col>
            </v-row>

            

     

        </v-card>

        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item,index) in this.$store.getters.premium" :key="index" class="pa-2">
                <premium-card :pet="item"></premium-card>
            </v-col>
        </v-row>
    </div>

    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    <v-btn color="primary" dark fixed bottom right fab @click="getPremium" :loading="loading">
        <v-icon>mdi-chevron-double-down</v-icon>
    </v-btn>

</v-container>
</template>

<script>
import PremiumCard from './components/PremiumCard.vue';
export default {
    name: 'Premium',
    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => {});
        } else {
            this.getStaticData();
            this.getPremium();
        }

    },
    data() {
        return {
            breed: '',
            pet_type: '',

            loading: false,
            snackbar: false,
            snackbar_text: '',
            page: 0,
            filterName: '',
            filterPurpose: '',
            hideNotified: '',
        }
    },
    computed : {
        petTypes() {
            let petTypes = this.$store.getters.petTypes;
            return petTypes;
        },
        typeBreeds() {
            let petTypes = this.$store.getters.petTypes;
            let selectedPetType = this.pet_type;

            let filteredItem = petTypes.filter(item => {
                return item.xid == selectedPetType
            });

            if (filteredItem.length > 0)
                return filteredItem[0].breeds;
            else
                return [];
        },
    },
    components: {
        PremiumCard,
    },
    methods: {
        getStaticData() {
          this.dataLoading = true;
          this.$store.dispatch('getStaticData').then(() => {
            this.dataLoading = false;
          })
              .catch(err => {
                //console.log(err);
                this.dataLoading = false;
                this.snackbar_text = "Oops, there is an error loading details for the page";
                this.snackbar = true;
              });
        },
        filterObject(item, queryText, itemText) {
            return (
                item.breed.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
        getPremium() {
            this.loading = true;
            let page = this.page + 1;
            let filterName = this.filterName;
            let filterPurpose = this.filterPurpose;
            let hideNotified = this.hideNotified;
            let breed_id = this.breed ? this.breed.xid : null;

                this.$store.dispatch('getPremium', {page, filterName, filterPurpose, hideNotified, breed_id }).then(() => {
                    this.loading = false;
                    this.page = page;
                })
                .catch(err => {
                    //console.log(err)
                    this.loading = false;
                    this.snackbar_text = "Oops, Couldn't get data. Please try again.";
                    this.snackbar = true;
                });
        },

        search() {

            this.page = 0;
            this.getPremium();

        },

        
    }

}
</script>
